import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

    paragraph: {
        padding: theme.spacing(2, 0),
    },
    accordion: {
        marginBottom: theme.spacing(10),
    }

}));
