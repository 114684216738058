/** @format */

import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles";

import { Typography, Container, Grid, Button } from "@mui/material";
import PageTitle from "../PageTitle/PageTitle";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { HelpOutlineOutlined } from "@mui/icons-material";
import BasicAccordion from "./Accordion/Accordion";
import { languages } from "../translations";
import BasicAccordionUa from "./AccordionUa/AccordionUa";
import BasicAccordionFr from "./AccordionFr/AccordionFr";

const Faq = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.auth.lang);

  return (
    <>
      <Navbar />
      <Container maxWidth="md" className="containerH">
        <PageTitle
          title={`${languages[lang].faq.title}`}
          subtitle={`${languages[lang].faq.subtitle}`}
        />

        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          spacing={3}
          className={classes.mainContainer}
        >
          <Grid item xs={12}>
            {!lang && <BasicAccordion />}
            {lang === "en" && <BasicAccordion />}
            {lang === "ua" && <BasicAccordionUa />}
            {lang === "fr" && <BasicAccordionFr />}
          </Grid>
          <Grid item xs={12} className="faq-contact">
            {" "}
            <Typography variant="body" align="center">
              {languages[lang].faq.contact}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.wishboard}
              startIcon={<HelpOutlineOutlined />}
              onClick={() => {
                navigate("/contacts");
              }}
            >
              {" "}
              {languages[lang].faq.contactBtn}
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};
export default Faq;
