import axios from "axios";
import constants from "../constants/constants";

const API = axios.create({
  baseURL: "https://wish-u.app:5050/",
  withCredentials: true,
});
API.interceptors.request.use((req) => {
  if (localStorage.getItem(constants.wishProfile)) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem(constants.wishProfile)).token
    }`;
  }
  return req;
});

API.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 403) {
      // redirect to login page
      window.location.href = "/";
      if (error) {
        localStorage.clear();
      }
    }
    return Promise.reject(error);
  }
);
export default API;
