import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

//API calls
export const getWish = createAsyncThunk(
  "content/getWish",
  async (id, thunkAPI) => {
    try {
      const res = await API.get(`/wish/${id}`);
      const data = await res.data;

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("No permission", error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getWishes = createAsyncThunk("content/getWishes", async (page) => {
  const res = await API.get(`/wish?page=${page}`);
  const data = await res.data;
  return data;
});
export const getArchive = createAsyncThunk(
  "content/getArchive",
  async (page) => {
    const res = await API.get(`/wish/archive`);
    const data = await res.data;
    return data;
  }
);
export const searchWish = createAsyncThunk(
  "content/searchWish",
  async (searchQuery) => {
    const res = await API.get(
      `/wish/search?searchQuery=${searchQuery.search || "none"}&tags=${
        searchQuery.tags
      }`
    );

    const data = await res.data;
    return data;
  }
);
export const createWish = createAsyncThunk("/createWish", async (newWish) => {
  const formData = new FormData();
  formData.append("title", newWish.title);
  formData.append("description", newWish.description);
  formData.append("dreamScale", newWish.dreamScale);
  formData.append("permission", newWish.permission);
  formData.append("link", newWish.link);
  formData.append("file", newWish.selectedFile);
  formData.append("selectedlists", newWish.selectedlists);
  const res = await API.post("/wish", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = await res.data;
  return data;
});
export const updateWish = createAsyncThunk("/updateWish", async (wishData) => {
  const formData = new FormData();
  formData.append("id", wishData.id);
  formData.append("title", wishData.title);
  formData.append("description", wishData.description);
  formData.append("dreamScale", wishData.dreamScale);
  formData.append("permission", wishData.permission);
  formData.append("link", wishData.link);
  formData.append("file", wishData.selectedFile);
  formData.append("selectedlists", wishData.selectedlists);
  const res = await API.patch(`/wish/${wishData.id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const data = await res.data;
  return data;
});
export const deleteWish = createAsyncThunk(
  "/deleteWish",
  async (deleteData) => {
    const res = await API.delete(`/wish/${deleteData.wishid}`, {
      data: deleteData.page,
    });
    const response = await res.data;
    return response;
  }
);
export const markComplete = createAsyncThunk(
  "/markComplete",
  async (wishdata) => {
    const res = await API.patch(`/wish/complete/${wishdata.wishid}`);
    const response = await res.data;

    return response;
  }
);
export const unMarkComplete = createAsyncThunk(
  "/unMarkComplete",
  async (wishdata) => {
    const res = await API.patch(`/wish/complete/${wishdata.wishid}`);
    const response = await res.data;

    return response;
  }
);

// export const bookWish = createAsyncThunk("/bookWish", async (id) => {
//   const res = await API.patch(`/wish/${id}/book`);
//   const data = await res.data;
//   return data;
// });

//Wishlists
export const getWishLists = createAsyncThunk(
  "content/getWishLists",
  async (page) => {
    const res = await API.get(`/wish/get-wishlists?wlpage=${page}`);
    const data = await res.data;
    return data;
  }
);
export const createWishList = createAsyncThunk(
  "/createWishList",
  async (newWishList) => {
    const formData = new FormData();

    formData.append("title", newWishList.title);
    formData.append("description", newWishList.description);

    if (newWishList.event) {
      formData.append("event", newWishList.event);
    }
    if (newWishList.permission) {
      formData.append("permission", newWishList.permission);
    }
    if (newWishList.selectedUsers) {
      formData.append("selectedUsers", newWishList.selectedUsers);
    }
    if (newWishList.wishes) {
      formData.append("wishes", newWishList.wishes);
    }

    formData.append("file", newWishList.selectedFile);

    const res = await API.post("/wish/create-wishlist", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await res.data;
    return data;
  }
);
export const updateWishList = createAsyncThunk(
  "/updateWishList",
  async (wishData) => {
    const formData = new FormData();
    formData.append("id", wishData.id);
    formData.append("title", wishData.title);
    formData.append("description", wishData.description);
    formData.append("permission", wishData.permission);
    if (wishData.event) {
      formData.append("event", wishData.event);
    }
    if (wishData.selectedUsers) {
      formData.append("selectedUsers", wishData.selectedUsers);
    }
    if (wishData.wishes) {
      formData.append("wishes", wishData.wishes);
    }
    formData.append("file", wishData.selectedFile);
    const res = await API.patch(
      `/wish/update-wishlist/${wishData.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const data = await res.data;
    return data;
  }
);
export const deleteWishList = createAsyncThunk(
  "/deleteWishList",
  async (deleteData) => {
    const res = await API.delete(`/wish/wishlist/${deleteData.wishid}`, {
      data: deleteData.page,
    });
    const response = await res.data;
    return response;
  }
);
