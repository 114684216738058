import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

  form: {
    padding: theme.spacing(6, 4),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
      padding: theme.spacing(6, 2),
    },
  },
  btns: {
    margin: theme.spacing(0, 0, 2),
  },
  date: {
    fontSize: theme.typography.sizes.h1,
    fontFamily: `${theme.typography.fonts.fontSecondary}!important`,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.weight.bold,
  },
  label: {
    fontFamily: `${theme.typography.fonts.fontPrimary}!important`,
    color: theme.palette.neutral.light,
    fontWeight: theme.typography.weight.bold,
    letterSpacing: '2px',
    textTransform: theme.typography.transform.uppercase,

  },
  input: {
    width: "100%",
  },
  innerWrapper: {
    padding: theme.spacing(6, 0),
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  imageSection: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },

  submitWrapper: {
    marginTop: theme.spacing(3),
  },
  banner: {
    position: "relative",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    isolation: "isolate",
    color: theme.palette.neutral.light,
    textAlign: "center",
    // width: "100%",
    // marginBottom: "5vh!important",
    padding: theme.spacing(6, 4),

  },
  userMenu: {
    position: "relative",
    overflow: "hidden",
    color: theme.palette.neutral.light,
    textAlign: "left",
    marginTop: "5vh!important",
    // width: "100%",
    padding: theme.spacing(6, 4),

  },
  accountMenu: {
    width: "100%",
  },
  submit: {
    marginTop: 10,
  },
}));
