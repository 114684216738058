import {
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import constants from "../../constants/constants";
import { useEffect } from "react";
const ProtectedRoute = ({ children }) => {
  const authData = useSelector((state) => state.auth.authData);
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));

  if (!user && !authData) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};
export default ProtectedRoute;
