import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    mainContainer: {
        height: "100%",
    },
    paper: {
        position: "relative",
        isolation: "isolate",
        overflow: "hidden",
        marginBlock: theme.spacing(6),
    },
    success: {
        height: "100%",
    }
}));
