/** @format */

import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import {
	Paper,
	Typography,
	CircularProgress,
	Divider,
	Container,
	Grow,
	Box,
	Button,
	Grid,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	IconButton,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUser, addFriend } from "../../actions/user";
import Navbar from "../Navbar/Navbar";
import constants from "../../constants/constants";
import copyText from "../ErrorScreens/helpers/copy";
import Loading from "../Loading/loading";

import {
	EmailOutlined,
	ArrowBack,
	PhoneOutlined,
	ScheduleOutlined,
	LanguageOutlined,
	FlagOutlined,
	LocationOnOutlined,
	CottageOutlined,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import Footer from "../Footer/Footer";
import Wish from "../Wishboard/WishGrid/Wish/Wish";
import Clock from "../animations/clock";
import banner from "../../images/Homepage/cake.jpg";

import wishlist4 from "../../images/utils/wishlist4.jpg";
import { bookGeneralWish } from "../../reducers/users";
import { GiftWish } from "../Wishboard/WishGrid/Wish/decor/icons";
import { languages } from "../translations";

const FriendDetails = () => {
	const myDetails = JSON.parse(localStorage.getItem(constants.wishProfile));
	const { user, isLoading, error } = useSelector((state) => state.allUsers);
	const { lang } = useSelector((state) => state.auth);

	moment.locale(lang === "ua" ? "uk" : lang);
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [deadline, setDeadline] = useState();
	const [isInvited, setIsInvited] = useState(false);
	useEffect(() => {
		dispatch(fetchUser(id));
	}, [id]);

	useEffect(() => {
		if (user && myDetails) {
			setIsInvited(
				user?.user?.invites?.find((invite) => invite === myDetails._id)
			);
		}

		if (user && user.user.birthDate) {
			let nextYearBmonth = moment(user.user.birthDate).format("MM");

			let nextYearBday = moment(user.user.birthDate).format("DD");
			let currentYear = moment().year();

			let dt = moment(
				`${currentYear}-${nextYearBmonth}-${nextYearBday}`
			).format("YYYY-MM-DD");
			// console.log(dt + "T00:00:00.000Z");
			let deadlineVal = moment().diff(moment(dt), "days");

			if (deadlineVal && deadlineVal > 0) {
				setDeadline(`${currentYear + 1}-${nextYearBmonth}-${nextYearBday}`);
			} else {
				setDeadline(`${currentYear}-${nextYearBmonth}-${nextYearBday}`);
			}
		}
	}, [user]);

	const Invites = () => {
		if (myDetails && myDetails._id === user?.user?._id) {
			return (
				<Typography variant='body1' className={classes.friendInfo}>
					{languages[lang].user.ownPage}
				</Typography>
			);
		}
		if (user?.user?.isFriends) {
			return (
				<Typography variant='body1' className={classes.friendInfo}>
					{languages[lang].user.yourFriend}
				</Typography>
			);
		} else if (isInvited) {
			return (
				<Typography variant='body1' className={classes.friendInfo}>
					<AccessTimeIcon fontSize='small' /> {languages[lang].user.pending}
				</Typography>
			);
		} else if (!myDetails) {
			return (
				<Typography variant='body1' className={classes.friendInfo}>
					<AccessTimeIcon fontSize='small' />
					{languages[lang].user.login}
				</Typography>
			);
		}
		return (
			<Button
				variant='contained'
				color='primary'
				className={classes.submit}
				onClick={() => {
					dispatch(addFriend(user?.user?._id)).then(setIsInvited(true));
				}}>
				{languages[lang].user.invite}
			</Button>
		);
		// if (user?.user?.isFriends) {
		// 	return (
		// 		<Typography variant='body1' className={classes.friendInfo}>
		// 			Your friend
		// 		</Typography>
		// 	);
		// } else if (isInvited) {
		// 	return (
		// 		<Typography variant='body1' className={classes.friendInfo}>
		// 			<AccessTimeIcon fontSize='small' /> Pending
		// 		</Typography>
		// 	);
		// }
		// return (
		// 	<Button
		// 		variant='contained'
		// 		color='primary'
		// 		className={classes.submit}
		// 		onClick={() => {
		// 			dispatch(addFriend(user?.user?._id)).then(setIsInvited(true));
		// 		}}>
		// 		Invite
		// 	</Button>
		// );
	};
	return isLoading ? (
		<Loading />
	) : (
		<>
			<Navbar />
			<Container maxWidth='xl' className={classes.innerWrapper}>
				<Grid
					container
					justifyContent='space-between'
					alignItems='stretch'
					spacing={3}
					className={classes.friendData}>
					<Grid item md={8}>
						<Paper elevation={6} className={classes.cardWrapper}>
							<div className='decor-card'></div>
							<Button
								startIcon={<ArrowBack />}
								variant='text'
								className={classes.wishboard}
								// endIcon={<FavoriteBorder />}
								onClick={() => {
									navigate(-1);
								}}>
								{languages[lang].searchUsers.returnToSearch}
							</Button>
							<Box
								container
								justifyContent='space-between'
								alignItems='stretch'
								spacing={3}
								className={classes.card}>
								<Box item className={classes.imageSection}>
									<div className='avatar --xl'>
										<img
											className={classes.media}
											src={
												user?.user?.avatar ||
												"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
											}
											alt={user?.user?.firstName}
										/>
									</div>
								</Box>
								<Box item className={classes.section}>
									<Typography variant='h4' component='h4'>
										{user?.user?.firstName} {user?.user?.lastName}
									</Typography>

									<Typography
										gutterBottom
										variant='h6'
										color='textSecondary'
										component='h2'>
										<Invites />
									</Typography>

									<Typography gutterBottom variant='body1' component='p'>
										{user?.user?.bio}
									</Typography>
									{/* <Divider style={{ margin: "20px 0" }} /> */}
									<Grid
										container
										alignItems='stretch'
										columnSpacing={3}
										rowSpacing={2}
										className={classes.friendData}>
										<Grid item md={6}>
											{user?.user?.email && (
												<Typography
													variant='body1'
													className={classes.friendInfo}>
													<EmailOutlined />
													{user?.user?.email}
												</Typography>
											)}
										</Grid>
										<Grid item md={6}>
											{user?.user?.phoneNumber && (
												<Typography
													variant='body1'
													className={classes.friendInfo}>
													<PhoneOutlined />
													{user?.user?.phoneNumber}
												</Typography>
											)}
										</Grid>
										<Grid item md={2}>
											{user?.user?.postCode && (
												<Typography
													variant='body1'
													className={classes.friendInfo}>
													<LanguageOutlined />
													{user?.user?.postCode}
												</Typography>
											)}
										</Grid>
										<Grid item md={4}>
											{user?.user?.country && (
												<Typography
													variant='body1'
													className={classes.friendInfo}>
													<FlagOutlined />
													{user?.user?.country}
												</Typography>
											)}
										</Grid>
										<Grid item md={6}>
											{user?.user?.city && (
												<Typography
													variant='body1'
													className={classes.friendInfo}>
													<LocationOnOutlined />
													{user?.user?.city}
												</Typography>
											)}
										</Grid>

										<Grid item md={12}>
											{user?.user?.address && (
												<Typography
													variant='body1'
													className={classes.friendInfo}>
													<CottageOutlined />
													{user?.user?.address}
												</Typography>
											)}
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Paper>
					</Grid>

					<Grid item xs={12} md={4}>
						<Box className={classes.banner}>
							<div className='banner-bg  --small'>
								<img src={banner} alt='avocado' className='banner-image' />{" "}
							</div>
							<div className='banner-content --small'>
								<Typography
									className={`${classes.preTitle} ${classes.preTitleDark} `}></Typography>
								<Typography variant='h4' className={classes.titleMain}>
									{user?.user?.firstName}'s {languages[lang].user.birthday}{" "}
									<span className={classes.titleMain}>
										{" "}
										{languages[lang].user.in}
									</span>
								</Typography>
								<Clock deadline={deadline} />
								<Box mt={2} mb={2}>
									<Button
										variant='contained'
										color='primary'
										className={classes.submit}
										onClick={() => {
											copyText(`user/${user?.user?._id}`);
										}}>
										<ShareIcon />
										{languages[lang].user.share}
									</Button>
								</Box>
							</div>
						</Box>
					</Grid>
				</Grid>
				{/* 
				<Grid
					className={classes.wishlists}
					container
					alignItems='stretch'
					spacing={3}>
					{user?.lists?.map((list) => (
						<Grid key={list._id} item xs={12} sm={6} md={4}>
							<Card sx={{ display: "flex" }}>
								<a className='list-link' href={`/wishList/${list._id}`}>
									<CardMedia
										component='img'
										sx={{ width: 151 }}
										image={list?.selectedFile || wishlist4}
										alt={list?.title}
									/>
								</a>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<CardContent sx={{ flex: "1 0 auto" }}>
										<Typography component='div' variant='h5'>
											<a className='listTitle' href={`/wishList/${list._id}`}>
												{" "}
												{list.title}
											</a>
										</Typography>

										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'>
											<span className='icon-grid --center'>
												{moment(list?.updatedAt).fromNow()}
											</span>
										</Typography>
									</CardContent>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											pl: 1,
											pb: 1,
										}}></Box>
								</Box>
							</Card>
						</Grid>
					))}
				</Grid> */}
				<Grid
					className={classes.wishlists}
					container
					alignItems='stretch'
					spacing={3}>
					<Grid item md={12}>
						{user?.lists.length > 0 && (
							<Typography variant='h5' component='h5'>
								{languages[lang].user.wishlistsTitle}
							</Typography>
						)}
					</Grid>

					{user?.lists?.map((list) => (
						<Grid key={list._id} item xs={12} sm={6} md={3}>
							<Card>
								<CardActionArea>
									<div className='wishlist --friend'>
										<a className='list-link' href={`/wishList/${list._id}`}>
											<CardMedia
												className='wishlist-image'
												component='img'
												image={list?.selectedFile || wishlist4}
												alt={list?.title}
											/>
										</a>
										{/* {list?.wishes?.length > 0 && (
                      <div className="wishlistBadge lvlWish">
                        <span className="wishlistBadge-icon">
                          <GiftWish />
                        </span>
                        <span className="wishlistBadge-text">Wishes</span>
                        <span className="wishlistBadge-lvl">
                          {list?.wishes?.length}
                        </span>
                      </div>
                    )} */}
										<div className='overlay-cardMedia --full'></div>
										<div className='wishlistContent'>
											{list.event && (
												<Typography
													className='event'
													// gutterBottom
													variant='body2'
													component='p'>
													<>
														{" "}
														{languages[lang].user.event}{" "}
														{moment(list?.event).fromNow()}
													</>
												</Typography>
											)}
											<div className={classes.cardTitle}>
												<Typography
													className={classes.title}
													// gutterBottom
													variant='h5'
													component='h5'>
													{list.title}
												</Typography>
											</div>
										</div>
									</div>

									{/* <CardContent sx={{ flex: "1 0 auto" }}>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'>
											{list.description}
										</Typography>

										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'>
											<span className='icon-grid --center'>
												<ScheduleOutlined />
												{moment(list?.updatedAt).fromNow()}
											</span>
										</Typography>
									</CardContent> */}
								</CardActionArea>
							</Card>
						</Grid>
					))}
				</Grid>

				<Grid
					className={classes.container}
					container
					alignItems='stretch'
					spacing={3}>
					<Grid item md={12}>
						{user?.wishList.length > 0 && (
							<Typography variant='h5' component='h5'>
								{languages[lang].user.wishTitle}
							</Typography>
						)}
					</Grid>
					{user?.wishList?.map((wish) => (
						<Grid key={wish._id} item xs={12} sm={6} md={3}>
							<Wish wishData={wish} update={bookGeneralWish} />
						</Grid>
					))}
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default FriendDetails;
