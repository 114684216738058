import { alpha } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

export default makeStyles((theme) => ({
  loadingWrapper: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: alpha(`${theme.palette.secondary.main}`, 0.7),
    left: 0,
    right: 0,
    top: 0,
    bottom: "0%",
    margin: "auto",
    height: "105vh",
    width: "105vw",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
