import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";

import {
    Button,
    Stack,
    TextField,
    Typography,
    Paper,
    Box,
    Autocomplete,
    Chip,
} from "@mui/material";
import image3 from "../../images/Homepage/image5.jpg";
import { Face3, Lock, RedoOutlined, Face2, Key, Pets } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

import { Festive, HomeIcon } from "../ErrorPage/decor/festive";
const ErrorPage = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <>
            {/* <div className="decor-card2"></div> */}
            {/* <div className="mover"></div> */}
            <div className="errorBlock">
                <Festive />

                <div className="errorTextWrap">
                    <Typography variant="h1" className="giantFont" align="center">
                        404
                    </Typography>
                    <Typography variant="h4" className="errorSubTitle" align="center">
                        Page not Found
                    </Typography>

                    <Button
                        endIcon={<HomeIcon />}
                        variant='contained'
                        className="errorSubTitle"
                        onClick={() => {
                            navigate("/");
                        }}>
                        Go Home
				</Button>
                </div>
            </div>



        </>
    );
};
export default ErrorPage;
