/** @format */

import React, { useEffect } from "react";
import { Pagination, PaginationItem, Box } from "@mui/material";
import { Link } from "react-router-dom";

import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";

import { getWishes } from "../../../actions/wishes";
import { getWishboard } from "../../../actions/user";
const WishPaginate = ({ page }) => {
  const { numberOfPages, wishlist } = useSelector((state) => state.wish);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getinvites = () => {
    return dispatch(getWishboard());
  };

  useEffect(() => {
    dispatch(getWishes(page)).then(getinvites());
  }, [page]);

  return (
    <Box className="wrapper-nav">
      <Box className={classes.paginationWrapper}>
        <Pagination
          classes={{ ul: classes.ul }}
          count={numberOfPages}
          page={Number(page)}
          variant="outlined"
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Link}
              to={`/wishboard?page=${item.page}`}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default WishPaginate;
