import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  media: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: "4px solid #f1ce89",
    borderRadius: 10,
    objectPosition: "center",
  },
  ava: {
    gridRow: "1/3",
  },
  friendCard: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    flexDirection: "row",
    gap: theme.spacing(0, 1),
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      minHeight: "160px",
      alignItems: "center",
      gridTemplateColumns: "60px 1fr",
    },
  },
  border: {
    border: "solid",
  },
  fullHeightCard: {
    height: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    position: "relative",
    padding: theme.spacing(2, 2, 0),
    // maxWidth: "340px",
  },
  overlay: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
  },
  overlay2: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2, 2, 0),
    maxWidth: "100%",
    wordBreak: "break-all",
  },
  cardActions: {
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "flex",
    textAlign: "initial",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardIcon: {
    marginInlineEnd: theme.spacing(1),
  },
}));
