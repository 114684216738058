import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(6, 0),
  },
  modal: {
    top: "50%",
    position: "absolute",
    left: "50%",
    width: "100%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.up("sm")]: {
      width: "clamp(1000px, 90vw, 1440px)",
    }
  },
  wishboardHeader: {
    justifyContent: "space-between",
    display: "flex",
  },
  wishBtn: {
    [theme.breakpoints.down("sm")]: {

    },
  },
  noBooked: {
    marginTop: theme.spacing(2),
  },

  banner: {
    position: "relative",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    isolation: "isolate",
    color: theme.palette.neutral.light,
    textAlign: "center",
    padding: theme.spacing(6, 4),
    marginTop: theme.spacing(4),
  },
}));
