import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "./Styles";
import { LockOutlined } from "@mui/icons-material";

import Input from "./input";
import icon from "./icon";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../reducers/auth";
import { signup, signin } from "../../actions/auth";
import { resetPwd } from "../../actions/user";
import Toast from "../toast/Toast";
import Logo from "../Logo/Logo";
import image3 from "../../images/Homepage/image5.jpg";
import constants from "../../constants/constants";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Notifications
const toastConf = {
  open: false,
  severity: "error",
  message: "Error",
};

const PasswordReset = () => {
  const query = useQuery();
  const initialState = {
    token: query.get("token") || null,
    password: "",
    confirmPassword: "",
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();

  const [toast, setToast] = useState(toastConf);

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const token = query.get("token") || null;

  useEffect(() => {
    console.log(formData);
    if (!formData.token) return navigate("/");
    // setFormData({ ...formData, token: token ? token : null });
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.token || !formData.password || !formData.confirmPassword) {
      console.log(formData);
      return;
    }

    dispatch(resetPwd(formData)).then((result) => {
      if (!result.error) {
        navigate("/auth");
      } else {
        setToast({
          ...toast,
          open: true,
          message: `Error ${result.payload}`,
        });
      }
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Container component="main" className={classes.main}>
      <Paper className={classes.paper} elevation={6}>
        <div className="row">
          <div className="col-image row-col">
            <img src={image3} alt="avocado" className="img" />{" "}
          </div>
          <div className="col-form row-col">
            <div className="form">
              {/* <Avatar className={classes.avatar}> */}
              <div className={classes.brandContainer}>
                <Logo className="logo" />
              </div>
              {/* </Avatar> */}
              <Typography variant="h5">Password reset</Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                  <Input
                    name="password"
                    label="Password"
                    handleChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    handleShowPassword={handleShowPassword}
                  />

                  <Input
                    name="confirmPassword"
                    label="Repeat Password"
                    handleChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    handleShowPassword={handleShowPassword}
                  />
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Send
                </Button>
              </form>
              <Toast
                open={toast.open}
                message={toast.message}
                severity={toast.severity}
                onClose={handleClose}
              />
            </div>
          </div>
        </div>
      </Paper>
    </Container>
  );
};
export default PasswordReset;
