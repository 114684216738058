/** @format */

import React, { useEffect } from "react";
import { Pagination, PaginationItem, Box } from "@mui/material";
import { Link } from "react-router-dom";

import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";

import { getWishLists } from "../../../../actions/wishes";
const WishListsPaginate = ({ wlpage }) => {
  const { numberOfListPages } = useSelector((state) => state.user);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWishLists(wlpage));
  }, [wlpage]);

  return (
    <Box className="wrapper-nav">
      <Box className={classes.paginationWrapper}>
        <Pagination
          classes={{ ul: classes.ul }}
          count={numberOfListPages}
          page={Number(wlpage)}
          variant="outlined"
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Link}
              to={`/wishboard?wlpage=${item.page}`}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default WishListsPaginate;
