import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  // Stack,
  Box,
  Paper,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import useStyles from "./styles";

import { languages } from "../../translations";
import { useSelector } from "react-redux";

const BasicAccordionFr = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.auth.lang);

  return (
    <div className={classes.accordion}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.paragraph}>
            {" "}
            Pourquoi devrais-je utiliser Wish ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Imaginez la semaine avant votre anniversaire. Vous commencez à
            recevoir des tonnes d'appels et de messages sur ce que vous aimeriez
            recevoir comme cadeau. Au lieu de planifier votre célébration ou de
            passer des moments de détente, vous devez répéter les mêmes souhaits
            encore et encore. Ou restez très concentré en évitant de nommer un
            souhait à différents amis, afin de ne pas recevoir deux ensembles
            Lego similaires et aucun parfum dont vous rêviez.
          </Typography>
          <Typography className={classes.paragraph}>
            C'est pourquoi nous avons créé Wish. Vous créez votre compte, le
            partagez avec vos amis et votre famille, et ils sauront quels sont
            les cadeaux les plus préférés pour vous.
          </Typography>
          <Typography className={classes.paragraph}>
            De plus, vos amis sur Wish pourront réserver certains de vos vœux,
            afin que d'autres utilisateurs sachent que vous allez déjà recevoir
            ce cadeau et choisiront parmi les vœux qui ne sont pas encore
            réservés.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.paragraph}>
            Réserver un vœu gâche-t-il la surprise ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Non, votre secret est en sécurité avec nous ! Lorsque vous réservez
            un vœu de quelqu'un, nous ajoutons une marque sur la carte du vœu,
            indiquant que quelqu'un prévoit d'offrir cela. Mais cette
            information ne sera disponible qu'aux autres utilisateurs, pas au
            créateur du vœu.
          </Typography>
          <Typography className={classes.paragraph}>
            Aussi, dès que vous réservez un vœu, vous le verrez disponible
            facilement dans l'onglet "Vœux réservés" sur votre tableau de bord
            Wish. Il vous sera donc très facile de suivre les vœux qui vous
            intéressent.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Puis-je voir d'une certaine manière quels vœux ont été réservés ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Non, nous protégeons la surprise que quelqu'un prépare pour vous !
          </Typography>
          <Typography className={classes.paragraph}>
            La meilleure façon de vous assurer que vos vœux sont réservés est de
            partager votre compte ou vos vœux les plus précieux avec autant de
            personnes que possible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            J'ajoute mon adresse, mais est-ce sûr ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Il est sûr de partager votre adresse dans votre compte. L'adresse,
            où vous pouvez recevoir vos cadeaux, n'est visible que par les
            personnes ajoutées dans votre espace de vœux en tant qu'amis. Cela
            signifie que vous avez toujours le contrôle sur qui peut voir cette
            information.
          </Typography>
          <Typography className={classes.paragraph}>
            De plus, il n'est pas obligatoire d'ajouter une adresse si vous ne
            le souhaitez pas. Vous pouvez toujours laisser l'adresse du point de
            collecte le plus proche de la Poste et des instructions de
            livraison.
          </Typography>
          <Typography className={classes.paragraph}>
            Ou vous pouvez jouer la carte du mystère et laisser le champ de
            l'adresse vide. Mais attention, dans ce cas, seuls les élus pourront
            vous envoyer des cadeaux.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Et si je suis trop timide pour partager mes vœux ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Eh bien, offrir un cadeau est une chose très agréable, donc les gens
            qui se soucient de vous vous donneront de toute façon quelque chose.
            Il pourrait donc être beaucoup plus facile de créer de nombreux vœux
            pour que vos amis puissent choisir plutôt que de prétendre que vous
            avez toujours voulu cette énorme paire de chaussettes que vous venez
            de recevoir.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Et si mes amis ne sont pas encore sur Wish ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Vous pouvez toujours inviter vos amis à rejoindre Wish, partager
            votre compte ou un vœu particulier. Et lorsque vos amis essaieront
            de réserver quelque chose pour vous, ils seront également invités à
            créer un compte.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Pourquoi seuls les utilisateurs enregistrés peuvent-ils réserver des
            vœux ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Tout d'abord, nous considérons que confier le vœu de quelqu'un à
            vous est une grande responsabilité. Nous devons nous assurer que
            lorsque vous réservez le vœu de quelqu'un, vous aurez toutes les
            informations dont vous avez besoin pour réaliser ce rêve. C'est
            pourquoi nous devons savoir à qui nous devons envoyer toutes les
            mises à jour sur le vœu et où le destinataire attend le cadeau.
          </Typography>
          <Typography className={classes.paragraph}>
            De plus, dans votre compte, vous pouvez voir tous les vœux que vous
            avez réservés et annuler votre réservation si vous changez d'avis.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Et si je ne peux pas offrir le vœu que j'ai réservé ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Vous pouvez toujours mettre à jour le vœu que vous avez réservé si
            les circonstances changent ou si vous changez simplement d'avis.
          </Typography>
          <Typography className={classes.paragraph}>
            Vous pouvez supprimer la réservation sur votre onglet "Vœux
            réservés" ou simplement sur la page de vos amis. Personne ne sera
            informé à ce sujet et le vœu sera disponible pour être réservé par
            d'autres utilisateurs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Plusieurs personnes peuvent-elles réserver le même vœu ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Non, donc si vous réservez un vœu, les autres utilisateurs verront
            qu'un autre utilisateur a déjà réservé un vœu. Il n'est donc plus
            disponible pour être réservé.
          </Typography>
          <Typography className={classes.paragraph}></Typography>
          <Typography className={classes.paragraph}></Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            {" "}
            Et si j'ai reçu le cadeau que je voulais ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Vous pouvez marquer votre vœu comme reçu :{" "}
          </Typography>
          <ol>
            <li> Connectez-vous à votre compte</li>
            <li> Allez sur votre tableau de bord Wish </li>
            <li>
              {" "}
              Cliquez sur le bouton "PLUS" en bas à droite de la carte du vœu.
            </li>
            <li> Dans le menu contextuel, cliquez sur "Marquer comme reçu"</li>
          </ol>
          <Typography className={classes.paragraph}>
            Après cela, votre cadeau reçu sera supprimé de la grille de vos vœux
            sur votre page. Mais il sera disponible sur votre page d'archives au
            cas où vous voudriez jamais restaurer ce vœu.
          </Typography>
          <Typography className={classes.paragraph}></Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicAccordionFr;
