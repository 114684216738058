/** @format */

import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import {
	Paper,
	Typography,
	CircularProgress,
	Divider,
	Container,
	Grow,
	Box,
	Button,
	Rating,
	Grid,
	Tooltip,
} from "@mui/material";
import Loading from "../Loading/loading";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getWish } from "../../actions/wishes";
import Navbar from "../Navbar/Navbar";
import {
	ArrowBack,
	FavoriteBorder,
	ScheduleOutlined,
} from "@mui/icons-material";
import CakeIcon from "@mui/icons-material/Cake";
import Toast from "../toast/Toast";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import Footer from "../Footer/Footer";
import { styled } from "@mui/material/styles";
import { bookWish } from "../../actions/user";
import { LikeIcon } from "../Wishboard/WishGrid/Wish/decor/icons";
import FavoriteIcon from "@mui/icons-material/Favorite";
import constants from "../../constants/constants";
import gift from "../../images/utils/gift.jpeg";
import { bookSingleWishSlice } from "../../reducers/wishes";
import { languages } from "../translations";
// Notifications
const toastConf = {
	open: false,
	severity: "error",
	message: "Error",
};
const WishDetails = () => {
	const user = JSON.parse(localStorage.getItem(constants.wishProfile));
	const { isLoading } = useSelector((state) => state.wish);
	const [toast, setToast] = useState(toastConf);
	const { wish } = useSelector((state) => state.wish);
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const { lang } = useSelector((state) => state.auth);
	moment.locale(lang === "ua" ? "uk" : lang);
	const StyledRating = styled(Rating)({
		"& .MuiRating-iconFilled": {
			color: "#F1CE89",
		},
		"& .MuiRating-iconHover": {
			color: "#F1CE89",
		},
	});

	useEffect(() => {
		dispatch(getWish(id)).then((result) => {
			if (result.error) {
				setToast({
					...toast,
					open: true,
					message: `Error ${result.payload}`,
				});
				navigate("/");
			}
		});
	}, [id]);
	const Books = () => {
		if (!user) {
			return (
				<Typography className={classes.loginInfo}>
					{languages[lang].wishDetails.login}
				</Typography>
			);
		}
		if (wish && wish.booked) {
			return wish.booked === user?._id ? (
				<>
					<Box className='animate-like --mt-xl'>
						<LikeIcon />
						<FavoriteIcon fontSize='small' />{" "}
					</Box>{" "}
					&nbsp; {languages[lang].wishDetails.youBooked}
				</>
			) : (
				<>
					<Box className='animate-like'>
						<LikeIcon />
						<FavoriteBorder fontSize='small' />{" "}
					</Box>{" "}
					&nbsp; {languages[lang].wishDetails.notYouBooked}
				</>
			);
		}

		return (
			<>
				<Box className='book-btn  animate-like --mt-xl'>
					<Tooltip
						className='book-tooltip'
						title={languages[lang].wishDetails.tooltip.book}>
						<Box className='animate-like'>
							<LikeIcon />
						</Box>
						<FavoriteBorder fontSize='small' /> &nbsp;{" "}
						{languages[lang].wishDetails.book}
					</Tooltip>
				</Box>
			</>
		);
	};

	return isLoading ? (
		<Loading />
	) : (
		<>
			<Navbar />
			<Container maxWidth='xl' className={`${classes.containerMain}`}>
				<Paper elevation={6} className={classes.cardWrapper}>
					<div className='decor-card'></div>
					<Grid
						container
						justifyContent='space-between'
						alignItems='stretch'
						spacing={3}
						className={classes.card}>
						<Grid item xs={12}>
							{" "}
							<Button
								startIcon={<ArrowBack />}
								variant='text'
								className={classes.wishboard}
								onClick={() => {
									navigate(-1);
								}}>
								{languages[lang].wishDetails.return}
							</Button>
						</Grid>

						<Grid item className={classes.section} xs={12} md={6}>
							<Typography variant='h3' component='h2'>
								{wish?.title}
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								color='primary'
								component='h2'>
								{wish?.tags?.map((tag) => `#${tag} `)}
							</Typography>

							<div className={classes.overlay2}>
								<div className='icon-grid'>
									<Typography gutterBottom variant='body1' component='span'>
										{languages[lang].wishDetails.wishmeter}: &nbsp;
									</Typography>
									<StyledRating
										name='customized-color'
										defaultValue={wish?.dreamScale || 1}
										// getLabelText={(value) => customIcons[value]}
										readOnly
										precision={1}
										icon={<CakeIcon fontSize='inherit' />}
										emptyIcon={<CakeIcon fontSize='inherit' />}
									/>
								</div>
							</div>
							<Divider style={{ margin: "20px 0" }} />
							{wish?.description && (
								<>
									<Typography gutterBottom variant='body1' component='span'>
										{languages[lang].wishDetails.details}: &nbsp;
									</Typography>
									<Typography gutterBottom variant='body1' component='span'>
										{wish?.description}
									</Typography>
								</>
							)}
							{wish?.link && (
								<div className='row-wrapper'>
									<Typography gutterBottom variant='body1' component='span'>
										{languages[lang].wishDetails.link}: &nbsp;
									</Typography>
									<Typography gutterBottom variant='body1' component='span'>
										<a
											className='link'
											target='_blank'
											href={wish?.link}
											rel='nofollow noreferrer'>
											{languages[lang].wishDetails.openLink}
										</a>{" "}
										&nbsp;
									</Typography>
									<LaunchOutlinedIcon />
								</div>
							)}

							<Box className={`${classes.eventGrid} ${classes.wishBlock}`}>
								<Typography variant='body1' className={classes.eventTitle}>
									{languages[lang].wishDetails.createdBy}: &nbsp;
									<span
										onClick={() => navigate(`/user/${wish?.creator._id}`)}
										className='icon-grid cursor-pointer underline '>
										{/* <PersonOutlineOutlined /> */}
										{wish?.creator?.firstName}
									</span>
								</Typography>

								<Typography variant='body1'>
									<div className='row-wrapper'>
										<span className='icon-grid'>
											<ScheduleOutlined />
											{moment(wish?.createdAt).fromNow()}
										</span>
									</div>
								</Typography>
							</Box>

							{/* <Box className={classes.eventGrid}>
								<Typography variant='h6' className={classes.eventTitle}>
									Event:{" "}
									<span className='accent icon-grid'>
										{moment(wish?.event).calendar()}
									</span>
								</Typography>
								<Typography variant='body1'>
									<span className=' icon-grid'>
										{" "}
										<TodayOutlined /> {moment(wish?.event).fromNow()}{" "}
									</span> */}
							{/* {moment(wish?.event)} */}
							{/* </Typography>
							</Box> */}
							{/* <Divider style={{ margin: "20px 0" }} /> */}
							{user?._id !== wish?.creator._id && (
								<>
									<Button
										size='small'
										color='primary'
										disabled={
											!user ||
											(wish?.booked !== null && wish?.booked !== user?._id)
										}
										onClick={() => {
											dispatch(bookWish(wish._id)).then(
												dispatch(bookSingleWishSlice({ wish, id: user?._id }))
											);
										}}>
										<Books item={wish} />
									</Button>
								</>
							)}
						</Grid>
						<Grid item className={classes.imageSection} xs={12} md={6}>
							<img
								className={classes.media}
								src={wish?.selectedFile || gift}
								alt={wish?.title}
							/>
							{user?._id === wish?.creator._id && (
								<div className={classes.overlay}>
									<div className={`wishlistBadge lvl${wish?.permission || 1}`}>
										<span className='wishlistBadge-text'>
											{languages[lang].permissionCodes[wish?.permission || 1]}
										</span>
										<span className='wishlistBadge-lvl'>
											{wish?.permission || 1}
										</span>
									</div>
								</div>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Container>
			<Footer />
		</>
	);
};

export default WishDetails;
