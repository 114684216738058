import React, { useEffect, useState, useCallback, useRef } from "react";

import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Grow,
  Grid,
  Modal,
  Button,
  Divider,
  Box,
  Typography,
  Tooltip,
  Tab,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import Wishform from "./Wishform/Wishform";

import WishPaginate from "./WishPagination/WishPagination";
import Pagination from "./FriendsTab/pagination/Pagination";

import { useDispatch } from "react-redux";
import { FavoriteBorder, Checklist } from "@mui/icons-material";
import { searchUsers } from "../../actions/user";
import useStyles from "./styles";

import Navbar from "../Navbar/Navbar";
import WishGrid from "./WishGrid/WishGrid";
import Wish from "./WishGrid/Wish/Wish";

import banner from "../../images/Homepage/10339.jpg";
import FriendsForm from "../Friends/FriendsForm";
import FriendsTab from "./FriendsTab/FriendsTab";
import Footer from "../Footer/Footer";
import NoResults from "../ErrorScreens/NoResults/NoResults";
import Clock from "../animations/clock";
import FriendsList from "./Sidebar/FriendsList";

import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import ReactCanvasConfetti from "react-canvas-confetti";

import WishListForm from "./WishlistForm/WishlistForm";
import WishListsGrid from "./WishListsGrid/WishListsGrid";
import WishListsPaginate from "./WishListsGrid/WishListsPagination/WishListsPagination";

import Share from "../Share/Share";
import { languages } from "../translations";
import constants from "../../constants/constants";
import EventBanner from "../EventBanner/EventBanner";
const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Wishboard = () => {
  const [currentId, setCurrentId] = useState(null);
  const [currentListId, setCurrentListId] = useState(null);
  const { error } = useSelector((state) => state.wish);
  const { bookedWishes, friends } = useSelector((state) => state.user);
  //"_id", "firstName", "lastName", "avatar", "bio", "userName"
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = query.get("page") || 1;
  const listPage = query.get("wlpage") || 1;
  const searchQuery = query.get("searchQuery");
  const [search, setSearch] = useState("");
  const [value, setValue] = useState("1");
  const { lang } = useSelector((state) => state.auth);
  const [tags, setTags] = useState([]);
  // Wish form
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Wish list form
  const [openWlf, setOpenWlf] = useState(false);
  const handleOpenWlf = () => setOpenWlf(true);
  const handleCloseWlf = () => setOpenWlf(false);
  const handleKeyPress = (e) => {
    if (e.keycode === 13) {
      //search
    }
  };
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        colors: ["#FEFEDF", "#F1CE89", "#058686"],
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    if (!user) {
      navigate("/auth");
    }
  }, [error]);

  const styleActivePage = () => {
    let menuItem = document.querySelector(".--js-wishboard-page");
    if (menuItem) {
      menuItem.classList.add("active-page");
    } else {
      return;
    }
  };
  useEffect(() => {
    styleActivePage();
  }, []);
  const editWish = (id) => {
    handleOpen();
    setCurrentId(id);
  };
  const createWish = (id) => {
    handleOpen();
    setCurrentId(null);
  };
  const createWishList = (id) => {
    handleOpenWlf();
    setCurrentListId(null);
  };
  const editWishList = (id) => {
    handleOpenWlf();
    setCurrentListId(id);
  };
  const searchUsr = () => {
    if (search.trim()) {
      dispatch(
        searchUsers({
          search: search.trim(),
        })
      );

      navigate(
        `/users/search?searchQuery=${search || "none"}&tags=${tags.join(",")}`
      );
    } else {
      return;
    }
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  // menuItem.length ? styleActivePage : "";
  let deadline = "February, 14, 2024";
  return (
    <>
      <Navbar />
      <Grow in>
        <Container maxWidth="xl" className="containerH">
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={3}
            className={`${classes.mainContainer} wishboard`}
          >
            <Grid item xs={12} md={8} lg={9}>
              <TabContext value={value}>
                <Box
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  className={classes.wishboardHeader}
                >
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    <Tab
                      className="tinyTab"
                      icon={<CardGiftcardOutlinedIcon />}
                      label={`${languages[lang].wishboard.tabs.wishes}`}
                      value="1"
                    />
                    <Tab
                      className="tinyTab"
                      icon={<Checklist />}
                      label={`${languages[lang].wishboard.tabs.wishlists}`}
                      value="2"
                    />
                    <Tab
                      className="tinyTab"
                      icon={<BookmarkAddedOutlinedIcon />}
                      label={`${languages[lang].wishboard.tabs.bookedwish}`}
                      value="3"
                    />

                    <Tab
                      className="tinyTab"
                      icon={<GroupAddOutlinedIcon />}
                      label={`${languages[lang].wishboard.tabs.friends}`}
                      value="4"
                    />
                  </TabList>
                  <Box
                    className="wish-manager"
                    sx={{
                      display: { md: "block" },
                    }}
                  >
                    <Button
                      onClick={createWishList}
                      variant="contained"
                      color="secondary"
                      className={classes.wishBtn}
                      size="medium"
                      endIcon={<Checklist />}
                    >
                      {languages[lang].wishboard.buttons.add.wishlist}
                    </Button>
                    <Button
                      onClick={createWish}
                      variant="contained"
                      className={classes.wishBtn}
                      color="gradient"
                      size="medium"
                      endIcon={<FavoriteBorder />}
                    >
                      {languages[lang].wishboard.buttons.add.wish}
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className={classes.modal}>
                        <Wishform
                          currentId={currentId}
                          setCurrentId={setCurrentId}
                          page={page}
                          closeModal={handleClose}
                        />
                      </Box>
                    </Modal>
                    <Modal
                      open={openWlf}
                      onClose={handleCloseWlf}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className={classes.modal}>
                        <WishListForm
                          currentListId={currentListId}
                          setCurrentListId={setCurrentListId}
                          page={page}
                          closeModal={handleCloseWlf}
                        />
                      </Box>
                    </Modal>
                  </Box>
                </Box>

                <TabPanel value="1">
                  <WishGrid
                    setCurrentId={setCurrentId}
                    edit={editWish}
                    fireConf={fire}
                  />
                  <WishPaginate page={page} />
                </TabPanel>
                <TabPanel value="2">
                  <WishListsGrid
                    setCurrentListId={setCurrentListId}
                    edit={editWishList}
                    fireConf={fire}
                  />
                  <WishListsPaginate wlpage={listPage} />
                </TabPanel>
                <TabPanel value="3">
                  <Grid container spacing={3}>
                    {bookedWishes?.map((wish) => (
                      <Grid key={wish._id} item xs={12} sm={4}>
                        <Wish
                          wishData={wish}
                          setCurrentId={setCurrentId}
                          page={page}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {!bookedWishes?.length && (
                    <Box className={classes.noBooked}>
                      <NoResults
                        message={{
                          title: `${languages[lang].errors.noresults.bookedWishes.title} `,
                          button: "none",
                          desc: `${languages[lang].errors.noresults.bookedWishes.desc} `,
                        }}
                      />
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value="4">
                  <FriendsTab />
                  <Pagination page={page} />
                </TabPanel>
              </TabContext>

              <Box className={classes.banner}>
                <EventBanner />
              </Box>
            </Grid>

            <Grid item xs={12} md={4} lg={3} className="sticky-desktop">
              <FriendsList />
              <FriendsForm />
              <Divider style={{ marginBlock: 30 }} />
              <Typography
                variant="h5"
                className={classes.titleMain}
                align="center"
              >
                {languages[lang].wishboard.titles.friends}
              </Typography>
              <div className="tiny-links">
                {/* <ShareLink />  */}
                <Share
                  type="wish"
                  shareText={languages[lang].share.shareWish.title}
                  id={`user/${user?._id}`}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grow>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <Footer />
    </>
  );
};
export default Wishboard;
