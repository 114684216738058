import React from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';
import {
  Card,
  cardActions,
  CardContent,
  CardMedia,
  Button,
  Box,
  Typography,
  CardActions,
  ButtonBase,
} from "@mui/material";
import {
  ThumbUpAlt,
  Delete,
  MoreHoriz,
  DeleteOutlined,
  FavoriteBorder,
} from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import constants from "../../../../constants/constants";
import { removeFriend } from "../../../../actions/user";
import {  useSelector } from "react-redux";
const Friend = ({ friendData, setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const lang = useSelector((state) => state.auth.lang);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const visitUser = () => {
    navigate(`/friend/${friendData.user._id}`);
  };
  
	moment.locale(lang === "ua" ? "uk" : lang);
  return (
    <Card className={classes.card}>
      <Box className={classes.friendCard}>
        <div className={classes.ava}>
          <ButtonBase className={classes.cardAction} onClick={visitUser}>
            <div className="avatar --lg">
              <CardMedia
                component="img"
                className={classes.media}
                image={
                  friendData?.user?.avatar ||
                  "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
                }
                // src={userData.selectedFile}
                title={friendData?.user?.firstName}
              />
            </div>
          </ButtonBase>

          <div className={classes.overlay2}>
            {(user?.sub === friendData?.creator ||
              user?._id === friendData?.creator) && (
              <>
                <Button
                  style={{ color: "red" }}
                  size="small"
                  onClick={() => {
                    setCurrentId(friendData._id);
                  }}
                >
                  <MoreHoriz fontSize="default" />
                </Button>
              </>
            )}
          </div>
        </div>
        <Box className={classes.friendData}>
          <Box className={classes.friendsName}>
            <Typography className={classes.title} variant="body">
              {friendData?.user?.firstName}
            </Typography>
            <Typography variant="body">{friendData?.user?.lastName}</Typography>
          </Box>
          <Typography className={classes.title} variant="h5" component="h4">
            {friendData?.user?.event && (
              <> Event {moment(friendData?.user?.event).fromNow()}</>
            )}
          </Typography>

          <CardContent
            sx={{
              "&:last-child": {
                p: 1,
                paddingBottom: 1,
              },
            }}
            className={classes.bio}
          >
            <Typography
              className={classes.title}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {friendData?.user?.bio}
            </Typography>
          </CardContent>
        </Box>
        <CardActions
          sx={{
            "&:last-child": {
              paddingLeft: 0,
            },
          }}
          className={classes.cardActions}
        >
          {(user?.sub === friendData?.creator ||
            user?._id === friendData?.creator) && (
            <Button
              endIcon={<DeleteOutlined />}
              size="small"
              color="primary"
              onClick={() => {
                dispatch(removeFriend(friendData.user._id));
              }}
            >
              Delete
            </Button>
          )}
          <ButtonBase className={classes.btn} onClick={visitUser}>
            <Button
              size="small"
              variant="contained"
              endIcon={<VisibilityOutlinedIcon />}
              color="primary"
              disabled={!user}
            >
              View
            </Button>
          </ButtonBase>
        </CardActions>
      </Box>
    </Card>
  );
};
export default Friend;
