import React, { useEffect } from "react";
import Friend from "./Friend/Friend";

import { Grid, CircularProgress, Box } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading/loading";
import NoResults from "../../ErrorScreens/NoResults/NoResults";


import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import { languages } from "../../translations";

const FriendsGrid = ({ setCurrentId }) => {
  const classes = useStyles();
  const friends = useSelector((state) => state.user.friends);
  const isLoading = useSelector((state) => state.user.isLoading);
  const { lang } = useSelector((state) => state.auth);
  moment.locale(lang === "ua" ? "uk" : lang);

  if (friends && !friends.length && !isLoading) return <NoResults message={{
    title: `${languages[lang].message.friends.title}`,
    button: `${languages[lang].message.friends.button}`,
    desc: `${languages[lang].message.friends.desc}`,
  }} />
  return isLoading ? (
    <Loading />

  ) : (
      <Grid
        className={classes.container}
        container
        alignItems="stretch"
        spacing={3}
      >
        {friends?.map((friend) => (
          <Grid key={friend._id} item xs={12} md={6}>
            <Box className="friend-wrapper">
              <Friend friendData={friend} setCurrentId={setCurrentId} />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
};
export default FriendsGrid;
