import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUser,
  fetchUsers,
  searchUsers,
  addFriend,
  bookWish,
  fetchFriends,
} from "../actions/user";

const initialState = {
  user: null,
  users: [],
  currentPage: 0,
  numberOfPages: 0,
  isLoading: true,
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    endLoading: (state, action) => {
      state.isLoading = false;
    },
    // booking wish wishout wishlists
    bookGeneralWish: (state, action) => {
      let obj = { ...action.payload.wishData };
      if (obj.booked === action.payload.id) {
        obj.booked = null;
      } else if (obj.booked === null) {
        obj.booked = action.payload.id;
      }

      state.user.wishList = state.user.wishList.map((wish) =>
        wish._id === obj._id ? obj : wish
      );
    },
  },
  extraReducers: (builder) => {
    //Get all users
    builder.addCase(fetchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
      }
      state.isLoading = false;
      state.users = action.payload.data;
      state.numberOfPages = action.payload.numberOfPages;
      state.currentPage = action.payload.currentPage;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //Get Single user
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.numberOfPages = action.payload.numberOfPages;
      state.isLoading = false;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.error = action.error.message;
    });

    // add friend
    builder.addCase(addFriend.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addFriend.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = state.users.map((user) =>
        user._id === action.payload._id ? action.payload : user
      );
    });
    builder.addCase(addFriend.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // Search user
    builder.addCase(searchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload.users;
    });

    builder.addCase(searchUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //Fetch friends
    // builder.addCase(fetchFriends.fulfilled, (state, action) => {
    //   if (action.payload.error === "JWT expired") {
    //     localStorage.clear();
    //   }
    //   state.isLoading = false;

    //   // state.numberOfPages = action.payload.friends.length;
    // });
  },
});

// Action creators are generated for each case reducer function
export const { startLoading, endLoading, bookGeneralWish } = usersSlice.actions;

export default usersSlice.reducer;
