import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';
import {
  Card,
  cardActions,
  CardContent,
  CardMedia,
  Box,
  Button,
  Typography,
  CardActions,
  ButtonBase,
} from "@mui/material";
import { FavoriteBorder } from "@mui/icons-material";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import constants from "../../../../constants/constants";
import { addFriend, searchUsers } from "../../../../actions/user";
import { languages } from "../../../translations";

const User = ({ UserData, setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const userFriends = useSelector((state) => state.user.friends);
  const isLoading = useSelector((state) => state.user.isLoading);
  const [disableInv, setDisableInv] = useState(user._id ? false : true);
  const [sent, setSent] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.auth.lang);
  moment.locale(lang === "ua" ? "uk" : lang);
  const inviteFriend = () => {
    if (!sent) {
      dispatch(addFriend(UserData._id)).then(setSent(true));
    }
  };

  const IsInvited = () => {
    if (UserData.invites.length > 0 && user._id) {
      let pending = UserData.invites.find((invite) => invite === user._id);
      setDisableInv(pending);
      return pending ? (
        <> {languages[lang].searchUsers.pending}</>
      ) : (
        <>
          <FavoriteBorder fontSize="small" /> &nbsp;{" "}
          {sent
            ? languages[lang].searchUsers.pending
            : languages[lang].searchUsers.invite}
        </>
      );
    }

    return (
      <>
        <FavoriteBorder fontSize="small" /> &nbsp;{" "}
        {sent
          ? languages[lang].searchUsers.pending
          : languages[lang].searchUsers.invite}
      </>
    );
  };

  const Invite = () => {
    let yourFriend = UserData.friends.find(
      (friends) => friends.user === user._id
    );

    setDisableInv(yourFriend);
    if (UserData.friends.length > 0 && user._id) {
      return yourFriend ? (
        <>{languages[lang].searchUsers.friends}</>
      ) : (
        <>
          <IsInvited />
        </>
      );
    }

    return <IsInvited />;
  };
  return (
    <Card className={classes.card}>
      <Box className={classes.friendCard}>
        <div className={classes.ava}>
          <ButtonBase
            className={classes.cardAction}
            onClick={() => {
              navigate(`/friend/${UserData._id}`);
            }}
          >
            <div className="avatar --lg">
              <CardMedia
                component="img"
                className={classes.media}
                image={
                  UserData?.avatar ||
                  "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
                }
                // src={userData.selectedFile}
                title={UserData.firstName}
              />
            </div>
          </ButtonBase>
        </div>

        <Typography
          className={classes.title}
          gutterBottom
          variant="h5"
          component="h4"
        >
          {UserData.firstName}
          {"\u00A0"}
          {UserData.lastName}
        </Typography>

        <CardActions className={classes.cardActions}>
          {(user?.sub === UserData?.creator ||
            user?._id === UserData?.creator) && (
            <Button
              size="small"
              color="primary"
              onClick={() => {
                navigate(`/friend/${UserData._id}`);
              }}
            >
              <RemoveRedEyeOutlinedIcon className={classes.cardIcon} />
              {languages[lang].searchUsers.viewProfile}
            </Button>
          )}
          <Button
            size="small"
            color="primary"
            disabled={disableInv}
            onClick={inviteFriend}
          >
            <Invite />
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
export default User;
