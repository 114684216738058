import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Stack,
  TextField,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Box,

  Autocomplete,
  Chip,
} from "@mui/material";
import image3 from "../../../images/Homepage/image5.jpg";
import {
  RedoOutlined,
  Close,
  Key,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import copyText from "../helpers/copy";
import constants from "../../../constants/constants";
import ShareLink from "../../Share/ShareLink";
import { languages } from "../../translations";
const NoResults = ({ message }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const classes = useStyles();
  const navigate = useNavigate();
  const [displayShare, setDisplayShare] = useState(false);
  const toggleShareModal = () => {
    setDisplayShare((prevDisplayShare) => !prevDisplayShare);
  };
  const { lang } = useSelector((state) => state.auth);
  moment.locale(lang === "ua" ? "uk" : lang);
  return (
    <>
      <Paper className={classes.paper}>
        <div className="decor-card2"></div>
        <Typography variant="h4" className={classes.paperTitle} align="center">
          {message
            ? `${message.title}`
            : `${languages[lang].message.title}`}
        </Typography>
        <Box className={classes.signIn}>
          <Typography variant="p" align="center">
            {message && message.desc
              ? `${message.desc}`
              : `${languages[lang].message.desc}`}
            {message && message.button === "none" ? "" : <RedoOutlined />}
          </Typography>
        </Box>
        {message && message.button === "none" ? (
          ""
        ) : (
            <>
              {/* <Button
                endIcon={<Key />}
                variant="contained"
                className={classes.wishboard}
                id="myInput"
                onClick={() => {
                  message && message.button === "copy"
                    ? copyText(`user/${user?._id}`)
                    : navigate("/auth");
                }}
              >
                Share the link
            </Button> */}
              <ShareLink shareText=" Link" />
            </>
          )}

      </Paper>
    </>
  );
};
export default NoResults;
