import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent!important",
    padding: theme.spacing(6, 10),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    isolation: "isolate",
    overflow: "hidden",
    gap: `${theme.spacing(2)} ${theme.spacing(2)}`,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(4, 2),
    },
  },
  paperTitle: {
    maxWidth: "30ch",
  },
  subtitle: {
    color: "#A0D0D0!important",
  },
  signIn: {
    gap: `${theme.spacing(2)} ${theme.spacing(2)}`,
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  paws: {
    display: "grid",
    gridTemplateColumns: `${theme.spacing(6)} ${theme.spacing(6)}`,
    gap: `${theme.spacing(4)} ${theme.spacing(2)}`,
  },
  paw00: {
    width: `${theme.spacing(6)}!important`,
    height: `${theme.spacing(6)}!important`,
  },
  paw01: {
    transform: "translate(0px, 0px) rotate(-4deg)",
  },
  paw02: {
    transform: "translate(0px, -20px) rotate(8deg)",
  },
  paw04: {
    transform: "translate(0px, 0px) rotate(-6deg)",
  },
  paw04: {
    transform: "translate(0px, -20px) rotate(6deg)",
  },
}));
