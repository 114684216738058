import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  container: {
    flexDirection: "column",
    gap: theme.spacing(2, 0)
  },
  row: {
    width: "100%",
  },
  smMargin: {
    // margin: theme.spacing(1),
  },
  actionDiv: {
    textAlign: "center",
  },

}));
