import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Grid, Paper, TextField, Button, IconButton, Box } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { useDispatch } from "react-redux";

import { searchUsers } from "../../actions/user";
import useStyles from "./styles";

import SearchGrid from "./SearchGrid/SearchGrid";
import { languages } from "../translations";
import constants from "../../constants/constants";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FriendSearch = () => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentId, setCurrentId] = useState(null);
  const { error } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const { lang } = useSelector((state) => state.auth);
  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchFriend();
    }
  };
  const clear = (e) => {
    setSearch("");
    navigate("/friends");
  };

  const searchFriend = () => {
    if (search.trim()) {
      dispatch(
        searchUsers({
          search: search.trim(),
        })
      );

      navigate(`/user/search?searchQuery=${search || "none"}`);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div
        className={search ? "headerSearch is-active" : "headerSearch"}
        sx={{ display: { xs: "none", md: "grid" } }}
      >
        <Box className="clear-btn">
          <Button
            onClick={clear}
            style={{ marginTop: "10px" }}
            variant="text"
            endIcon={<RestartAlt />}
          >
            {languages[lang].search.clear}
          </Button>
        </Box>
        <TextField
          style={{ marginBottom: "0px" }}
          name="search"
          variant="standard"
          label={`${languages[lang].search.placeholder}`}
          fullWidth
          value={search}
          size="small"
          className="input-focus"
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <IconButton
          variant="contained"
          color="primary"
          size="large"
          className="searchButton"
          id="myInput"
          onClick={searchFriend}
        >
          <SearchOutlinedIcon />
        </IconButton>
      </div>
    </>
  );
};
export default FriendSearch;
