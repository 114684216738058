import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  paginationWrapper: {
    padding: theme.spacing(2, 0),

  },
  ul: {
    justifyContent: "center",
  },
}));
