import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBlock: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(4, 4),
    isolation: "isolate",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 2),
      width: "80%",
      maxHeight: "90vh",
      overflowY: "auto",
      marginInline: "auto",
    },
  },
  formHeader: {
    width: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  clearBtn: {
    // width: "100px",
    // right: 0,
    // top: 0,
    // position: "absolute",
  },
  fullW: {
    width: "100%",
    display: "flex",
    paddingBlock: theme.spacing(0, 1),
  },
  fileInput: {
    width: "100%",
    margin: "10px 5px",
    // marginBlock: theme.spacing(1),
  },
  rating: {
    width: "100%",
    margin: "10px 0",
  },
  textarea: {
    // width: "100%",
    padding: theme.spacing(2, 1),
    borderRadius: "20px",
    backgroundColor: "#1f353b!important",
    borderColor: "#fff!important",
    color: "#fff!important",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 1),
    },
  },

  buttonSubmit: {
    marginBottom: 10,
  },
  dateInput: {
    color: theme.palette.neutral.light,
    width: "100%",
  },
  tagsInputWrapper: {
    // margin: theme.spacing(1),
  },
  tagsInput: {
    // margin: "0!important",
  },
  modal: {
    top: "50%",
    position: "absolute",
    left: "50%",
    width: "100%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.up("sm")]: {
      width: "clamp(1000px, 90vw, 1440px)",
    },
  },
}));
