import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container, Grow, Grid, Button, Box, Typography } from "@mui/material";

import Navbar from "../Navbar/Navbar";
import Stack from "@mui/material/Stack";

import Pagination from "../pagination/Pagination";

import { useDispatch } from "react-redux";

import useStyles from "./styles";

import constants from "../../constants/constants";
// import image1 from "../../images/Homepage/image1.jpg";
import image1 from "../../images/Homepage/levi2.jpg";
import image2 from "../../images/Homepage/image6.jpg";
// import image3 from "../../images/Homepage/image2.jpg";
import image3 from "../../images/Homepage/levi4-gen.jpg";
import banner from "../../images/Homepage/10339.jpg";
// import banner from "../../images/Homepage/10430.jpg";
import light from "../../images/Homepage/light.jpg";

import { Circle, Underline, Wavy, Background } from "../Home/decor/circle";
import { Checklist, Redeem, FavoriteBorder } from "@mui/icons-material";

import { gsap } from "gsap";
import Clock from "../animations/clock";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import MyThree from "../animations/scene";
import Footer from "../Footer/Footer";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { languages } from "../translations";
import EventBanner from "../EventBanner/EventBanner";
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Home = () => {
  const [currentId, setCurrentId] = useState(null);
  const lang = useSelector((state) => state.auth.lang);
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = query.get("page") || 1;
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));

  const component = useRef();
  const slider = useRef();

  useEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel");

      let timeLine = gsap.timeline({ repeat: 0, repeatDelay: 4 });
      if (window.innerWidth > 1024) {
        timeLine.to(".gift", {
          duration: 0.5,
          scale: 1,
          x: 0,
          rotate: "10deg",
        });
        timeLine.to(
          "circle",
          {
            duration: 0.5,
            opacity: 1,
            x: 0,
          },
          "<"
        );
        timeLine.to(".gift", {
          ease: "none",
          duration: 15,
          immediateRender: true,
          motionPath: {
            path: ".animation",
            align: ".animation",
            alignOrigin: [0.5, 0.52],
            autoRotate: true,
          },
        });
        timeLine.to(
          "#as",
          {
            duration: 20,
            strokeDashoffset: 0,
            ease: "none",
          },
          "<"
        );
        timeLine.to(
          ".gift",
          {
            duration: 1,
            scale: 0.5,
            rotate: "10deg",
          },
          "<78%"
        );

        ScrollTrigger.create({
          trigger: slider.current,
          start: "0% 90%",
          scrub: 0.4,
          // markers: true,
          toggleActions: "restart none none reset",
          end: () => "+=" + slider.current.offsetHeight - 0,
          animation: timeLine,
        });
      }
      panels.forEach((panel) => {
        let outerWrapper = panel.querySelector(".image-wrapper");
        let innerWrapper = panel.querySelector(".image-reverse");
        let svg = panel.querySelector(".decor path");

        let innerTL = gsap.timeline({ repeat: 0, repeatDelay: 4 });
        innerTL.to(
          svg,
          {
            strokeDashoffset: 0,
            duration: 2,
          },
          0
        );
        innerTL.to(
          outerWrapper,
          {
            rotate: "180deg",
            duration: 3,
          },
          "<"
        );
        innerTL.to(
          innerWrapper,
          {
            rotate: "-180deg",
            duration: 3,
          },
          "<"
        );
        ScrollTrigger.create({
          trigger: panel,
          start: "0% 90%",
          scrub: 0.4,
          toggleClass: "active",
          toggleActions: "restart none none reset",
          end: "100% 0%",
          animation: innerTL,
        });
      });
    }, component);
    return () => ctx.revert();
  });

  let deadline = "February, 14, 2024";
  return (
    <>
      {" "}
      <Navbar />
      <Grow in>
        <div ref={component}>
          <Container maxWidth="xl">
            <Grid
              container
              columnSpacing={{ xs: 0, md: 3 }}
              className={classes.mainContainer}
            >
              <Grid item xs={12} sm={6} className={classes.content}>
                <Typography variant="p" className={classes.preTitle}>
                  {languages[lang].home.hero.a1}
                </Typography>
                <Typography variant="h1" className={classes.logo}>
                  {languages[lang].home.hero.a2}{" "}
                  <span className={classes.dot}>
                    {" "}
                    {languages[lang].home.hero.a3}
                  </span>
                </Typography>
                <Typography variant="p" className={classes.slogan}>
                  {languages[lang].home.hero.a4}
                </Typography>

                <Box mt={3}>
                  {!user && (
                    <Grid container spacing={2}>
                      <Grid item xs="auto">
                        <Button
                          component={Link}
                          to="/auth"
                          variant="contained"
                          color="gradient"
                          size="large"
                          endIcon={<FavoriteBorder />}
                          className={classes.actionLink}
                        >
                          {" "}
                          {languages[lang].home.hero.make}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {user && (
                    <Grid container spacing={2}>
                      <Grid item xs="auto">
                        <Button
                          component={Link}
                          to="/wishboard"
                          variant="contained"
                          color="gradient"
                          size="large"
                          className={classes.actions}
                          endIcon={<Checklist />}
                        >
                          {" "}
                          {languages[lang].home.hero.create}
                        </Button>
                      </Grid>
                      <Grid item xs="auto">
                        <Button
                          component={Link}
                          to="/wishboard"
                          color="secondary"
                          size="large"
                          variant="contained"
                          className={classes.actionLink}
                          endIcon={<Redeem />}
                        >
                          {" "}
                          {languages[lang].home.hero.choose}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.canvas}>
                <MyThree />
              </Grid>
            </Grid>
            <div ref={slider} className="container">
              <div className="bg-wrapper">
                <Background />
              </div>

              <div className="panel small" datasvg="0% 50%">
                <Grid className={classes.introRow} maxWidth="lg">
                  <Grid
                    item
                    xs="auto"
                    md="auto"
                    className={classes.introRowContent}
                  >
                    <Typography className={`${classes.preTitle} ${classes.mt}`}>
                      {languages[lang].home.howReceive}
                    </Typography>
                    <Typography variant="h2" className={classes.h2}>
                      {languages[lang].home.three}
                      <span className="decor">
                        <span className="circle">
                          {languages[lang].home.simple}
                        </span>
                      </span>
                      {languages[lang].home.steps}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className="panel primaryGreen">
                <Grid
                  container
                  direction="row-reverse"
                  className={`${classes.rowGrid1}`}
                >
                  <Grid
                    item
                    xs="auto"
                    md={4}
                    className={`${classes.grid1Content}`}
                  >
                    <Typography
                      className={`${classes.preTitle} ${classes.preTitleDark}`}
                    >
                      {" "}
                      {languages[lang].home.s1.title}
                    </Typography>
                    <Typography variant="h4" className={classes.h4}>
                      {" "}
                      {languages[lang].home.s1.create} &nbsp;
                      <span className="decor">
                        <span className="circle">
                          {" "}
                          {languages[lang].home.s1.account}{" "}
                        </span>
                        <Circle className="svg" />
                      </span>
                    </Typography>
                    <Typography className={classes.text}>
                      {" "}
                      {languages[lang].home.s1.instr}
                    </Typography>
                    <Box mt={3}>
                      <Button
                        component={Link}
                        to="/wishboard"
                        variant="contained"
                        color="gradient"
                        className={classes.actionLink2}
                        endIcon={<Checklist />}
                      >
                        {" "}
                        {languages[lang].home.s1.button}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs="auto" className={classes.grid1Media}>
                    <div className="image-wrapper">
                      <div className="image-reverse">
                        <img src={image1} alt="avocado" className="image" />{" "}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="panel primaryYellow">
                <Grid
                  container
                  direction="row"
                  className={`${classes.rowGrid1}`}
                >
                  <Grid
                    item
                    xs="auto"
                    md={4}
                    className={`${classes.grid1Content}`}
                  >
                    <Typography
                      className={`${classes.preTitle} ${classes.preTitleDark}`}
                    >
                      {languages[lang].home.s2.title}
                    </Typography>
                    <Typography variant="h4" className={classes.h4}>
                      {languages[lang].home.s2.create}{" "}
                      <span className="decor">
                        <span className="undelineSvg">
                          {" "}
                          {languages[lang].home.s2.wishlist}{" "}
                        </span>
                        <Underline className="svg" />
                      </span>
                    </Typography>

                    <Typography className={classes.text}>
                      {languages[lang].home.s2.instr}
                    </Typography>
                    <Box mt={3}>
                      <Button
                        component={Link}
                        to="/wishboard"
                        variant="contained"
                        color="gradient"
                        className={classes.actionLink2}
                        endIcon={<Checklist />}
                      >
                        {" "}
                        {languages[lang].home.s2.button}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs="auto" className={classes.grid1Media}>
                    <div className="image-wrapper">
                      <div className="image-reverse">
                        <img src={image2} alt="avocado" className="image" />{" "}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="panel primaryPurple">
                <Grid
                  container
                  direction="row-reverse"
                  className={`${classes.rowGrid1}`}
                >
                  <Grid
                    item
                    xs="auto"
                    md={4}
                    className={`${classes.grid1Content} }`}
                  >
                    <Typography
                      className={`${classes.preTitle} ${classes.preTitleDark} `}
                    >
                      {languages[lang].home.s3.title}
                    </Typography>
                    <Typography variant="h4" className={classes.h4}>
                      {languages[lang].home.s3.share}{" "}
                      <span className="decor">
                        {" "}
                        <span className="wavy">
                          {" "}
                          {languages[lang].home.s3.account}
                        </span>
                        <Wavy className="svg" />
                      </span>
                    </Typography>
                    <Typography className={classes.text}>
                      {languages[lang].home.s3.instr}
                    </Typography>

                    <Box mt={3}>
                      <Button
                        component={Link}
                        to="/wishboard"
                        variant="contained"
                        color="gradient"
                        className={classes.actionLink2}
                        endIcon={<Checklist />}
                      >
                        {" "}
                        {languages[lang].home.s3.button}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs="auto" className={classes.grid1Media}>
                    <div className="image-wrapper">
                      <div className="image-reverse">
                        <img src={image3} alt="avocado" className="image" />{" "}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>
          <div className="banner">
            <Grid container className={`${classes.rowGrid1}`}>
              <Grid item xs={12} className={classes.banner}>
                <EventBanner />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grow>
      <Footer />
    </>
  );
};
export default Home;
