import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';
import {
  Card,
  cardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardActions,
  Box,
  ButtonBase,
  Rating,
  Dialog,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Tooltip,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Toast from "../../../toast/Toast";
import {
  ThumbUpAlt,
  Delete,
  MoreHoriz,
  DeleteOutlined,
  FavoriteBorder,
  LinkOutlined,
  Close,
} from "@mui/icons-material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import CakeIcon from "@mui/icons-material/Cake";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { AlarmAdd, RestartAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import constants from "../../../../constants/constants";
import {
  deleteWish,
  markComplete,
  getWishes,
} from "../../../../actions/wishes";
import { bookWish, getWishboard } from "../../../../actions/user";
import gift from "../../../../images/utils/gift.jpeg";
import { ScheduleOutlined, ModeEditOutlined } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import Share from "../../../Share/Share";
import { LikeIcon, ShareIcon } from "../../WishGrid/Wish/decor/icons";
import { bookGeneralWish } from "../../../../reducers/users";

import { languages } from "../../../translations";

const Wish = ({ wishData, setCurrentId, page, edit, fire, update = null }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));

  const { bookedWishes } = useSelector((state) => state.user);
  const [showDelete, setShowDelete] = useState(false);
  const [displayShare, setDisplayShare] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.auth);
  moment.locale(lang === "ua" ? "uk" : lang);
  const openWish = () => {
    navigate(`/wish/${wishData._id}`);
  };

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#F1CE89",
    },
    "& .MuiRating-iconHover": {
      color: "#F1CE89",
    },
  });
  const toggleDeleteModal = () => {
    setShowDelete((prevShowDelete) => !prevShowDelete);
  };
  const toggleShareModal = () => {
    setDisplayShare((prevDisplayShare) => !prevDisplayShare);
  };
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleClosePopper = (event, reason) => {
    setOpen(false);
  };
  //bookWish
  const Books = () => {
    if (!user) {
      return (
        <Typography className={classes.loginInfo}>
          {languages[lang].wishcard.login}
        </Typography>
      );
    }
    if (wishData?.booked) {
      return wishData.booked === user?._id ? (
        <>
          <Box className="animate-like">
            <LikeIcon />
            <FavoriteIcon fontSize="small" />{" "}
          </Box>{" "}
          &nbsp; {languages[lang].wishcard.youBooked}
        </>
      ) : (
        <>
          <Box className="animate-like">
            <LikeIcon />
            <FavoriteBorder fontSize="small" />{" "}
          </Box>{" "}
          &nbsp; {languages[lang].wishcard.notYouBooked}
        </>
      );
    }
    return (
      <>
        <Box className="book-btn">
          <Tooltip
            className="book-tooltip"
            title={languages[lang].wishcard.tooltip.book}
          >
            <Box className="animate-like">
              <LikeIcon />
            </Box>
            <FavoriteBorder fontSize="small" />
            &nbsp; {languages[lang].wishcard.book}
          </Tooltip>
        </Box>
      </>
    );
  };

  return (
    <Card className={classes.card}>
      <ButtonBase className={classes.cardAction} onClick={openWish}>
        <CardMedia
          component="img"
          className={classes.media}
          image={wishData?.selectedFile || gift}
          // src={userData.selectedFile}
          title={wishData.title}
        />
        <div className="overlay-cardMedia"></div>
      </ButtonBase>
      <div className={classes.overlay}>
        {/* <Typography variant="h6">{wishData.title}</Typography> */}
        {/* <Box className={classes.iconWidget}>
          <span className="icon-grid --center">
            <ScheduleOutlined />
            <Typography variant="body2" className={` alttext2`}>
              {moment(wishData.updatadeAt).fromNow()}
            </Typography>
          </span>
        </Box> */}
      </div>
      <div className={classes.overlay}>
        {user?._id === wishData?.creator && (
          <div className={`wishlistBadge lvl${wishData?.permission || 1}`}>
            <span className="wishlistBadge-text">
              {languages[lang].permissionCodes[wishData?.permission || 1]}
            </span>
            <span className="wishlistBadge-lvl">
              {wishData?.permission || 1}
            </span>
          </div>
        )}
      </div>
      <div className={classes.overlay2}>
        <div className="rating-wrap">
          <StyledRating
            size="small"
            name="customized-color"
            defaultValue={wishData?.dreamScale || 1}
            // getLabelText={(value) => customIcons[value]}
            readOnly
            precision={1}
            icon={<CakeIcon fontSize="inherit" />}
            emptyIcon={<CakeIcon fontSize="inherit" />}
          />
        </div>
      </div>

      <div className={classes.details}>
        <Typography variant="body2" color="light">
          {wishData.tags?.map((tag) => (tag.length ? `#${tag}` : ""))}
        </Typography>
      </div>
      <div className="date-wrapper">
        <Box className={classes.iconWidget}>
          <span className="icon-grid -no-shadow --center">
            <ScheduleOutlined />
            <Typography variant="body2" className={` alttext2`}>
              {moment(wishData.updatadeAt).fromNow()}
            </Typography>
          </span>
        </Box>
      </div>
      <div className={classes.cardTitle}>
        <Typography
          className={`${classes.title} new-title`}
          // gutterBottom
          variant="h6"
          component="h6"
        >
          {wishData.title}
        </Typography>

        {wishData?.link && (
          <div className="row-wrapper">
            {/* <Tooltip title="Openl wish external link"> */}
            <a
              className="link -rounded"
              target="_blank"
              href={wishData?.link}
              rel="nofollow"
            >
              <LinkOutlined />
            </a>
            {/* </Tooltip> */}
          </div>
        )}
      </div>
      <Typography
        className={classes.title}
        // gutterBottom
        variant="body2"
        component="p"
      >
        {wishData.event && (
          <>
            {" "}
            {languages[lang].user.event} {moment(wishData?.event).fromNow()}
          </>
        )}
      </Typography>

      <CardContent className={classes.cardBody}>
        <Typography
          className={`${classes.desc} new-title alttext`}
          // variant="body2"
          // component="p"
          sx={{ p: 0 }}
        >
          {wishData.description}
        </Typography>
        <Typography
          className={classes.title}
          // variant="body2"
          color="primary.main"
          // component="p"
          sx={{ p: 0 }}
        ></Typography>
      </CardContent>

      <CardActions className={classes.cardActions}>
        {user?._id !== wishData?.creator && (
          <>
            <Button
              size="small"
              color="primary"
              disabled={!user}
              onClick={() => {
                if (update !== null) {
                  dispatch(bookWish(wishData._id)).then(
                    dispatch(update({ wishData, id: user?._id }))
                  );
                } else {
                  dispatch(bookWish(wishData._id));
                }
              }}
            >
              <Books />
            </Button>
          </>
        )}
        {user?._id === wishData?.creator && (
          <>
            <Button
              className="animated-share"
              endIcon={<ShareIcon />}
              size="small"
              color="primary"
              onClick={toggleShareModal}
            >
              {languages[lang].wishcard.buttons.share}
            </Button>

            <>
              <div className="more-actions">
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  {languages[lang].wishcard.buttons.more}{" "}
                  <MoreHoriz style={{ marginLeft: 10 }} fontSize="default" />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClosePopper}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              className="quick-action_item"
                              onClick={handleClose}
                            >
                              <Button
                                endIcon={<DeleteOutlined />}
                                size="small"
                                color="primary"
                                onClick={toggleDeleteModal}
                              >
                                {languages[lang].wishcard.buttons.delete}
                              </Button>
                            </MenuItem>
                            <MenuItem
                              className="quick-action_item"
                              onClick={handleClose}
                            >
                              <Button
                                style={{ color: "white" }}
                                size="small"
                                endIcon={<DoneIcon />}
                                onClick={() => {
                                  dispatch(
                                    markComplete({ wishid: wishData._id })
                                  ).then(fire());
                                }}
                              >
                                {languages[lang].wishcard.buttons.received}
                              </Button>
                            </MenuItem>
                            <MenuItem
                              className="quick-action_item"
                              onClick={handleClose}
                            >
                              <Button
                                endIcon={<ModeEditOutlined />}
                                size="small"
                                color="primary"
                                onClick={() => {
                                  edit(wishData._id);
                                }}
                              >
                                {languages[lang].wishcard.buttons.edit}
                              </Button>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </>
          </>
        )}
      </CardActions>
      <Dialog open={showDelete}>
        <DialogTitle> {languages[lang].dialog.delete.wish.title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.desc}>
            {languages[lang].dialog.delete.wish.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button endIcon={<Close />} onClick={toggleDeleteModal}>
            {languages[lang].dialog.delete.wish.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<DeleteOutlined />}
            onClick={() => {
              dispatch(deleteWish({ wishid: wishData._id, page: page }));
            }}
          >
            {languages[lang].dialog.delete.wish.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        className={classes.share}
        open={displayShare}
        PaperProps={{
          style: {
            maxWidth: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogActions className={classes.btns}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Close />}
            onClick={toggleShareModal}
          >
            {languages[lang].dialog.share.wish.close}
          </Button>
        </DialogActions>
        <DialogTitle className={classes.dialogTitle}>
          {languages[lang].dialog.share.wish.title}
        </DialogTitle>
        <DialogContent className={classes.desc}>
          <DialogContentText></DialogContentText>
          <Share
            type="wish"
            shareText="your Wish"
            id={`wish/${wishData._id}`}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};
export default Wish;
