export const LikeIconW = () => {
    return (
        <>
            <svg className="like-svg path-1" width="107" height="98" viewBox="0 0 107 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.549 24.686c-4.727-4.88-12.842-3.704-15.989 2.317l-6.62 12.663a10.665 10.665 0 0 1-10.422 5.681l-14.23-1.297c-6.765-.619-12.15 5.566-10.612 12.181a20.281 20.281 0 0 0 17.085 15.51l31.159 4.136L83.326 47.45a20.28 20.28 0 0 0-3.777-22.763ZM44.654 17.12C54.537-1.787 80.026-5.482 94.871 9.842a41.612 41.612 0 0 1 7.751 46.705l-14.015 29.72a19.382 19.382 0 0 1-20.083 10.946l-32.571-4.325A41.614 41.614 0 0 1 .898 61.066c-4.834-20.78 12.082-40.2 33.328-38.262l7.117.649 3.311-6.332Z" fill="#C49A57" />
            </svg>

        </>
    );
};

export const LikeIcon = () => {
    return (
        <>
            <svg className="like-svg" width="157" height="121" viewBox="0 0 157 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="path-1 path-active" d="M19.5 91C19.3027 91 19.1055 90.9486 18.9288 90.8457C18.7368 90.7341 14.1763 88.065 9.55044 84.0433C6.80872 81.6598 4.62016 79.2957 3.04569 77.0168C1.00825 74.0679 -0.0163324 71.2314 0.000196834 68.5861C0.0195444 65.5079 1.11329 62.6131 3.0802 60.4348C5.08031 58.2198 7.74952 57 10.5963 57C14.2447 57 17.5803 59.0601 19.5001 62.3234C21.4198 59.0601 24.7554 57 28.4038 57C31.0933 57 33.6593 58.1006 35.6294 60.0991C37.7914 62.2922 39.0199 65.391 38.9998 68.6007C38.9832 71.2414 37.9394 74.0736 35.8974 77.0184C34.3181 79.2961 32.1326 81.6592 29.4016 84.042C24.7926 88.0633 20.2649 90.7323 20.0744 90.844C19.8968 90.9479 19.6983 91 19.5 91Z" fill="#C49A57" />
                <path className="path-3 path-active" d="M76.5 44C76.242 44 75.9841 43.9335 75.753 43.8004C75.502 43.6559 69.5383 40.2018 63.489 34.9973C59.9037 31.9127 57.0417 28.8532 54.9828 25.9041C52.3185 22.0879 50.9786 18.4172 51.0003 14.9937C51.0256 11.0102 52.4558 7.26396 55.0279 4.44498C57.6435 1.57851 61.134 0 64.8567 0C69.6276 0 73.9896 2.66596 76.5001 6.88916C79.0105 2.66606 83.3725 0 88.1435 0C91.6605 0 95.016 1.42429 97.5923 4.01056C100.42 6.84871 102.026 10.8589 102 15.0126C101.978 18.4301 100.613 22.0952 97.9428 25.9062C95.8775 28.8538 93.0195 31.9119 89.4482 34.9956C83.4211 40.1996 77.5002 43.6536 77.2511 43.7981C77.0189 43.9326 76.7594 44 76.5 44Z" fill="#C49A57" />
                <path className="path-4 path-active" d="M119.5 121C119.323 121 119.146 120.955 118.987 120.864C118.815 120.765 114.722 118.41 110.571 114.862C108.11 112.759 106.146 110.673 104.733 108.662C102.905 106.06 101.985 103.557 102 101.223C102.018 98.507 102.999 95.9527 104.764 94.0307C106.559 92.0763 108.955 91 111.509 91C114.784 91 117.777 92.8177 119.5 95.6972C121.223 92.8178 124.216 91 127.491 91C129.904 91 132.207 91.9711 133.975 93.7345C135.915 95.6696 137.018 98.4038 137 101.236C136.985 103.566 136.048 106.065 134.216 108.663C132.798 110.673 130.837 112.758 128.386 114.861C124.25 118.409 120.186 120.764 120.015 120.862C119.856 120.954 119.678 121 119.5 121Z" fill="#C49A57" />
            </svg>



        </>
    );
};


export const LikeIcon2 = () => {
    return (
        <>

            <svg className="like-svg" width="512" height="712" viewBox="0 0 512 712" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="path-1 path-active" fillRule="evenodd" clipRule="evenodd" d="M70.2601 75.0639C56.1718 73.4596 44.6428 86.1118 47.5456 99.9906C50.6586 114.874 62.6646 126.274 77.6894 128.612L127.208 136.318C128.338 136.494 129.453 135.919 129.964 134.895L152.348 90.0578C159.14 76.4531 156.796 60.0644 146.463 48.9094C136.827 38.5075 119.836 40.5832 112.988 52.999L102.461 72.083C100.374 75.8675 96.2089 78.0183 91.9145 77.5294L70.2601 75.0639ZM26.6641 104.358C20.7842 76.2457 44.137 50.6182 72.6735 53.8675L87.231 55.5249L94.3074 42.6954C108.179 17.5466 142.595 13.342 162.113 34.4117C178.48 52.0802 182.193 78.0384 171.435 99.5867L149.05 144.424C144.392 153.755 134.233 159.002 123.927 157.398L74.409 149.691C50.611 145.988 31.5949 127.932 26.6641 104.358Z" fill="#F1CE89" />
                <path className="path-2 path-active" fillRule="evenodd" clipRule="evenodd" d="M96.5488 354.686C91.8222 349.807 83.7068 350.982 80.5601 357.003L73.9404 369.666C71.9382 373.498 67.8224 375.74 63.5182 375.347L49.288 374.05C42.5234 373.431 37.1372 379.616 38.6764 386.231C40.5889 394.453 47.3934 400.629 55.7607 401.741L86.9196 405.877L100.327 377.448C103.927 369.813 102.422 360.747 96.5488 354.686ZM61.6541 347.121C71.5372 328.213 97.0256 324.518 111.871 339.842C123.922 352.279 127.009 370.882 119.622 386.547L105.607 416.267C102.009 423.898 93.8877 428.324 85.5242 427.213L52.9529 422.889C35.7842 420.61 21.8222 407.934 17.8979 391.066C13.0638 370.287 29.9803 350.865 51.2263 352.804L58.3434 353.453L61.6541 347.121Z" fill="#F1CE89" />
                <path className="path-3 path-active" fillRule="evenodd" clipRule="evenodd" d="M349.333 52.7806C347.214 46.3268 339.4 43.8381 333.939 47.8773L322.451 56.3742C318.975 58.9442 314.293 59.1633 310.593 56.929L298.363 49.5417C292.548 46.0301 285 49.2375 283.494 55.8609C281.621 64.0913 285.04 72.6212 292.08 77.2774L318.299 94.6165L342.783 74.9054C349.358 69.6121 351.967 60.8002 349.333 52.7806ZM321.254 30.7253C338.406 18.0392 362.948 25.856 369.601 46.1256C375.005 62.5807 369.651 80.6617 356.161 91.5228L330.568 112.127C323.995 117.418 314.756 117.85 307.718 113.196L280.313 95.0713C265.868 85.5174 258.849 68.0151 262.692 51.1274C267.425 30.3249 291.129 20.2511 309.39 31.2804L315.509 34.9749L321.254 30.7253Z" fill="#F1CE89" />
                <path className="path-4 path-active" fillRule="evenodd" clipRule="evenodd" d="M447.694 204.967C449.054 198.312 443.502 192.277 436.756 193.077L422.567 194.76C418.275 195.269 414.099 193.137 411.995 189.363L405.036 176.883C401.727 170.95 393.582 169.994 388.99 174.999C383.283 181.218 382.023 190.321 385.828 197.855L400.001 225.912L431.037 220.934C439.371 219.597 446.006 213.238 447.694 204.967ZM434.244 171.892C455.43 169.379 472.867 188.336 468.596 209.238C465.13 226.207 451.515 239.254 434.415 241.998L401.973 247.202C393.642 248.538 385.404 244.332 381.599 236.801L366.787 207.473C358.979 192.014 361.562 173.335 373.272 160.574C387.697 144.856 413.276 147.86 423.667 166.492L427.148 172.734L434.244 171.892Z" fill="#F1CE89" />
                <path className="path-5 path-active" fillRule="evenodd" clipRule="evenodd" d="M437.561 418.472C432.834 413.594 424.719 414.769 421.572 420.789L414.952 433.453C412.951 437.284 408.834 439.526 404.531 439.134L390.299 437.837C383.535 437.218 378.15 443.403 379.688 450.018C381.602 458.24 388.405 464.416 396.774 465.527L427.931 469.664L441.339 441.235C444.938 433.6 443.434 424.533 437.561 418.472ZM402.666 410.908C412.55 392 438.037 388.305 452.883 403.629C464.934 416.066 468.021 434.669 460.633 450.334L446.619 480.053C443.021 487.684 434.899 492.111 426.536 490.999L393.964 486.675C376.798 484.397 362.835 471.72 358.91 454.852C354.075 434.074 370.993 414.652 392.239 416.591L399.355 417.239L402.666 410.908Z" fill="#F1CE89" />
            </svg>

        </>
    );
};



export const ShareIcon = () => {
    return (
        <>

            <svg className="drawingPlane" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2960_68)">
                    <path className="drawingIcon" d="M24.7218 11.3664L9.05591 6.77437C8.3427 6.56626 7.6008 6.81983 7.16428 7.42231C6.7288 8.0261 6.72064 8.8101 7.1436 9.42226L11.425 15.614C11.4829 15.6983 11.5046 15.8002 11.4862 15.9008L10.1072 23.307C9.97166 24.0369 10.2996 24.7499 10.942 25.1219C11.326 25.3436 11.7586 25.4084 12.1656 25.3214C12.4407 25.2627 12.7025 25.1347 12.931 24.9409L25.3579 14.3442C25.8432 13.9307 26.061 13.2883 25.928 12.6656C25.795 12.0429 25.3337 11.5455 24.7218 11.3664ZM24.4826 13.3164L12.0549 23.9125C11.8701 24.0708 11.688 23.9939 11.6179 23.9529C11.548 23.9126 11.3898 23.7931 11.4346 23.5542L12.8113 16.1584L16.8739 15.2906C17.2384 15.2128 17.4709 14.854 17.393 14.4895C17.3151 14.125 16.9564 13.8925 16.5919 13.9704L12.5293 14.8382L8.25434 8.65505C8.1155 8.45387 8.21089 8.27934 8.25887 8.21311C8.29137 8.16782 8.3656 8.08447 8.48442 8.05909C8.5387 8.0475 8.60328 8.04827 8.67681 8.06938L24.3434 12.6613C24.5398 12.719 24.5941 12.88 24.6084 12.9467C24.6226 13.0135 24.6383 13.1835 24.4826 13.3164Z" fill="#F1CE89" />
                    <path className="drawingPath" d="M6.3634 12.6101L3.86599 13.1435C3.77151 13.1637 3.67847 13.1034 3.65829 13.0089C3.63811 12.9145 3.6984 12.8214 3.79288 12.8012L6.29029 12.2678C6.38476 12.2476 6.4778 12.3079 6.49798 12.4024C6.51816 12.4968 6.45787 12.5899 6.3634 12.6101Z" stroke="#F1CE89" />
                    <path className="drawingPath" d="M6.38552 17.0189C6.29104 17.0391 6.198 16.9788 6.17782 16.8843C6.15757 16.7895 6.21755 16.6969 6.31241 16.6766L8.80908 16.1433C8.90356 16.1232 8.9966 16.1834 9.01678 16.2779C9.03696 16.3724 8.97667 16.4654 8.8822 16.4856L6.38552 17.0189Z" stroke="#F1CE89" />
                    <path className="drawingPath" d="M5.59561 21.2433L8.09302 20.7098C8.1875 20.6896 8.28053 20.7499 8.30072 20.8444C8.3209 20.9389 8.26061 21.0319 8.16613 21.0521L5.66872 21.5856C5.57425 21.6057 5.48121 21.5455 5.46103 21.451C5.44085 21.3565 5.50113 21.2635 5.59561 21.2433Z" stroke="#F1CE89" />
                </g>

            </svg>

        </>
    );
};


export const GiftWish = () => {
    return (
        <>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.25 4.5H14.85C15.25 4.02 15.5 3.42 15.5 2.75C15.5 1.23 14.27 0 12.75 0C11.07 0 9.59 0.89 8.75 2.21C7.91 0.89 6.43 0 4.75 0C3.23 0 2 1.23 2 2.75C2 3.42 2.25 4.02 2.65 4.5H1.25C0.56 4.5 0 5.06 0 5.75V8.25C0 8.85 0.43 9.33 1 9.45V16.25C1 16.94 1.56 17.5 2.25 17.5H15.25C15.94 17.5 16.5 16.94 16.5 16.25V9.45C17.07 9.33 17.5 8.85 17.5 8.25V5.75C17.5 5.06 16.94 4.5 16.25 4.5ZM16 8H9.5V6H16V8ZM12.75 1.5C13.44 1.5 14 2.06 14 2.75C14 3.44 13.44 4 12.75 4H9.59C9.93 2.57 11.22 1.5 12.75 1.5ZM4.75 1.5C6.28 1.5 7.57 2.57 7.91 4H4.75C4.06 4 3.5 3.44 3.5 2.75C3.5 2.06 4.06 1.5 4.75 1.5ZM1.5 6H8V8H1.5V6ZM2.5 9.5H8V16H2.5V9.5ZM15 16H9.5V9.5H15V16Z" fill="currentColor" />
            </svg>

        </>
    );
};
