/** @format */

import React, { useState, useEffect } from "react";
import useStyles from "./styles";

import {
	Button,
	TextField,
	Modal,
	TextareaAutosize,
	Typography,
	Paper,
	Tooltip,
	Box,
	ListSubheader,
	IconButton,
	Grid,
	ListItemIcon,
	Rating,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import { useDispatch, useSelector } from "react-redux";

import constants from "../../../constants/constants";
import { styled } from "@mui/material/styles";
import {
	AutoFixHighOutlined,
	RestartAlt,
	PermIdentityOutlined,
	HelpOutlined,
} from "@mui/icons-material";
import ErrorSignIn from "../../ErrorScreens/ErrorSignIn";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createWishList, updateWishList } from "../../../actions/wishes";
import { languages } from "../../translations";

const WishlistForm = ({
	currentListId,
	setCurrentListId,
	page,
	closeModal,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem(constants.wishProfile));
	const { friends } = useSelector((state) => state.user);
	const { wishlist, allWishes } = useSelector((state) => state.wish);
	const { lang } = useSelector((state) => state.auth);
	const [wishData, setWishData] = useState({
		title: "",
		description: "",
		permission: 0,
		event: null,
		selectedFile: {},
		selectedUsers: [],
		wishes: [],
	});
	const [hover, setHover] = useState(-1);
	const [showDate, setShowDate] = useState(false);
	const [openFriends, setOpenFriends] = useState(false);
	const [openWishes, setOpenWishes] = useState(false);
	const handleOpenFriends = () => setOpenFriends(true);
	const handleCloseFriends = () => setOpenFriends(false);
	const handleOpenWishes = () => setOpenWishes(true);
	const handleCloseWishes = () => setOpenWishes(false);
	moment.locale(lang === "ua" ? "uk" : lang);
	const selectedWishList = useSelector((state) =>
		currentListId
			? state.user.wishLists.find((w) => w._id === currentListId)
			: null
	);

	const handleToggleUser = (value) => () => {
		const currentIndex = wishData?.selectedUsers
			? wishData?.selectedUsers.findIndex((e) => e === value)
			: -1;
		const newChecked = wishData.selectedUsers
			? [...wishData.selectedUsers]
			: [];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setWishData({ ...wishData, selectedUsers: newChecked });
	};
	const handleToggleWish = (value) => () => {
		const currentIndex = wishData?.wishes
			? wishData?.wishes.findIndex((e) => e === value)
			: -1;
		const newChecked = wishData.wishes ? [...wishData.wishes] : [];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setWishData({ ...wishData, wishes: newChecked });
	};

	const StyledRating = styled(Rating)({
		"& .MuiRating-iconFilled": {
			color: "#F1CE89",
		},
		"& .MuiRating-iconHover": {
			color: "#F1CE89",
		},
	});

	useEffect(() => {
		if (selectedWishList) {
			setWishData(selectedWishList);
			if (selectedWishList.event) {
				setShowDate(true);
			}
		}
	}, [selectedWishList]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!wishData) return;
		if (currentListId) {
			dispatch(
				updateWishList({
					...wishData,
					selectedUsers: wishData.selectedUsers
						? JSON.stringify(wishData.selectedUsers)
						: null,
					wishes: wishData.wishes ? JSON.stringify(wishData.wishes) : null,
					id: currentListId,
				})
			);
			clear();
			closeModal();
		} else {
			// if (wishData.selectedUsers) {

			dispatch(
				createWishList({
					...wishData,
					selectedUsers: wishData.selectedUsers
						? JSON.stringify(wishData.selectedUsers)
						: null,
					wishes: wishData.wishes ? JSON.stringify(wishData.wishes) : null,
					page: page,
				})
			);

			clear();
			closeModal();
		}
	};
	const cancel = () => {
		clear();
		closeModal();
	};
	//MAX FILE SIZE 5 MB
	const uploadFile = (event) => {
		let sizeInKb = (event.target.files[0].size / (1024 * 1024)).toFixed(2);
		if (sizeInKb > constants.MAX_LIFE_SIZE) {
			clear();
		}
		let file = event.target.files[0];
		setWishData({ ...wishData, selectedFile: file });
	};
	const handleChange = (event) => {
		setWishData({ ...wishData, [event.target.name]: event.target.value });
	};

	const clear = () => {
		setCurrentListId(null);
		setShowDate(false);
		setWishData({
			title: "",
			description: "",
			permisson: 1,
			event: moment().add(1, "days").toISOString(),
			selectedUsers: [],
			wishes: [],
			selectedFile: "",
		});
	};
	if (!user) {
		return <ErrorSignIn />;
	}
	return (
		<Paper className={classes.paper}>
			<div className='decor-card --form'></div>
			<form
				autoComplete='off'
				className={`${classes.form} ${classes.root}`}
				noValidate
				onSubmit={handleSubmit}>
				<Box className={classes.formHeader}>
					<Typography variant='h4'>
						{currentListId
							? languages[lang].wishlistForm.title.editing
							: languages[lang].wishlistForm.title.creating}{" "}
						{languages[lang].wishlistForm.title.wishlist}
					</Typography>
					<Box className={classes.clearBtn}>
						<Button
							style={{ marginBottom: 10 }}
							onClick={cancel}
							// fullWidth
						>
							<CloseIcon />
						</Button>
					</Box>
				</Box>
				<Grid
					container
					justifyContent='space-between'
					alignItems='stretch'
					spacing={{ xs: 0, md: 3 }}>
					<Grid item xs={12} md={6}>
						{/* <Box> */}

						<TextField
							name='title'
							variant='standard'
							label={languages[lang].wishlistForm.title.name}
							fullWidth
							required
							value={wishData.title}
							onChange={
								(e) => handleChange(e)
								// setWishData({ ...wishData, title: e.target.value })
							}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<div className='input-file'>
							<Typography variant='p' className={classes.fullW}>
								{languages[lang].wishlistForm.coverImage}
							</Typography>

							<label htmlFor='upload-image'>
								<Button variant='contained' component='span'>
									{languages[lang].wishlistForm.upload}
								</Button>
								<input
									id='upload-image'
									hidden
									accept='image/png, image/jpeg, image/webp, image/jpg'
									type='file'
									onChange={uploadFile}
								/>
							</label>
							<Typography variant='p' className={classes.fullW}>
								{wishData?.selectedFile?.name}
							</Typography>
						</div>
					</Grid>

					<Grid item xs={12} md={12}>
						<Typography variant='p' className={classes.fullW}>
							{languages[lang].wishlistForm.description}
						</Typography>
						<TextareaAutosize
							className={classes.textarea}
							aria-label='description'
							minRows={3}
							maxRows={8}
							style={{ width: "100%", resize: "vertical", overflow: "auto" }}
							name='description'
							variant='soft'
							label={languages[lang].wishlistForm.shortDescription}
							value={wishData?.description}
							onChange={(e) => handleChange(e)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={wishData?.wishes?.length > 0 ? true : false}
									/>
								}
								label={languages[lang].wishlistForm.addWishes}
								onChange={handleOpenWishes}
							/>
						</FormGroup>
					</Grid>
					<Grid item xs={12} md={6}>
						{!showDate ? (
							<FormGroup className='helper-grid --offset'>
								<FormControlLabel
									control={<Checkbox />}
									label={languages[lang].wishlistForm.eventDate}
									onChange={(e) => setShowDate(true)}
								/>
								<Tooltip
									title={languages[lang].wishlistForm.tootlTips.eventTooltip}
									className='helper-btn'>
									<IconButton
										size='large'
										className='helper-btn -new'
										aria-label='account of current user'
										aria-controls='menu-appbar'
										aria-haspopup='true'
										color='inherit'>
										<HelpOutlined />
									</IconButton>
								</Tooltip>
							</FormGroup>
						) : (
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									className={classes.dateInput}
									fullWidth
									// value={
									//   wishData.event ? moment(wishData.event) : moment().add(1, "days")
									// }
									value={moment(wishData.event)}
									format='DD-MM-YYYY'
									label={languages[lang].wishlistForm.eventCalendar}
									views={["day", "month", "year"]}
									onChange={(e) =>
										setWishData({
											...wishData,
											event: moment(e, "DD MM YYYY").toISOString(),
										})
									}
								/>
							</LocalizationProvider>
						)}
					</Grid>

					<Grid item xs={12} md={12} className='access-form'>
						<div className='access-grid -wishlist'>
							<div className='helper-grid'>
								<div className='helper-grid-text'>
									<Tooltip
										title={languages[lang].wishlistForm.tootlTips.accessTooltip}
										className='helper-btn'>
										<IconButton
											size='large'
											className='helper-btn'
											aria-label='account of current user'
											aria-controls='menu-appbar'
											aria-haspopup='true'
											color='inherit'>
											<HelpOutlined />
										</IconButton>
									</Tooltip>
									<Typography component='legend'>
										{languages[lang].wishlistForm.access}
									</Typography>
								</div>
								<div className='grid-b'>
									<StyledRating
										disabled={wishData?.selectedUsers?.length > 0}
										name='customized-color'
										defaultValue={wishData?.permission}
										getLabelText={(value) =>
											languages[lang].permissionCodes[value]
										}
										precision={1}
										icon={<LockPersonIcon fontSize='inherit' />}
										emptyIcon={<LockPersonIcon fontSize='inherit' />}
										onChange={(event, newValue) => {
											setWishData({ ...wishData, permission: newValue });
										}}
										onChangeActive={(event, newHover) => {
											setHover(newHover);
										}}
									/>
									<div className='choise'>
										<span className='choiseText'>or</span>
									</div>
									<FormGroup className='access-limit'>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														wishData?.selectedUsers?.length > 0 ? true : false
													}
												/>
											}
											label={languages[lang].wishlistForm.limitAccess}
											onChange={handleOpenFriends}
										/>
									</FormGroup>
								</div>
							</div>

							<div className='result-grid'>
								{wishData?.selectedUsers?.length > 0 && (
									<Box className='helper-grid__badge'>
										<div className={`wishlistBadge lvl6`}>
											<span className='wishlistBadge-text'>Limited</span>
											<span className='wishlistBadge-lvl'>
												<PermIdentityOutlined />
											</span>
										</div>
									</Box>
								)}
								{wishData?.permission !== null &&
									wishData?.selectedUsers?.length <= 0 && (
										<Box className='helper-grid__badge'>
											<div
												className={`wishlistBadge lvl${
													hover !== -1 ? hover : wishData?.permission
												}`}>
												<span className='wishlistBadge-text'>
													{
														languages[lang].permissionCodes[
															hover !== -1 ? hover : wishData?.permission
														]
													}
												</span>
												<span className='wishlistBadge-lvl'>
													{hover !== -1 ? hover : wishData?.permission}
												</span>
											</div>
										</Box>
									)}
							</div>
							{/* <div className='result-grid'> */}
						</div>
					</Grid>

					<Grid item xs={12} md={12}>
						<Button
							style={{ marginBottom: 10 }}
							className={classes.buttonSubmit}
							variant='contained'
							color='primary'
							size='large'
							type='submit'
							// fullWidth
							endIcon={<AutoFixHighOutlined />}>
							{currentListId
								? languages[lang].wishlistForm.save
								: languages[lang].wishlistForm.create}
						</Button>

						<Button
							style={{ marginLeft: 20 }}
							variant='standard'
							color='secondary'
							size='small'
							type='button'
							endIcon={<RestartAlt />}
							onClick={clear}
							//   fullWidth
							className={classes.clear}>
							{languages[lang].wishlistForm.clear}
						</Button>
					</Grid>
				</Grid>
			</form>

			<Modal
				open={openFriends}
				onClose={handleCloseFriends}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box className={classes.modal}>
					<Paper className={classes.paper}>
						<List dense sx={{ width: "100%", maxWidth: 360 }}>
							{friends?.map((value) => {
								const labelId = `checkbox-list-secondary-label-${value.user._id}`;
								return (
									<ListItem
										key={value.user._id}
										secondaryAction={
											<Checkbox
												edge='end'
												onChange={handleToggleUser(value.user._id)}
												checked={
													wishData?.selectedUsers
														? wishData?.selectedUsers?.indexOf(
																value.user._id
														  ) !== -1
														: false
												}
												inputProps={{ "aria-labelledby": labelId }}
											/>
										}
										disablePadding>
										<ListItemButton>
											<ListItemAvatar>
												<Avatar
													alt={`Avatar n°${value.user.name + 1}`}
													src={value.user.avatar}
												/>
											</ListItemAvatar>
											<ListItemText
												id={labelId}
												primary={`${value.user.firstName} ${value.user.lastName}`}
											/>
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
						<Button
							style={{ marginBottom: 10 }}
							className={classes.buttonSubmit}
							variant='contained'
							color='secondary'
							// size='small'
							type='submit'
							onClick={handleCloseFriends}>
							{languages[lang].wishlistForm.confirmModal}
						</Button>
					</Paper>
				</Box>
			</Modal>
			<Modal
				open={openWishes}
				onClose={handleCloseWishes}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box className={classes.modal}>
					<Paper className={classes.paper}>
						<List
							className='popup-list'
							dense
							sx={{
								width: "100%",
								maxWidth: 360,
								bgcolor: "background.paper",
								position: "relative",
								overflow: "auto",
								maxHeight: 300,
								"& ul": { padding: 0 },
							}}>
							<ListSubheader>
								{languages[lang].wishlistForm.selectWishes}{" "}
							</ListSubheader>
							{allWishes?.map((value) => {
								const labelId = `checkbox-list-secondary-label-${value._id}`;
								return (
									<ListItem
										className='popup-list-wish'
										key={value._id}
										secondaryAction={
											<Checkbox
												edge='end'
												onChange={handleToggleWish(value._id)}
												checked={
													wishData?.wishes
														? wishData?.wishes?.indexOf(value._id) !== -1
														: false
												}
												inputProps={{ "aria-labelledby": labelId }}
											/>
										}
										disablePadding>
										<ListItemButton>
											{/* <ListItemAvatar>
												<Avatar
													alt={`Avatar n°${value.name + 1}`}
													src={value.user.avatar}
												/>
											</ListItemAvatar> */}
											<ListItemIcon>
												<ListItemText
													className='access-list'
													primary={`${
														value.permission === 0 ? 1 : value.permission
													}`}
												/>
											</ListItemIcon>
											<ListItemText id={labelId} primary={`${value.title}`} />
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>

						<Button
							style={{ marginBlock: 10 }}
							className={classes.buttonSubmit}
							variant='contained'
							color='secondary'
							type='submit'
							onClick={handleCloseWishes}>
							{languages[lang].wishlistForm.confirmModal}
						</Button>
					</Paper>
				</Box>
			</Modal>
		</Paper>
	);
};
export default WishlistForm;
