import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";

import {
    Button,
    Stack,
    TextField,
    Typography,
    Paper,
    Box,
    Autocomplete,
    Chip,
} from "@mui/material";
import image3 from "../../images/Homepage/image5.jpg";
import { Face3, Lock, RedoOutlined, Face2, Key, Pets } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

const ErrorSignIn = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Paper className={classes.paper}>
            <div className="decor-card2"></div>
            <Box className={classes.paws}>
                {/* <Pets className={`${classes.paw01}  ${classes.paw00} `} />
                <Pets className={`${classes.paw02}  ${classes.paw00} `} />
                <Pets className={`${classes.paw03}  ${classes.paw00} `} />
                <Pets className={`${classes.paw04}  ${classes.paw00} `} /> */}
            </Box>

            <Typography variant="h4" align="center">
                Sorry,  we can't recognize you!
                    </Typography>

            <Box className={classes.signIn}>
                <Typography variant="p" align="center">
                    Please, sign in
                  </Typography>
                <RedoOutlined />
            </Box>
            <Button
                endIcon={<Key />}
                variant='contained'
                className={classes.wishboard}
                onClick={() => {
                    navigate("/auth");
                }}>
                Unlock my account
				</Button>
        </Paper>
    );
};
export default ErrorSignIn;
