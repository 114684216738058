import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Paper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { confirmEmail, sendMessage } from "../../actions/user";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import EmailSuccess from "../../components/ErrorScreens/EmailSuccess/EmailSuccess";
import { AutoFixHighOutlined, RestartAlt } from "@mui/icons-material";
import constants from "../../constants/constants";
import Toast from "../../components/toast/Toast";
import letter from "../../images/utils/letter.jpeg";
// import PageTitle from "../../components/PageTitle/PageTitle";
// Notifications
const toastConf = {
  open: false,
  severity: "error",
  message: "Error",
};

const Contact = () => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const dispatch = useDispatch();
  const [toast, setToast] = useState(toastConf);

  const classes = useStyles();
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message) return;
    if (!user) return;
    if (!message.length < 25) {
      shortMessage();
      return;
    }

    dispatch(sendMessage({ message })).then(clear());
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };
  const shortMessage = () => {
    setToast({
      open: true,
      severity: "error",
      message: "Message should be at least 25 characters",
    });
  };
  const clear = () => {
    setMessage("");
    setToast({
      open: true,
      severity: "success",
      message: "Your request has been sent",
    });
  };
  return (
    <>
      <Navbar />
      <Container className="body-wrapper --align-center">
        {/* <Paper className={classes.paper}> */}
        <div className='decor-card --alt'></div>
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          spacing={3}
          className={classes.mainContainer}
        >
          <Grid item xs={12} md={6} className={classes.success}>

            <Typography variant="h1"> Contact us</Typography>
            {/* </Grid> */}
            {user && (
              // <Grid>
              <form
                autoComplete="off"
                className={`${classes.form} ${classes.root}`}
                noValidate
                onSubmit={handleSubmit}
              >
                <Box className={classes.formHeader}>
                  {/* <Typography variant="h4">Send us a message</Typography> */}
                </Box>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={{ xs: 3 }}
                >
                  <Grid item xs={12} md={12}>
                    <Typography variant="p" className={classes.fullW}>
                      Please explain your problem or suggestion.
                    </Typography>

                    <TextareaAutosize
                      className={classes.textarea}
                      aria-label="message"
                      minLength={10}
                      minRows={6}
                      maxRows={10}
                      style={{
                        marginTop: "16px",
                        width: "100%",
                        resize: "vertical",
                        overflow: "auto",
                      }}
                      name="message"
                      variant="filled"
                      label="Your message"
                      value={message}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button
                      style={{ marginBottom: 10 }}
                      className={classes.buttonSubmit}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      // fullWidth
                      endIcon={<AutoFixHighOutlined />}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
              // </Grid>
            )}

          </Grid>

          <Grid item xs={12} md={6}>
            <div className="contact-image avatar">
              <div className="contact-image-bg">


                <img
                  className={classes.media}
                  className={`${classes.media} contact-image-image `}
                  src={letter}
                  alt="Contact us"
                />
              </div>
            </div>

          </Grid>
        </Grid>
        {/* </Paper> */}
        <Toast
          open={toast.open}
          message={toast.message}
          severity={toast.severity}
          onClose={handleClose}
        />
      </Container>
      <Footer />
    </>
  );
};
export default Contact;
