import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";

import {
    Button,
    Stack,
    TextField,
    Typography,
    Paper,
    Box,
    Autocomplete,
    Chip,
} from "@mui/material";
import image3 from "../../../images/Homepage/image5.jpg";
import { Face3, Lock, RedoOutlined, Face2, Key, Pets } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

const EmailSuccess = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Paper className={classes.paper}>
            <div className="decor-card2"></div>
            <Typography variant="h4" className={classes.paperTitle} align="center">
                Congradulations! Your email was confirmed
                    </Typography>
            <Box className={classes.signIn}>
                <Typography variant="p" align="center">
                    You will be redirected shortly. In case of delay, please, click here
                    <RedoOutlined />
                </Typography>

            </Box>
            <Button
                endIcon={<Key />}
                variant='contained'
                className={classes.wishboard}
                onClick={() => {
                    navigate("/auth");
                }}>
                Start wishing now
				</Button>
        </Paper>
    );
};
export default EmailSuccess;
