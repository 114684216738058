import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logoDot: {
    color: "#00A597",
  },
  logo: {
    cursor: "pointer",
  },
}));
