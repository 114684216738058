import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "500px",
  },
  friendData: {
    marginBlock: theme.spacing(0, 4),
  },
  friendInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0),

    }
  },
  cardWrapper: {
    padding: theme.spacing(4, 2),
    overflow: "hidden",
    position: "relative",
    isolation: "isolate",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 2),

    }
  },
  card: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(2, 2),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: theme.spacing(0),
      flexDirection: "column",
    },
  },
  section: {
    borderRadius: "20px",
    margin: "10px",
    flex: 1,
  },
  imageSection: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },
  commentsOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  innerWrapper: {
    minHeight: "calc(100vh - 200px)",
    marginBlock: theme.spacing(6),
  },

  banner: {
    position: "relative",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    isolation: "isolate",
    color: theme.palette.neutral.light,
    textAlign: "center",
    // width: "100%",
    // marginBottom: "5vh!important",
    padding: theme.spacing(6, 4),

  },
}));
