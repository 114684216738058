import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';

import {
  Card,
  cardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardActions,
  Box,
  ButtonBase,
  Rating,
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  ThumbUpAlt,
  Delete,
  MoreHoriz,
  DeleteOutlined,
  FavoriteBorder,
  LinkOutlined,
} from "@mui/icons-material";
import CakeIcon from "@mui/icons-material/Cake";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { AlarmAdd, RestartAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import constants from "../../../constants/constants";
import { unMarkComplete, deleteWish } from "../../../actions/wishes";

import gift from "../../../images/utils/gift.jpeg";
import { ScheduleOutlined, ModeEditOutlined } from "@mui/icons-material";
import { languages } from "../../translations";

import { styled } from "@mui/material/styles";
const ArchiveWish = ({ wishData, setCurrentId, page, edit }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));

  const [showDelete, setShowDelete] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openWish = () => {
    navigate(`/wish/${wishData._id}`);
  };
  const lang = useSelector((state) => state.auth.lang);
	moment.locale(lang === "ua" ? "uk" : lang);
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#F1CE89",
    },
    "& .MuiRating-iconHover": {
      color: "#F1CE89",
    },
  });
  const toggleDeleteModal = () => {
    setShowDelete((prevShowDelete) => !prevShowDelete);
  };

  return (
    <Card className={classes.card}>
      <ButtonBase className={classes.cardAction}>
        <CardMedia
          component="img"
          className={classes.media}
          image={wishData?.selectedFile || gift}
          // src={userData.selectedFile}
          title={wishData.title}
        />
        <div className="overlay-cardMedia"></div>
      </ButtonBase>

      <div className={classes.overlay}>
        <div className={`wishlistBadge lvl${wishData?.permission || 1}`}>
          <span className="wishlistBadge-text">
            {languages[lang].permissionCodes[wishData?.permission || 1]}
          </span>
          <span className="wishlistBadge-lvl">{wishData?.permission || 1}</span>
        </div>
      </div>
      <div className={classes.overlay2}>
        <div className="rating-wrap wishline-rating">
          <StyledRating
            name="customized-color"
            defaultValue={wishData?.dreamScale || 1}
            // getLabelText={(value) => customIcons[value]}
            readOnly
            precision={1}
            icon={<CakeIcon fontSize="inherit" />}
            emptyIcon={<CakeIcon fontSize="inherit" />}
          />
        </div>
      </div>

      <div className={classes.details}>
        <Typography variant="body2" color="light">
          {wishData.tags?.map((tag) => (tag.length ? `#${tag}` : ""))}
        </Typography>
      </div>
      <div className="date-wrapper">
        <Box className={classes.iconWidget}>
          <span className="icon-grid -no-shadow --center">
            <ScheduleOutlined />
            <Typography variant="body2" className={` alttext2`}>

              {moment(wishData.updatadeAt).fromNow()}
            </Typography>
          </span>
        </Box>
      </div>

      <div className={classes.cardTitle}>
        <Typography
          className={classes.title}
          // gutterBottom
          variant="h6"
          component="h6"
        >
          {wishData.title}
        </Typography>

        {wishData?.link && (
          <div className="row-wrapper">
            {/* <Tooltip title="Openl wish external link"> */}
            <a
              className="link -rounded"
              target="_blank"
              href={wishData?.link}
              rel="nofollow"
            >
              <LinkOutlined />
            </a>
            {/* </Tooltip> */}
          </div>
        )}
      </div>
      <Typography
        className={classes.title}
        // gutterBottom
        variant="body2"
        component="p"
      >
        {wishData.event && <> Event {moment(wishData?.event).fromNow()}</>}
      </Typography>

      <CardContent className={classes.cardBody}>
        <Typography
          className={classes.desc}
          // variant="body2"
          // component="p"
          sx={{ p: 0 }}
        >
          {wishData.description}
        </Typography>
        <Typography
          className={classes.title}
          // variant="body2"
          color="primary.main"
          // component="p"
          sx={{ p: 0 }}
        ></Typography>
      </CardContent>

      <CardActions className={classes.cardActions}>
        {user?._id === wishData?.creator && (
          <>
            <Button
              style={{ color: "white" }}
              size="small"
              endIcon={<RestoreIcon />}
              onClick={() => {
                dispatch(unMarkComplete({ wishid: wishData._id }));
              }}
            >
              {languages[lang].archive.buttons.wishagain}
            </Button>
            <Button
              size="small"
              color="primary"
              endIcon={<DeleteOutlined />}
              // onClick={() => {
              //   // dispatch(deleteWish({ wishid: wishData._id, page: page }));

              // }}
              onClick={toggleDeleteModal}
            >
              {languages[lang].archive.buttons.delete}
            </Button>
          </>
        )}
      </CardActions>
      <Dialog open={showDelete}>
        <DialogTitle>Delete wish</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.desc}>
            You are going to delete your wish. We must warn you that you if you
            proceed you can't revert this proccess and you will lose access to
            it permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button onClick={toggleDeleteModal}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(deleteWish({ wishid: wishData._id, page: page }));
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
export default ArchiveWish;
