const fr = {
  home: {
    hero: {
      a1: "Vos rêves peuvent devenir réalité",
      a2: "Fais un",
      a3: "Vœu",
      a4: "Aidez vos personnes préférées à trouver un cadeau parfait pour vous !",

      make: "Fais un vœu",
      create: "Créez une liste de souhaits",
      choose: "Choisissez un cadeau",
    },
    howReceive: "Comment recevoir des cadeaux?",
    three: "Trois ",
    simple: "simple ",
    steps: " étapes",
    s1: {
      title: "Étape 1",
      create: "Créez un compte",
      account: "compte",
      instr:
        " Créez votre propre espace de vœux, où vous pouvez ajouter autant de vœux que vous le souhaitez.",
      button: "Créez une liste de souhaits",
    },
    s2: {
      title: "Étape 2",
      create: "Créez une",
      wishlist: "liste de souhaits",
      instr:
        "Ajoutez une photo, une description ou un lien vers un produit. Vous pouvez même évaluer vos vœux avec le Wishemeter pour souligner ce que vous voulez le plus.",
      button: "Fais un vœu maintenant !",
    },
    s3: {
      title: "Étape 3",
      share: "Partagez votre",
      account: "compte",
      instr:
        "Aidez vos amis à trouver le meilleur cadeau pour vous. Invitez vos personnes préférées à vous suivre sur Wish. Partagez votre profil sur vos réseaux sociaux préférés ou par e-mail.",
      button: "Invitez des amis!",
    },
    createAccount: "Créer un compte",
  },
  auth: {
    login: "Connexion",
    register: "S'inscrire",
    or: "ou",

    signUp: "S'inscrire",
    signIn: "Se connecter",
    lastName: "Nom de famille",
    firstName: "Prénom",
    email: "Adresse e-mail",
    password: "Mot de passe",
    repeatpassword: "Répéter le mot de passe",
    lostpwd: "Mot de passe oublié",
    reset: "Réinitialiser",
    cancel: "Annuler",
    checkEmail: "Vérifiez votre e-mail",
    resetpwd: "Réinitialiser le mot de passe",
    resetinstr:
      "Veuillez saisir l'adresse e-mail avec laquelle vous vous êtes inscrit, et nous vous enverrons des instructions par e-mail",
    errors: {
      uppercase:
        "Lettre majuscule manquante ! Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule et une lettre minuscule, un caractère spécial et un chiffre",
      lowercase:
        "Au moins une lettre minuscule ! Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule et une lettre minuscule, un caractère spécial et un chiffre",
      digit:
        "Au moins un chiffre ! Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule et une lettre minuscule, un caractère spécial et un chiffre",
      special:
        "Caractères spéciaux manquants ! Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule et une lettre minuscule, un caractère spécial et un chiffre",
      shortpwd:
        "Trop court ! Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule et une lettre minuscule, un caractère spécial et un chiffre",
      pwdnotmatch: "Les mots de passe ne correspondent pas",
    },
  },
  archive: {
    title: "Archive",
    subtitle: "Vœux que vous avez reçus",
    buttons: {
      wishagain: "Souhaiter à nouveau",
      delete: "Supprimer",
    },
  },

  navbar: {
    signin: "Se connecter",
    logout: "Déconnexion",
    faq: "FAQ",
    wishboard: "Tableau des vœux",
    login: "Connexion",
    archive: "Archive",
    register: "S'inscrire",
    account: "Détails du compte",
    settings: "Ouvrir les paramètres",
  },
  search: {
    placeholder: "Rechercher un ami",
    button: "Rechercher",
    clear: " Effacer",
    title: "Résultats de la recherche ",
  },
  searchUsers: {
    pending: "Demande en attente",
    invite: "Inviter",
    friends: "Est ami",
    viewProfile: "Voir le profil",
    returnToSearch: "Retour à la recherche",
  },
  wishboard: {
    tabs: {
      wishes: "Mes vœux",
      wishlists: "Liste de souhaits",
      bookedwish: "Vœux réservés",
      friends: "Amis ",
    },
    buttons: {
      add: {
        wish: "Faire un vœu",
        wishlist: "Ajouter une liste ",
      },
    },
    titles: {
      friends: "Inviter plus d'amis",
    },
  },

  wishcard: {
    buttons: {
      share: "Partager",
      more: "Plus",
      delete: "Supprimer",
      received: "Marquer comme reçu",
      edit: "Modifier",
    },
    tooltip: {
      book: "En réservant un vœu, vous confirmez que vous prévoyez de l'offrir.",
    },
    book: "Réserver",
    login: "Connectez-vous pour voir le statut",
    youBooked: "Vous avez réservé",
    notYouBooked: "Quelqu'un a réservé",
  },
  wishDetails: {
    tooltip: {
      book: "En réservant un vœu, vous confirmez que vous prévoyez de l'offrir.",
    },
    book: "Réserver",
    login: "Connectez-vous pour voir le statut",
    youBooked: "Vous avez réservé",
    notYouBooked: "Quelqu'un a réservé",
    return: "Retour à tous les vœux",
    wishmeter: "l'ampleur du rêve",
    details: "Détails du vœu",
    openLink: "Ouvrir",
    link: "Lien",
    createdBy: "Créé par",
  },
  wishlist: {
    details: {
      back: "Retour au tableau des vœux",
      wishes: "Vœux",
      special: "Limité",
      created: "Créé par",
      updated: "Dernière mise à jour",
      desc: "Détails de la liste de souhaits",
      event: "Événement",
      delete: "Supprimer",
      received: "Marquer comme reçu",
      link: "Lien",
    },
    tooltip: {
      book: "En réservant un vœu, vous confirmez que vous prévoyez de l'offrir.",
    },
    book: "Réserver",
    login: "Connectez-vous pour voir le statut",
    youBooked: "Vous avez réservé",
    notYouBooked: "Quelqu'un a réservé",
  },
  wishlistForm: {
    title: {
      name: "Titre de votre liste de souhaits",
      creating: "Création",
      editing: "Modification",
      wishlist: "une liste de souhaits",
    },
    tootlTips: {
      eventTooltip:
        "Vous pouvez ajouter une date pour spécifier quand vous souhaitez recevoir les cadeaux",
      accessTooltip:
        "Vous pouvez sélectionner qui pourra accéder à votre liste de souhaits. Pour limiter l'accès, sélectionnez un niveau de relation ou montrez-le uniquement à quelques personnes",
    },
    selectWishes: "Sélectionner des vœux",
    coverImage: "Sélectionner une image de couverture",
    upload: "Télécharger",
    description: "Veuillez ajouter une description",
    shortDescription: "description",
    addWishes: "Ajouter des vœux",
    eventDate: "Avez-vous une date d'événement?",
    eventCalendar: "Quand voulez-vous recevoir un cadeau",
    access: "Sélectionnez qui peut accéder à la liste de souhaits",
    limitAccess: "Limite aux utilisateurs spécifiques?",
    save: "Enregistrer la liste de souhaits",
    create: "Créer une liste de souhaits",
    confirmModal: "Confirmer",
    clear: "effacer",
  },
  wishlistcard: {
    buttons: {
      share: "Partager",
      more: "Plus",
      delete: "Supprimer",
      received: "Marquer comme reçu",
      edit: "Modifier",
    },
    elements: {
      wishes: "Vœux",
      special: "Limité",
    },
  },
  customIcons: [
    "À quel point tu le veux?",
    "Peut attendre",
    "J'adorerais l'avoir",
    "Prochaine vacance ",
    "Dès que possible",
    "Je ne le croirai pas tant que je ne l'aurai pas vu",
  ],
  permissionCodes: [
    "Qui peut y accéder?",
    "Public",
    "Amis",
    "Famille",
    "Partenaire",
    "TOP SECRET!",
  ],
  wishForm: {
    title: {
      name: "Titre de votre vœu",
      creating: "Création",
      editing: "Modification",
      wish: "un vœu",
    },
    tootlTips: {
      eventTooltip:
        "Vous pouvez ajouter une date pour spécifier quand vous souhaitez recevoir les cadeaux",
      accessTooltip:
        "Vous pouvez sélectionner qui pourra accéder à votre vœu. Pour limiter l'accès, sélectionnez un niveau de relation ou montrez-le uniquement à quelques personnes",
    },
    coverImage: "Avez-vous une idée de son apparence?",
    upload: "Télécharger",
    description: "Veuillez ajouter une description",
    shortDescription: "description",
    addToWishlists:
      "Souhaitez-vous l'ajouter à des listes de souhaits existantes?",
    selectWishlist: "Sélectionner des listes de souhaits",
    dreamscale: "Sélectionner la priorité",
    access: "Sélectionnez qui peut y accéder",
    link: "Lien vers l'article",
    save: "Enregistrer le vœu",
    create: "Créer un vœu",
    confirmModal: "Confirmer",
    clear: "effacer",
    select: "Sélectionner",
  },
  account: {
    title: "À propos de toi",
    account: "Votre compte",
    resetPass: "Réinitialiser le mot de passe",
    resetMessage:
      "Veuillez entrer l'adresse e-mail avec laquelle vous vous êtes inscrit, et nous vous enverrons des instructions par e-mail",
    logout: "Déconnexion",
    enterEmail: "Entrer l'email",
    countdown: {
      birthday: "Votre anniversaire ",
      dans: "dans",
      jours: "jours",
      heures: "heures",
      min: "min",
      sec: "sec",
    },
    form: {
      first: "Prénom",
      last: "Nom",
      phone: "Numéro de téléphone",
      email: "Email",
      country: "Pays",
      city: "Ville",
      postal: "Code postal",
      bio: "À propos de moi",
      adress: "Adresse",
      birthday: "Votre anniversaire",
      reset: "Réinitialiser",
      upload: "Télécharger",
      save: "Enregistrer",
    },
  },
  user: {
    birthday: "Anniversaire ",
    dans: "dans",
    share: "Partager le profil",
    invite: "Inviter",
    pending: "En attente",
    login: "Veuillez vous connecter pour ajouter un ami",
    wishlistsTitle: "Listes de souhaits",
    wishTitle: "Vœux",
    event: "Événement",
    yourFriend: "Votre ami",
    ownPage: "Voici comment votre page est présentée aux autres.",
  },
  errors: {
    noresults: {
      bookedWishes: {
        title: "Aucun vœu réservé pour le moment",
        desc: "Réservez votre premier vœu maintenant !",
      },
      wishes: {
        title: "Aucun vœu pour le moment",
        desc: "Créez votre premier vœu maintenant !",
      },
      wishlists: {
        title: "Aucun vœu pour le moment",
        desc: "Créez votre premier vœu maintenant !",
      },
    },
  },

  friend: {
    buttons: {
      view: "Voir",
      more: "Plus",
      permissions: " Changer les autorisations",
      delete: "Supprimer",
    },
  },
  holidays: {
    christmas: "Noël",
    easter: "Pâques",
    tech: {
      dans: "dans",
    },
  },
  share: {
    shareWish: {
      title: "votre Vœu",
    },
  },
  dialog: {
    delete: {
      wish: {
        title: "Supprimer le vœu",
        text: "Vous vous apprêtez à supprimer votre vœu. Nous devons vous avertir que si vous continuez, vous ne pourrez pas revenir en arrière, et toutes les personnes susceptibles de s'abonner à votre vœu perdront définitivement l'accès.",
        cancel: "Annuler",
        delete: "Supprimer",
      },
      wishlist: {
        title: "Supprimer la liste de souhaits",
        text: "Vous vous apprêtez à supprimer votre liste de souhaits. Nous devons vous avertir que si vous continuez, vous ne pourrez pas revenir en arrière.",
        cancel: "Annuler",
        delete: "Supprimer",
      },
      friend: {
        title: "Retirer un ami",
        text: "Vous vous apprêtez à retirer un ami de votre liste. Êtes-vous sûr de vouloir continuer?",
        cancel: "Annuler",
        delete: "Supprimer l'ami",
      },
    },
    change: {
      permissions: {
        title: "Changer le niveau d'autorisation des amis",
        text: "Vous êtes sur le point de changer les autorisations",
        label: "Sélectionner la priorité",
        cancel: "Annuler",
        confirm: "Confirmer",
      },
    },
    share: {
      wish: {
        title: "Partager votre Vœu",
        close: "Fermer",
      },
      widget: {
        wish: {
          copy: "Copier le lien",
        },
      },
      messages: {
        link: "Le lien https://wish-u.app/",
        linkSuccess: "est copié dans votre presse-papiers",
      },
    },
  },
  faq: {
    title: "Foire aux questions",
    subtitle:
      "Nous sommes là pour vous aider à trouver les réponses aux questions que nous entendons le plus souvent. ",
    contact: "    Si vous ne trouvez pas les réponses à votre question",
    contactBtn: "Contactez-nous",
  },
  message: {
    title: "Désolé, nous ne trouvons pas la personne que vous recherchez !",
    desc: "S'il vous plaît, essayez de modifier la demande. Ou envoyez ce lien à quelqu'un que vous aimeriez rencontrer dans Wish",
    friends: {
      title: "Pas encore d'amis",
      desc: "Si vos amis n'ont pas encore rejoint Wish, s'il vous plaît, vous pouvez les inviter via un lien",
      button: "copie",
    },
  },
};
export default fr;
