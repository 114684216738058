import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  media: {
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
    width: "100%",
    objectFit: "cover",
    border: "4px solid #f1ce89",
    borderRadius: 10,
    objectPosition: "center",
  },
  border: {
    border: "solid",
  },
  fullHeightCard: {
    height: "100%",
  },
  friendsName: {
    paddingLeft: theme.spacing(0.5),
  },
  friendsDesc: { paddingLeft: theme.spacing(0.5), },

  btnRow: {
    marginTop: theme.spacing(1),
    width: "100%",

  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    position: "relative",

  },
  overlay: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      left: theme.spacing(1),
      display: "flex",
    },
  },
  overlay2: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      display: "flex",
    },
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 2, 0),
    color: "#fff!important",
  },
  title: {
    padding: theme.spacing(0, 2),
    color: "#fff!important",
  },
  cardActions: {
    // padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "flex",
    textAlign: "initial",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  friendCard: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    gridTemplateRows: "80px",
    flexDirection: "row",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.neutral.main,
    alignItems: "flex-start",

  }
}));
