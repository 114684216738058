import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Grid, Paper, TextField, Button, Box } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { useDispatch } from "react-redux";

import { searchUsers } from "../../actions/user";
import useStyles from "./styles";
import { languages } from "../translations";
import SearchGrid from "./SearchGrid/SearchGrid";
import constants from "../../constants/constants";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FriendsForm = () => {
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const [currentId, setCurrentId] = useState(null);
  const { error } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");

  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchFriend();
    }
  };
  const clear = (e) => {
    setSearch("");
    navigate("/friends");
  };

  const searchFriend = () => {
    if (search.trim()) {
      dispatch(
        searchUsers({
          search: search.trim(),
        })
      );

      navigate(`/user/search?searchQuery=${search || "none"}`);
    } else {
      navigate("/");
    }
  };

  return (
    <>
   
          {searchQuery ? <SearchGrid setCurrentId={setCurrentId} /> : <></>}
          <Paper elevation={6} className={classes.formWrapper}>
          {/* <div className="decor-card2 "></div> */}
            <Box className={classes.AppBarSearch}>
              <Grid container>
                <Grid item xs={12} sm={8} md={12}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    name="search"
                    variant="standard"
                    label={`${languages[lang].search.placeholder}`}
                    fullWidth
                    value={search}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={8}>
                  <Button
                    onClick={searchFriend}
                    className={classes.searchButton}
                    variant="contained"
                    // color="gradient"
                    endIcon={<SearchOutlinedIcon />}
                  >
                    {" "}
                    {languages[lang].search.button}{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    onClick={clear}
                    style={{ marginTop: "10px" }}
                    variant="text"
                    endIcon={<RestartAlt />}
                  >
                    {languages[lang].search.clear}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
    
    </>
  );
};
export default FriendsForm;
