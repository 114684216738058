import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
    height: theme.layout.sizes.logoHeight,
    paddingTop: theme.spacing(2),
  },
  altBtn: {
    paddingTop: theme.spacing(2),
  },
  googlebtn: {
    marginTop: theme.spacing(2),
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: 10,
  },
  googleButton: {
    marginBottom: theme.spacing(2),
  },
  main: {
    marginTop: "3rem",
  },
  logoDot: {
    color: theme.palette.secondary.color02,
  },
  logo: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontWeight: "bold!important",
  },
}));
