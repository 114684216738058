import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export let theme = createTheme({
  components: {
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          borderWidth: 1,
          border: "none",
        },

        "&  MuiPickersToolbar": {
          toolbar: {
            backgroundColor: "red",
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          borderWidth: 0,
          borderColor: "#fffff2",
          backgroundColor: "#061f25",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "text" && {
            border: 0,
            color: "white",
            "&:hover": {
              color: "#f1ce89",
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "light" && {
            "&:hover": {
              color: "#f1ce89",
            },
          }),
          ...(ownerState.variant === "contained" && {
            border: 0,
            color: "white",
            borderRadius: "90px",
            // fontSize: 18,
            fontWeight: "600",
            letterSpacing: "-0.2px",
            textTransform: "none",
            boxShadow: "none",
            transition: "all .3s ease",
            "@media (max-width:1024px)": {
              fontSize: 14,
              padding: "8px 20px",
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.size === "medium" && {
            padding: "10px 26px",
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.size === "large" && {
            padding: "15px 34px",
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "gradient" && {
            color: "#fff",
            // background: 'linear-gradient(155deg, #058686 10%, #034940 50%,  #058686 98%)',
            background:
              "linear-gradient(97deg, #058686 0%, #175565 35%, #058686 98%)",
            background: "  linear-gradient(102.03deg, #043C3C 4.19%, #089191 52%,   #043C3C 100%)",
            backgroundPosition: "1% 50%",
            backgroundSize: "200% 200%",
            border: "thin solid #F1CE89",

            "&:hover": {
              backgroundPosition: "100% 100%",
            },
            //
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "gradientSec" && {
            color: "#fff",
            background:
              "linear-gradient(97deg, rgba(163, 105, 175, 0) 10%, #a369af 35%,  rgba(163, 105, 175, 0) 75%)",

            /* Rectangle 1 */

            backgroundPosition: "1% 50%",
            backgroundSize: "300% 300%",

            "&:hover": {
              backgroundPosition: "100% 100%",
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.size === "small" && {
            padding: "4px 10px",
            fontSize: "0.8125rem",
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
            color: "#061f25",
            backgroundImage:
              "linear-gradient(97deg, #F1CE89 0%, #F1CE89 35%, #fff4e2 78%, #F1CE89 98%)",
            backgroundPosition: "1% 50%",
            backgroundSize: "200% 200%",
            border: "thin solid #F1CE89",

            "&:hover": {
              backgroundPosition: "100% 100%",
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
            color: "#061f25",
            background:
              "linear-gradient(97deg, #fff4e2 0%, #fff4e2 35%, #fff 58%, #fff4e2 98%)",
            backgroundPosition: "1% 50%",
            backgroundSize: "200% 200%",
            border: "thin solid #F1CE89",

            "&:hover": {
              backgroundPosition: "100% 100%",
            },
          }),


          "& endIcon": {
            color: "RED",
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1f353b",
          color: "#ffffff",
          backgroundImage: "none",
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingInline: 0,
        },
      },
    },
    root: {
      "& .MuiTextField-root": {
        backgroundColor: "#1f353b",
      }
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(20,0,0)",

          "&:hover": {
            backgroundColor: "rgb(250, 232, 241)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: "rgb(232, 241, 250)"
            }
          },
          "&.Mui-focused": {
            backgroundColor: "rgb(250, 241, 232)"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& MuiIconButton": {
            color: "white",
          },
          "& label": {
            color: "white",
          },
          "&:hover label": {
            // fontWeight: 700,
          },
          "& label.Mui-focused": {
            color: "white",
          },
          "& label.Mui-disabled": {
            color: "white",
          },
          "& textarea": {
            backgroundColor: "#1f353b!important",
          },

          "& .MuiInputBase-root.Mui-disabled": {
            WebkitTextFillColor: "#b4bcbe",
            color: "white",
            borderWidth: 2,
          },
          "& .MuiInputBase-root.Mui-disabled": {
            "& > fieldset": {
              borderWidth: 2,
              borderColor: "#b4bcbe",
            },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#b4bcbe",
            color: "white",
            borderWidth: 2,
          },
          "& .MuiInputBase-root": {
            color: "white",
            paddingBottom: 10,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "white",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: 90,
            "& fieldset": {
              color: "white",
              borderColor: "white",
            },
            "&:hover fieldset": {
              color: "white",
              borderColor: "white",
              borderWidth: 2,
            },
            "&.Mui-focused fieldset": {
              color: "white",
              borderColor: "white",
            },
          },
          "& .Mui-focused.MuiAutocomplete-input": {
            color: "blue",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "white",
          "&.MuiOutlinedInput-root": {
            borderRadius: 90,
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "#F3D7A0",
            },
            "&.Mui-focused fieldset": {
              // borderColor: "#A0D0D0",
              borderColor: "#F3D7A0",
            },
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
            color: "#ffffff",
          },
          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
            color: "violet",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  status: {
    danger: "#e53e3e",
  },
  typography: {
    fontFamily: ["Noto Sans", "Noto Serif"].join(","),
    fonts: {
      fontPrimary: ["Noto Sans"].join(","),
      fontSecondary: ["Noto Serif"].join(","),
    },

    sizes: {
      xl: "clamp(100px, 6vw, 14rem)!important",
      h1: "clamp(2.125rem, 1.5789rem + 2.3301vw, 4.375rem)!important",
      h2: "clamp(2rem, 1.5752rem + 1.8123vw, 3.75rem)!important",
      h3: "clamp(28px, 2.08vw, 4rem)!important",
      h4: "clamp(24px, 1.6vw, 3rem)!important",
      h6: "clamp(16px, 1.01vw, 2rem)!important",
      body: "clamp(18px, 1.43vw, 2.2rem)!important",
      md: "clamp(16px, 1.01vw, 2rem)!important",
      sm: "clamp(14px, 0.93vw, 1.8rem)!important",
      xs: "clamp(14px, 0.83vw, 1.6rem)!important",
    },
    weight: {
      black: "900!important",
      bold: "700!important",
      semi: "600!important",
      med: "500!important",
      reg: "400!important",
    },
    transform: {
      none: "none!important",
      uppercase: "uppercase!important",
    },
    h1: {
      fontFamily: ["Noto Serif"].join(","),
      fontWeight: "600",
      fontSize: "2.75rem",
    },
    h2: {
      fontFamily: ["Noto Serif"].join(","),
      fontWeight: "600",
    },
    h3: {
      fontFamily: ["Noto Serif"].join(","),
      fontWeight: "600",
    },
    h4: {
      fontFamily: ["Noto Serif"].join(","),
      fontWeight: "600",
    },
    h5: {
      fontFamily: ["Noto Serif"].join(","),
    },
    h6: {
      fontSize: "1.05rem",
      fontFamily: ["Noto Serif"].join(","),
    },
    body: {
      fontSize: 20,
      fontFamily: ["Noto Sans"].join(","),
    },
    p: {
      fontFamily: ["Noto Sans"].join(","),
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#F1CE89!important",
      color02: "#998AB5",

      color03: "#B3D9D9",
    },
    secondary: {
      main: "#fff4e2!important",
      color01: "#f1ce89",
      color02: "#FEFEDF!important",

    },
    gradient: {
      main: "#058686",
      color03: "#B3D9D9!important",
    },
    light: {
      main: "#ffffff!important",
    },
    neutral: {
      main: "#061f25!important",
      color02: "#8EA4A3!important",
      color03: "#B3D9D9!important",
      color04: "#8EA4A3!important",
      light: "#ffffff!important",
      dark: "#000000!important",
      text: "#B3D9D9!important",
    },

    // primary: {
    //   main: '#00796b',
    //   contrastText: '#ffffff',
    // },
    // secondary: {
    //   main: '#f1ce89',
    // },
    background: {
      default: '#071f25',
      paper: '#1F353B',
    },
    success: {
      main: '#009688',
    },
    info: {
      main: '#a0d0d0',
    },
    warning: {
      main: '#fa9137',
    },
    error: {
      main: '#f44336',
    },
  },
  layout: {
    sizes: {
      bannerFull: "100vh",
      bannerLarge: "60vh",
      logoHeight: "clamp(40px, 6.5vh, 64px)",

    },
    gap: {
      block: {
        xl: "10vmin",
      },
      inline: {
        md: "30px",
      },
    },
  },
  buttons: {
    borderRadius: {
      def: "90px!important",
    },
  },
  button: {
    // fontSize: 20,
  },
});




theme = responsiveFontSizes(theme);
