
import * as React from "react";


export const LoaderSvg = () => {
    return (
        <>
            {/* <svg className="loading-svg" width="700" height="300" viewBox="0 0 700 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M307.547 165.96C309.875 165.029 337.792 168.697 345.093 170.162C354.209 171.992 355.625 232.746 350.969 232.746C346.312 232.746 347.421 171.094 351.767 171.094C356.113 171.094 414.024 166.821 416.042 166.821C418.059 166.821 418.204 212.616 415.911 224.165C414.246 232.548 352.622 237.029 351.527 237.005C348.736 236.943 312.543 226.001 309.127 225.718C306.389 225.49 348.669 240.936 352.829 240.961C356.923 240.987 393.974 237.129 410.831 230.787C415.261 229.12 419.953 228.259 424.686 228.259H689.162" stroke="#DBC087" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M363.735 131.588C363.735 131.588 375.297 113.482 386.086 112.187C389.966 111.722 400.52 134.693 395.243 135.314C381.215 136.964 374.391 139.257 371.665 141.071C352.548 153.796 338.402 134.352 324.372 141.97C303.702 153.191 306.924 152.588 314.374 151.967C318.803 151.598 314.533 153.318 312.981 159.216C311.429 165.114 312.993 162.817 319.345 154.56C327.105 144.471 350.077 140.435 349.921 132.83C349.766 125.225 334.866 109.393 333.159 109.393C331.451 109.393 319.034 118.396 318.724 125.38C318.414 132.365 359.855 145.247 365.287 140.125C370.72 135.003 356.78 132.531 353.365 133.773C349.95 135.015 348.24 163.143 350.093 165.636C352.032 168.247 411.789 162.972 417.144 162.377C419.938 162.067 419.324 147.755 416.817 146.799C410.855 144.526 356.285 149.283 349.3 149.283C342.316 149.283 307.297 146.611 305.841 148.817C301.77 154.987 303.805 203.386 305.824 219.855C306.176 222.732 303.519 225.119 300.62 225.096L10.8398 223.008" stroke="#DBC087" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg> */}
            <svg width="1920" className="loading-svg" height="133" viewBox="0 0 1920 133" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M887.047 56.5672C889.375 55.6359 917.292 59.3037 924.593 60.7692C933.709 62.5991 935.125 123.353 930.469 123.353C925.812 123.353 926.921 61.7005 931.267 61.7005C935.613 61.7005 993.524 57.4279 995.542 57.4279C997.559 57.4279 997.704 103.222 995.411 114.772C993.746 123.154 932.122 127.636 931.027 127.612C928.236 127.55 892.043 116.608 888.627 116.324C885.889 116.097 928.169 131.542 932.329 131.568C936.423 131.593 973.474 127.736 990.331 121.394C994.761 119.727 999.453 118.866 1004.19 118.866L1950.5 114.5" stroke="#DBC087" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M943.235 22.1951C943.235 22.1951 954.797 4.0884 965.586 2.7938C969.466 2.3282 980.02 25.2994 974.743 25.9202C960.715 27.5706 953.891 29.8632 951.165 31.6775C932.048 44.403 917.902 24.9589 903.872 32.5762C883.202 43.7978 886.424 43.195 893.874 42.5741C898.303 42.205 894.033 43.9246 892.481 49.8226C890.929 55.7206 892.493 53.4236 898.845 45.1663C906.605 35.0776 929.577 31.0421 929.422 23.4368C929.266 15.8315 914.366 0 912.659 0C910.951 0 898.535 9.00219 898.224 15.9867C897.914 22.9712 939.355 35.8537 944.787 30.7317C950.22 25.6097 936.28 23.1378 932.865 24.3795C929.45 25.6212 927.74 53.7492 929.593 56.2431C931.533 58.8535 991.289 53.5786 996.644 52.9837C999.438 52.6733 998.824 38.362 996.317 37.4058C990.355 35.1322 935.785 39.8892 928.801 39.8892C921.816 39.8892 886.797 37.2174 885.341 39.4236C881.27 45.5939 883.305 93.9932 885.324 110.461C885.676 113.338 883.019 115.725 880.12 115.702H-69" stroke="#DBC087" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>


        </>
    );
};