import React, { useEffect } from "react";
import Wish from "./Wish/Wish";

import { Grid } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading/loading";
import { useNavigate } from "react-router-dom";
import NoResults from "../../ErrorScreens/NoResults/NoResults";
import { getWishes } from "../../../actions/wishes";
import { getWishboard } from "../../../actions/user";
import { languages } from "../../translations";
import constants from "../../../constants/constants";

const WishGrid = ({ setCurrentId, page, edit, fireConf }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const { wishlist } = useSelector((state) => state.wish);
  const { lang } = useSelector((state) => state.auth);
  const numberOfPages = useSelector((state) => state.wish.numberOfPages);
  const isLoading = useSelector((state) => state.wish.isLoading);
  const navigate = useNavigate();
  const getinvites = () => {
    return dispatch(getWishboard());
  };
  if (wishlist && !wishlist.length && !isLoading) {
    if (!wishlist.length && numberOfPages > 1) {
      navigate(`/wishboard?page=${numberOfPages}`);
    } else if (!wishlist.length && numberOfPages === 1) {
      dispatch(getWishes(page)).then(getinvites());
    }
    return (
      <NoResults
        message={{
          title: `${languages[lang].errors.noresults.wishes.title} `,
          button: "none",
          desc: `${languages[lang].errors.noresults.wishes.desc} `,
        }}
      />
    );
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Grid className={classes.container} container spacing={3}>
      {wishlist?.map((wish) => (
        <Grid key={wish._id} item xs={12} md={6} lg={4}>
          <Wish
            wishData={wish}
            setCurrentId={setCurrentId}
            page={page}
            edit={edit}
            fire={fireConf}
            update={() => {}}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default WishGrid;
