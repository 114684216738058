import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Grow,
  Grid,
  Paper,
  Box,
  AppBar,
  TextField,
  Button,
  Autocomplete,
  Chip,
} from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Stack from "@mui/material/Stack";
import FriendsGrid from "./FriendsGrid/FriendsGrid";

import Pagination from "./pagination/Pagination";

import { languages } from "../translations";
import { useDispatch } from "react-redux";

import { fetchUsers, searchUsers } from "../../actions/user";
import useStyles from "./styles";
import users from "../../reducers/users";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import PageTitle from "../PageTitle/PageTitle";
import SearchGrid from "./SearchGrid/SearchGrid";
import constants from "../../constants/constants";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Friends = () => {
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.auth);
  const [currentId, setCurrentId] = useState(null);
  const { error } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");

  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchFriend();
    }
  };
  const clear = (e) => {
    setSearch("");
    navigate("/friends");
  };

  const searchFriend = () => {
    if (search.trim()) {
      dispatch(
        searchUsers({
          search: search.trim(),
        })
      );

      navigate(`/user/search?searchQuery=${search || "none"}`);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" className="">
        <PageTitle title={`${languages[lang].search.title}`} />
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
          className={classes.new}
        >
          <Grid item xs={12} lg={8}>
            {searchQuery ? (
              <>
                <SearchGrid setCurrentId={setCurrentId} />
              </>
            ) : (
              <Box className="friend-page">
                <FriendsGrid setCurrentId={setCurrentId} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Paper elevation={6} className={classes.formWrapper}>
              <Box className={classes.AppBarSearch}>
                <Grid container>
                  <Grid item xs={12} sm={8} lg={8}>
                    <TextField
                      style={{ marginBottom: "10px" }}
                      name="search"
                      variant="standard"
                      label={`${languages[lang].search.placeholder}`}
                      fullWidth
                      value={search}
                      onKeyDown={handleKeyPress}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <Button
                      onClick={searchFriend}
                      className={classes.searchButton}
                      variant="contained"
                      endIcon={<SearchOutlinedIcon />}
                    >
                      {" "}
                      {languages[lang].search.button}{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Button
                      onClick={clear}
                      style={{ marginTop: "10px" }}
                      variant="text"
                      endIcon={<RestartAlt />}
                    >
                      {languages[lang].search.clear}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
export default Friends;
