import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

//API calls
export const getWishboard = createAsyncThunk("getWishboard", async (page) => {
  const res = await API.get(`user/wishboard?page=${page}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const fetchUserDetails = createAsyncThunk("fetchUser", async () => {
  const res = await API.get(`user/details`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }

  return data;
});
export const fetchFriends = createAsyncThunk("fetchFriends", async () => {
  const res = await API.get(`user/fetch-friends`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});

export const updateDetails = createAsyncThunk(
  "updateDetails",
  async (details) => {
    const formData = new FormData();

    formData.append("firstName", details.firstName);
    formData.append("lastName", details.lastName);
    formData.append("email", details.email);
    formData.append("avatar", details.avatar);
    formData.append("address", details.address);
    formData.append("country", details.country);
    formData.append("city", details.city);
    formData.append("postCode", details.postCode);
    formData.append("phoneNumber", details.phoneNumber);
    formData.append("bio", details.bio);
    formData.append("birthDate", details.birthDate);
    formData.append("sex", details.sex);

    const res = await API.patch("/user/details", details, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await res.data;
    if (data.error && data.error === "JWT expired") {
      localStorage.clear();
    }
    return data;
  }
);
export const initiateReset = createAsyncThunk(
  "initiateReset",
  async (email) => {
    console.log(email);
    const res = await API.post(`/user/initiate-reset`, { email: email });
    const data = await res.data;
    return data;
  }
);
export const resetPwd = createAsyncThunk(
  "reserPwd",
  async (formData, thunkAPI) => {
    try {
      const { data } = await API.post("/user/reset-password", formData);
      return data;
    } catch (error) {
      console.log("error in reset password", error);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
export const confirmEmail = createAsyncThunk("confirmEmail", async (id) => {
  const res = await API.post(`/user/confirm-email/${id}`);
  const data = await res.data;
  return data;
});
export const acceptReq = createAsyncThunk("acceptRequest", async (id) => {
  const res = await API.patch(`/user/accept/${id}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const removeFriend = createAsyncThunk("removeFriend", async (id) => {
  const res = await API.patch(`/user/remove-friend/${id}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const declineReq = createAsyncThunk("declineRequest", async (id) => {
  const res = await API.patch(`/user/decline/${id}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const fetchUser = createAsyncThunk("users/getUser", async (id) => {
  const res = await API.get(`/user/${id}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const fetchUsers = createAsyncThunk("users/getUsers", async (page) => {
  const res = await API.get(`/user?page=${page}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  // console.log(data);
  return data;
});
export const searchUsers = createAsyncThunk(
  "searchFriend",
  async (searchQuery) => {
    const res = await API.get(`/user/search?query=${searchQuery.search}`);

    const data = await res.data;
    if (data.error && data.error === "JWT expired") {
      localStorage.clear();
    }
    return data;
  }
);
export const addFriend = createAsyncThunk("/addFriend", async (id) => {
  const res = await API.post(`/user/add-friend/${id}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const updatePermit = createAsyncThunk(
  "/friend-permit",
  async (permission) => {
    const res = await API.patch(`/user/update-permission`, permission);
    const data = await res.data;
    if (data.error && data.error === "JWT expired") {
      localStorage.clear();
    }
    return data;
  }
);
export const changeLang = createAsyncThunk("/user-lang", async (lang) => {
  const res = await API.patch(`/user/change-lang`, lang);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});

export const bookWish = createAsyncThunk("users/bookWish", async (id) => {
  const res = await API.patch(`/user/book-wish/${id}`);
  const data = await res.data;
  if (data.error && data.error === "JWT expired") {
    localStorage.clear();
  }
  return data;
});
export const getWishListData = createAsyncThunk(
  "content/getWishList",
  async (id, thunkAPI) => {
    try {
      const res = await API.get(`/wish/wishlist/${id}`);
      const data = await res.data;

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("No permission", error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sendMessage = createAsyncThunk(
  "support/sendmMessage",
  async (message, thunkAPI) => {
    try {
      const res = await API.post(`/user/contact/`, message);
      const data = await res.data;

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("No permission", error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
