const ua = {
  home: {
    hero: {
      a1: "Ваші мрії можуть стати реальністю",
      a2: "Загадайте",
      a3: "Бажання",
      a4: "Допоможіть своїм друзям і близьким обрати вам найкращі подарунки ",

      make: "Створити бажання",
      create: "Створити список",
      choose: "Обрати подарунки",
    },
    howReceive: "Як отримати бажане?",
    three: "Три ",
    simple: "Простих ",
    steps: " Кроки",
    s1: {
      title: "Крок 1",
      create: "Створити ",
      account: "Аккаунт",
      instr:
        "Створіть власний простір, де ви зможете загадати стальки бажань скільки захочете",
      button: "Загадати бажання",
    },
    s2: {
      title: "Крок 2",
      create: "Створіть ",
      wishlist: "Список",
      instr:
        "Додайте фото, детальний опис, посилання і навіть визначте пріоритет щоб ваші близькі завжди знали що насправді вам хочеться більше.",
      button: "Створити список!",
    },
    s3: {
      title: "Крок 3",
      share: "Поділіться",
      account: "Аккаунтом",
      instr:
        "Відправте посилання на свою сторінку друзям через соціальні мережі або прямо на пошту і почніть здійснювати Мрії.",
      button: "Запросити друзів!",
    },
    createAccount: "Створити аккаунт",
  },
  auth: {
    login: "Вхід",
    register: "Реєстрація",
    or: "або",

    signUp: "Зареєструватися",
    signIn: "Увійти",
    lastName: "Прізвище",
    firstName: "Ім'я",
    email: "Адреса електронної пошти",
    password: "Пароль",
    repeatpassword: "Повторіть пароль",
    lostpwd: "Забули пароль",
    reset: "Скидання",
    cancel: "Скасувати",
    checkEmail: "Перевірте свою електронну пошту",
    resetpwd: "Скидання пароля",
    resetinstr:
      "Будь ласка, введіть адресу електронної пошти, з якою ви зареєструвалися, і ми відправимо вам інструкції електронною поштою",
    errors: {
      uppercase:
        "Відсутній прописна літера! Пароль повинен містити щонайменше 8 символів, одну прописну та одну рядкову літеру, один спецсимвол і одну цифру",
      lowercase:
        "Принаймні одна рядкова літера! Пароль повинен містити щонайменше 8 символів, одну прописну та одну рядкову літеру, один спецсимвол і одну цифру",
      digit:
        "Принаймні одна цифра! Пароль повинен містити щонайменше 8 символів, одну прописну та одну строчну літеру, один спецсимвол і одну цифру",
      special:
        "Відсутні спеціальні символи! Пароль повинен містити щонайменше 8 символів, одну прописну та одну строчну літеру, один спецсимвол і одну цифру",
      shortpwd:
        "Занадто короткий! Пароль повинен містити щонайменше 8 символів, одну прописну та одну строчну літеру, один спецсимвол і одну цифру",
      pwdnotmatch: "Паролі не збігаються",
    },
  },

  archive: {
    title: "Архів",
    subtitle: "Тут відбражені виконані бажання",
    buttons: {
      wishagain: "Хочу ще!",
      delete: "Видалити",
    },
  },

  navbar: {
    signin: "Увійти",
    logout: "Вийти",
    faq: "Часті запитання",
    wishboard: "Дошка бажань",
    login: "Логін",
    archive: "Виконані бажання",
    register: "Зареєструватись",
    account: "Мій акаунт",
    settings: "Відкрити налаштування ",
  },
  search: {
    placeholder: "Шукати друзів",
    button: "Пошук",
    clear: " Очистити",
    title: "Результати пошуку",
  },
  searchUsers: {
    pending: "Запит відправленно",
    invite: "Запросити",
    friends: "Ваш друг",
    viewProfile: "Переглянути профіль",
    returnToSearch: "Повернутись до пошуку",
  },
  wishboard: {
    tabs: {
      wishes: "Бажання",
      wishlists: "Списки",
      bookedwish: "Заброньоване",
      friends: "Друзі",
    },

    buttons: {
      add: {
        wish: "Загадати бажання",
        wishlist: "Список",
      },
    },
    titles: {
      friends: "Запросити друзів",
    },
  },

  wishcard: {
    buttons: {
      share: "Поділитись",
      more: "Більше",
      delete: "Видалити",
      received: "Позначити як отримане",
      edit: "Редагувати",
    },
    tooltip: {
      book: "Бронючи бажання, ви підтверджуєте свій намір подарувати. ",
    },
    book: "Забронювати",
    login: "Увійдіть щоб бачити статус",
    youBooked: "Ви забронювали",
    notYouBooked: "Хтось забронював",
  },
  wishDetails: {
    tooltip: {
      book: "Бронючи бажання, ви підтверджуєте свій намір подарувати. ",
    },
    book: "Забронювати",
    login: "Увійдіть щоб бачити статус",
    youBooked: "Ви забронювали",
    notYouBooked: "Хтось забронював",
    return: "Повернутись",
    wishmeter: "Шкала мрійника",
    details: "Wish details",
    openLink: "Відкрити",
    link: "Посилання",
    createdBy: "Мрійник",
  },
  wishlist: {
    details: {
      back: "Повернутись до кабінету",
      wishes: "Бажання",
      special: "Особливий",
      created: "Створено",
      updated: "Оновлено",
      desc: "Деталі списку",
      event: "Подія",
      delete: "Видалити",
      received: "Позначити як отримане",
      link: "Посилання",
    },
    tooltip: {
      book: "Бронючи бажання, ви підтверджуєте свій намір подарувати. ",
    },
    book: "Забронювати",
    login: "Увійдіть щоб бачити статус",
    youBooked: "Ви забронювали",
    notYouBooked: "Хтось забронював",
  },
  wishlistForm: {
    title: {
      name: "Назва списку бажань",
      creating: "Створити",
      editing: "Редагувати",
      wishlist: "список бажань",
    },
    tootlTips: {
      eventTooltip:
        "Ви можете додати конкретну дату до якої ви б хотіли отримати це подарунок",
      accessTooltip:
        "Ви можете обемежити доступ до Списку за рівнем доступу або до кілько осіб яких ви оберете. Проте це не вплине на рівент доступу до окреми бажань",
    },
    selectWishes: "Оберіть бажання",
    coverImage: "Виберіть обкладинку",
    upload: "Завантажити",
    description: "Додайте опис",
    shortDescription: "опис",
    addWishes: "Додати бажаня",
    eventDate: "Дата події?",
    eventCalendar: "Коли б ви хотіли отримати",
    access: "Визначте доступ",
    limitAccess: "Обмежити до окремих людей?",
    save: "Зберегти список",
    create: "Створити список",
    confirmModal: "Підтвердити",
    clear: "Очистити",
  },
  wishlistcard: {
    buttons: {
      share: "Поділитись",
      more: "Більше",
      delete: "Видалити",
      received: "Позначити як отримане",
      edit: "Редагувати",
    },
    elements: {
      wishes: "Бажання",
      special: "Особливий",
    },
  },
  customIcons: [
    "Ваш пріоритет",
    "Не терміново",
    "Було б добре",
    "На наступне свято",
    "Дуже хочу",
    "Мрія всього життя",
  ],
  permissionCodes: [
    "Хто матиме доступ?",
    "Всі",
    "Друзі",
    "Сімʼя",
    "Кохана людина",
    "СЕКРЕТНО!",
  ],
  wishForm: {
    title: {
      name: "Назва вашого бажання",
      creating: "Створити",
      editing: "Редагувати",
      wish: "бажання",
    },
    tootlTips: {
      accessTooltip:
        "Ви можете обемежити доступ до бажання за рівнем доступу. Кожен наступний матите доступ до попередніх",
    },
    coverImage: "Знаєте як виглядає?",
    upload: "Завантажити",
    description: "Додайте опис",
    shortDescription: "Опис",
    addToWishlists: "Долучити до створенного списку?",
    selectWishlist: "Оберіть списки",
    dreamscale: "Шкала мрійника",
    access: "Визначте доступ",
    link: "Посилання",
    save: "Зберегти",
    create: "Створити",
    confirmModal: "Підтвердити",
    clear: "Очистити",
    select: "Обрати",
  },
  account: {
    title: "Твої дані",
    account: "Твій акаунт",
    resetPass: "Скинути пароль",
    resetMessage:
      "Будь ласка, введіть адресу електронної пошти, яку ви зареєстрували, і ми надішлемо вам інструкції електронною поштою",
    logout: "Вийти",
    enterEmail: "Введіть email",
    countdown: {
      birthday: "Твій День Народження ",
      in: "через",
      days: "дн",
      hours: "год",
      min: "хв",
      sec: "сек",
    },
    form: {
      first: "Ім'я",
      last: "Прізвище",
      phone: "Номер телефону",
      email: "Email",
      country: "Країна",
      city: "Місто",
      postal: "Індекс",
      adress: "Адреса",
      birthday: "День Народження",
      bio: "Про мене",
      reset: "Скинути",
      upload: "Завантажити",
      save: "Зберегти",
    },
  },
  user: {
    birthday: "День народження ",
    in: "через",
    share: "Поділитись",
    invite: "Запросити",
    pending: "Запит відравленно",
    login: "Увійдіть в обліковий запис щоб додати друга",
    wishlistsTitle: "Списки бажань",
    wishTitle: "Бажання",
    event: "Подія",
    yourFriend: "Ваш друг",
    ownPage: "Так вашу сторінку бачитимуть інші.",
  },
  errors: {
    noresults: {
      bookedWishes: {
        title: "Ще немає заброньованих бажань",
        desc: "Забронюй перше бажання зараз!",
      },
      wishes: {
        title: "Ще немає бажань",
        desc: "Загадай перше бажання зараз!",
      },
      wishlists: {
        title: "Ще немає списків бажань",
        desc: "Склади перший список зараз!",
      },
    },
  },

  friend: {
    buttons: {
      view: "Дивитись",
      more: "Більше",
      permissions: "Змінити рівень доступу",
      delete: "Видалити",
    },
  },
  holidays: {
    сhristmas: "Різдво",
    easter: "Великдень",
    tech: {
      in: "через",
    },
  },
  share: {
    shareWish: {
      title: "Бажанням",
    },
  },
  dialog: {
    delete: {
      wish: {
        title: "Видалити бажання",
        text: "Ви збираєтеся видалити своє бажання. Ми повинні попередити вас, що якщо ви продовжите, ви не зможете скасувати цей процес, і всі люди, які могли підписатися на ваше бажання, назавжди втратять доступ до нього.",
        cancel: "Скасувати",
        delete: "Видалити",
      },
      wishlist: {
        title: "Видалити список бажань",
        text: "Ви збираєтеся видалити свій список бажань. Ми повинні попередити вас, що якщо ви продовжите, ви не зможете скасувати цей процеc.",
        cancel: "Скасувати",
        delete: "Видалити",
      },
      friend: {
        title: "Видалення зі списку друзів",
        text: "Ви збираєтеся видалити користувача і списку друзів. Ви впевнені?",
        cancel: "Скасувати",
        delete: "Видалити друга",
      },
    },
    change: {
      permissions: {
        title: "Змінити рівень доступу",
        text: "Ви збираєтесь змінити те, які ваші бажання буде бачити цей користувач",
        label: "Оберіть новий рівень доступу",
        cancel: "Скасувати",
        confirm: "Підтвердити",
      },
    },
    share: {
      wish: {
        title: "Share Your Wish",
        close: "Close",
      },
      widget: {
        wish: {
          copy: "Копіювати посилання",
        },
      },
      messages: {
        link: "Посилання https://wish-u.app/",
        linkSuccess: "скопійоване",
      },
    },
  },
  faq: {
    title: "Часті запитання",
    subtitle: "Тут ви знайдете відповіді на питання, які ми чуємо найчастіше. ",
    contact: "Якщо відповіді на ваше запитання тут немає",
    contactBtn: "Напишіть нам",
    accordion: {
      q1: "Why should I use Wish?",
    },
  },
  message: {
    title: "Вибачте, ми не можемо знайти того, кого ви шукаєте!",
    desc: "Будь ласка, спробуйте змінити запит. Або надішліть це посилання людині, з якою ви хотіли б зустрітися в Wish",
    friends: {
      title: "Ще немає друзів",
      desc: "Якщо ваші друзі ще не приєдналися до Wish, будь ласка, ви можете запросити їх за посиланням",
      button: "Скопійювати",
    },
  },
};
export default ua;
