import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

//API calls
export const signin = createAsyncThunk(
  "/signin",
  async (formData, thunkAPI) => {
    try {
      const { data } = await API.post("user/signin", formData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("error in signing in", error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const googelOauth = createAsyncThunk(
  "/googleSignin",
  async (token, thunkAPI) => {
    try {
      const { data } = await API.post("user/google-signin", token);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("error in signing in", error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const signup = createAsyncThunk(
  "/signup",
  async (formData, thunkAPI) => {
    try {
      const { data } = await API.post("user/signup", formData);
      return data;
    } catch (error) {
      console.log("error in signing up", error);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
export const logout = createAsyncThunk("/logout", async () => {
  try {
    const { data } = await API.post("user/logout");
    return data;
  } catch (error) {
    console.log("error in signing up", error);
  }
});
