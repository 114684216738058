import React, { useState, useEffect } from "react";
import useStyles from "./styles";

import {
  Button,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  Paper,
  Box,
  Grid,
  IconButton,
  Rating,
  ListSubheader,
  Modal,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { useDispatch, useSelector } from "react-redux";
import { createWish, updateWish } from "../../../actions/wishes";
import constants from "../../../constants/constants";
import { styled } from "@mui/material/styles";
import {
  AutoFixHighOutlined,
  RestartAlt,
  HelpOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import CakeIcon from "@mui/icons-material/Cake";
import ErrorSignIn from "../../ErrorScreens/ErrorSignIn";
import CloseIcon from "@mui/icons-material/Close";
import { languages } from "../../translations";
const Wishform = ({ currentId, setCurrentId, page, closeModal }) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const [wishData, setWishData] = useState({
    title: "",
    description: "",
    dreamScale: 0,
    permission: 0,
    link: "",
    selectedFile: {},
  });
  const { lang } = useSelector((state) => state.auth);
  const { wishLists } = useSelector((state) => state.user);
  const [hover, setHover] = useState(-1);
  const [userHover, setUserHover] = useState(-1);
  const [selectedlists, setSelectedLists] = useState([]);
  const [openLists, setOpenLists] = useState(false);
  const handleOpenLists = () => setOpenLists(true);
  const handleCloseLists = () => setOpenLists(false);
  const [tags, setTags] = useState([]);
  const wish = useSelector((state) =>
    currentId ? state.wish.wishlist.find((w) => w._id === currentId) : null
  );

  const handleToggleList = (value) => () => {
    const currentIndex = selectedlists.findIndex((e) => e._id === value._id);
    const newChecked = [...selectedlists];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedLists(newChecked);
  };
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#F1CE89",
    },
    "& .MuiRating-iconHover": {
      color: "#F1CE89",
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (wish) {
      setWishData(wish);
    }
  }, [wish]);
  useEffect(() => {
    if (wishLists) {
      let wls = wishLists.filter((wishl) =>
        wishl.wishes ? wishl.wishes.find((w) => w === currentId) : null
      );

      setSelectedLists(wls);
    }
  }, [wishLists]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!wishData) return;
    if (currentId) {
      console.log(selectedlists);
      // dispatch(updateWish({ ...wishData, tags }));
      dispatch(
        updateWish({
          ...wishData,
          selectedlists: JSON.stringify(selectedlists),
          id: currentId,
        })
      );
      clear();
      closeModal();
    } else {
      dispatch(
        createWish({
          ...wishData,
          selectedlists: JSON.stringify(selectedlists),
        })
      );
      clear();
      closeModal();
    }
  };
  const cancel = () => {
    clear();
    closeModal();
  };
  //MAX FILE SIZE 5 MB
  const uploadFile = (event) => {
    let sizeInKb = (event.target.files[0].size / (1024 * 1024)).toFixed(2);
    if (sizeInKb > constants.MAX_LIFE_SIZE) {
      console.log(sizeInKb);
      clear();
    }
    let file = event.target.files[0];
    setWishData({ ...wishData, selectedFile: file });
  };
  const handleKeyPress = (e) => {
    if (e.keycode === 13) {
      handleAddTag(e, e.target.value);
    }
  };
  const handleAddTag = (event, tag) => {
    setTags(tag);
  };
  const clear = () => {
    setCurrentId(null);
    setTags("");
    setWishData({
      title: "",
      description: "",
      dreamScale: 1,
      permission: 1,
      // event: moment().add(1, "days").toISOString(),
      link: "",
      selectedFile: "",
    });
    setSelectedLists([]);
  };
  if (!user) {
    return <ErrorSignIn />;
  }
  return (
    <Paper className={classes.paper}>
      <div className="decor-card --form"></div>
      <form
        autoComplete="off"
        className={`${classes.form} ${classes.root}`}
        noValidate
        onSubmit={handleSubmit}
      >
        <Box className={classes.formHeader}>
          <Typography variant="h4">
            {currentId
              ? languages[lang].wishForm.title.editing
              : languages[lang].wishForm.title.creating}{" "}
            {languages[lang].wishForm.title.wish}
          </Typography>
          <Box className={classes.clearBtn}>
            <Button
              style={{ marginBottom: 10 }}
              onClick={cancel}
              // fullWidth
            >
              <CloseIcon />
            </Button>
          </Box>
        </Box>
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          spacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6}>
            {/* <Box> */}
            <TextField
              name="title"
              variant="standard"
              label={languages[lang].wishForm.title.name}
              fullWidth
              required
              value={wishData.title}
              onChange={(e) =>
                setWishData({ ...wishData, title: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="link"
              variant="standard"
              label={languages[lang].wishForm.link}
              fullWidth
              value={wishData.link}
              className="styled-overflow"
              onChange={(e) =>
                setWishData({ ...wishData, link: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.rating}>
              <Typography component="legend">
                {languages[lang].wishForm.dreamscale}
              </Typography>

              <StyledRating
                name="wish-permission"
                defaultValue={wishData.dreamScale}
                getLabelText={(value) => languages[lang].customIcons[value]}
                precision={1}
                icon={<CakeIcon fontSize="inherit" />}
                emptyIcon={<CakeIcon fontSize="inherit" />}
                onChange={(event, newValue) => {
                  setWishData({ ...wishData, dreamScale: newValue });
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              {wishData.dreamScale !== null && (
                <Box sx={{ mt: 2 }}>
                  {
                    languages[lang].customIcons[
                      hover !== -1 ? hover : wishData.dreamScale
                    ]
                  }
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.rating}>
              <div className="access-grid">
                <div className="helper-grid">
                  <Typography component="legend">
                    {languages[lang].wishForm.access}
                  </Typography>
                  <Tooltip
                    title={languages[lang].wishForm.tootlTips.accessTooltip}
                    className="helper-btn"
                  >
                    <IconButton
                      size="large"
                      className="helper-btn"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <HelpOutlined />
                    </IconButton>
                  </Tooltip>
                  <StyledRating
                    name="customized-color"
                    defaultValue={wishData.permission}
                    getLabelText={(value) =>
                      languages[lang].permissionCodes[value]
                    }
                    precision={1}
                    icon={<LockPersonIcon fontSize="inherit" />}
                    emptyIcon={<LockPersonIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setWishData({ ...wishData, permission: newValue });
                    }}
                    onChangeActive={(event, newHover) => {
                      setUserHover(newHover);
                    }}
                    className="icons-rating"
                  />
                </div>

                {wishData.permission !== null && (
                  <>
                    <Box>
                      <div
                        className={`wishlistBadge lvl${
                          userHover !== -1 ? userHover : wishData?.permission
                        }`}
                      >
                        <span className="wishlistBadge-text">
                          {
                            languages[lang].permissionCodes[
                              userHover !== -1
                                ? userHover
                                : wishData?.permission
                            ]
                          }
                        </span>
                        <span className="wishlistBadge-lvl">
                          {userHover !== -1 ? userHover : wishData?.permission}
                        </span>
                      </div>
                    </Box>
                  </>
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="p" className={classes.fullW}>
              {languages[lang].wishForm.description}
            </Typography>
            <TextareaAutosize
              className={classes.textarea}
              aria-label="description"
              minRows={3}
              maxRows={8}
              style={{ width: "100%", resize: "vertical", overflow: "auto" }}
              name="description"
              variant="filled"
              label={languages[lang].wishForm.shortDescription}
              value={wishData.description}
              onChange={(e) =>
                setWishData({ ...wishData, description: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.rating}>
              <div className="input-wishlist">
                <Typography variant="p">
                  {languages[lang].wishForm.addToWishlists}
                </Typography>
                <Button
                  style={{ marginBottom: 10 }}
                  className={classes.buttonSubmit}
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenLists}
                  // fullWidth
                  // endIcon={<AutoFixHighOutlined />}
                >
                  {languages[lang].wishForm.select}
                </Button>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="input-file">
              <Typography variant="p" className={classes.fullW}>
                {languages[lang].wishForm.coverImage}
              </Typography>

              <label htmlFor="upload-image">
                <Button variant="contained" component="span" color="secondary">
                  {languages[lang].wishForm.upload}
                </Button>
                <input
                  id="upload-image"
                  hidden
                  accept="image/png, image/jpeg, image/webp, image/jpg"
                  type="file"
                  onChange={uploadFile}
                />
              </label>
              <Typography variant="p" className={classes.fullW}>
                {wishData?.selectedFile?.name}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <Button
              style={{ marginBottom: 10 }}
              className={classes.buttonSubmit}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              // fullWidth
              endIcon={<AutoFixHighOutlined />}
            >
              {currentId
                ? languages[lang].wishForm.save
                : languages[lang].wishForm.create}
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              color="secondary"
              size="small"
              type="button"
              endIcon={<RestartAlt />}
              onClick={clear}
              className={classes.clear}
            >
              {languages[lang].wishForm.clear}
            </Button>
          </Grid>
        </Grid>
        {/* </Box> */}
      </form>
      <Modal
        open={openLists}
        onClose={handleCloseLists}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Paper className={classes.paper}>
            <List
              className="popup-list"
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
                "& ul": { padding: 0 },
              }}
            >
              <ListSubheader>
                {languages[lang].wishForm.selectWishlist}
              </ListSubheader>
              {wishLists?.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value._id}`;
                return (
                  <ListItem
                    key={value._id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggleList(value)}
                        checked={
                          selectedlists.findIndex(
                            (e) => e._id === value._id
                          ) !== -1
                        }
                        // checked={selectedlists.indexOf(value._id) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar className="avatar --left-zero">
                        <Avatar
                          variant="rounded"
                          alt={`Avatar n°${value.title + 1}`}
                          src={value.selectedFile}
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={`${value.title} `} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Button
              style={{ marginBlock: 10 }}
              className={classes.buttonSubmit}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleCloseLists}
            >
              {languages[lang].wishForm.confirmModal}
            </Button>
          </Paper>
        </Box>
      </Modal>
    </Paper>
  );
};
export default Wishform;

/* <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            className={classes.dateInput}
            fullWidth
            // value={
            //   wishData.event ? moment(wishData.event) : moment().add(1, "days")
            // }
            value={moment(wishData.event)}
            format="DD-MM-YYYY"
            label={"When do you want to receive a gift"}
            views={["day", "month", "year"]}
            onChange={(e) =>
              setWishData({
                ...wishData,
                event: moment(e, "DD MM YYYY").toISOString(),
              })
            }
          />
        </LocalizationProvider>


   <Autocomplete
          autoSelect
          fullWidth
          className={classes.tagsInputWrapper}
          style={{ marginBottom: "10px" }}
          // defaultValue={tags}
          clearIcon={false}
          options={tags}
          freeSolo
          multiple
          onKeyDown={(event) => {
            handleKeyPress(event);
          }}
          getOptionLabel={(option) => option.name || ""}
          // onChange={(e) =>
          //   setWishData({ ...wishData, tags: e.target.value.split(",") })
          // }
          onChange={(event, newInputValue) => {
            handleAddTag(event, newInputValue);
          }}
          renderTags={(tags, props) =>
            tags.map((option, index) => (
              <Chip
                color="primary"
                key={index}
                label={option}
                {...props({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField className={classes.tagsInput} label="Tags" {...params} />
          )}
        /> */
