const constants = {
  wishProfile: "wishProfile",
  wishLang: "wishLang",
  MAX_LIFE_SIZE: 10,
  permissionCodes: [
    "Who can access it?",
    "Public",
    "Friends",
    "Family",
    "Partner",
    "TOP SECRET!",
  ],
};
export default constants;
