function copyText(txt) {
  // Get the text field
  let copyText = `https://wish-u.app/${txt}`;

  // Select the text field
  // copyText.select();
  // copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  // navigator.clipboard.writeText(copyText.value);
  navigator.clipboard.writeText(copyText);

  // Alert the copied text
  // alert("Copied the text: " + copyText);
}

export default copyText;
