import * as THREE from "three";
import { useEffect, useRef } from "react";
import { MeshSurfaceSampler } from "three/addons/math/MeshSurfaceSampler.js";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
// import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { Canvas, useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

console.clear();
function MyThree() {
    const refContainer = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    let gltf1 = useLoader(GLTFLoader, "../models/Duck/glTF/scene.gltf");
    // const gltf1 = useLoader(GLTFLoader, "../models/Fox/glTF/Fox.gltf");
    let gltf2 = useLoader(GLTFLoader, "../models/Duck/glTF/ps5_controller.glb");
    let gltf3 = useLoader(GLTFLoader, "../models/Sketchfab_Scene.glb")
    let gltf4 = useLoader(GLTFLoader, "../models/teddy_bear/scene.gltf")


    useEffect(() => {
        let innerWidth = window.innerWidth > 1024 ? window.innerWidth / 2 : window.innerWidth - 32;
        let innerHeight = window.innerWidth > 1024 ? window.innerHeight * 0.8 : window.innerHeight / 2.5;
        let gu = {
            time: { value: 0 }
        };
        let scene = new THREE.Scene();

        let camera = new THREE.PerspectiveCamera(45, innerWidth / innerHeight, 1, 100);
        window.innerWidth > 1024 ? camera.position.set(0, 0, 1).setLength(20) : camera.position.set(0, 0, 5).setLength(20);

        var renderer = new THREE.WebGLRenderer(
            {
                antialias: true,
                alpha: true,
            });
        renderer.setPixelRatio(devicePixelRatio);
        renderer.setSize(innerWidth, innerHeight);
        document.body.appendChild(renderer.domElement);
        refContainer.current && refContainer.current.appendChild(renderer.domElement);
        window.addEventListener("resize", (event) => {
            camera.aspect = innerWidth / innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(innerWidth, innerHeight);
        });
        let controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.enableZoom = false;
        let light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.setScalar(1);
        scene.add(light, new THREE.AmbientLight(0xffffff, 0.5));
        let amount = 20000;
        const parameters = {}
        parameters.insideColor = '#ffffff'
        parameters.outsideColor = '#EC903C'
        parameters.count = amount;
        parameters.radius = 100;
        const colorInside = new THREE.Color(parameters.insideColor)
        const colorOutside = new THREE.Color(parameters.outsideColor)
        let g = new THREE.BufferGeometry();
        g.setAttribute("position", new THREE.Float32BufferAttribute(new Array(amount * 3).fill(0), 3));
        gltf1.scene.updateMatrixWorld(true);
        let model1 = new THREE.Mesh(mergeModel(gltf1.scene, 6))
        g.setAttribute("positionStart", pointification(model1, amount));


        gltf2.scene.updateMatrixWorld(true);
        let model2 = new THREE.Mesh(mergeModel(gltf3.scene, 1));
        g.setAttribute("positionEnd", pointification(model2, amount));

        let pu = {
            morphRatio: { value: 1 }
        }
        let uniforms = {
            mousePos: { value: new THREE.Vector3() }
        }

        const colors = new Float32Array(parameters.count * 3)

        for (let i = 0; i < parameters.count; i++) {
            // Position
            const i3 = i * 3
            const radius = Math.random() * parameters.radius

            // Color
            const mixedColor = colorInside.clone()
            mixedColor.lerp(colorOutside, radius / parameters.radius)

            colors[i3] = mixedColor.r
            colors[i3 + 1] = mixedColor.g
            colors[i3 + 2] = mixedColor.b
        }
        let materialMorph = new THREE.PointsMaterial({
            // color: 0x44ffff,
            transparent: true,
            size: 0.08,
            sizeAttenuation: true,
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            onBeforeCompile: shader => {
                shader.uniforms.morphRatio = pu.morphRatio;
                shader.vertexShader = `
          uniform float morphRatio;
          attribute vec3 positionStart;
          attribute vec3 positionEnd;
          attribute float rotDir;

          mat2 rot2d(float a){ return mat2(cos(a), sin(a), -sin(a), cos(a));}
          ${shader.vertexShader}
        `.replace(
                    `#include <begin_vertex>`,
                    `#include <begin_vertex>
            vec3 pStart = positionStart;
            vec3 pEnd = positionEnd;
            float distRatio = sin(morphRatio * PI);
            vec3 pos = mix(pStart, pEnd, morphRatio);
            pos.xz *= rot2d(PI2 * morphRatio);
            transformed = pos + normalize(pos) * distRatio * 1.5;
          `
                );
            },
        })

        g.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        let form = new THREE.Points(
            g,
            materialMorph
        )

        scene.add(form);
        const mouse = new THREE.Vector2()
        window.addEventListener('mousemove', (event) => {
            mouse.x = event.clientX / window.innerWidth * 2 - 1
            mouse.y = - (event.clientY / window.innerHeight) * 2 + 1
        })

        renderer.setAnimationLoop(() => {
            renderer.render(scene, camera);
        });


        let changeShape = () => {
            if (gltf2) {
                let model2 = new THREE.Mesh(mergeModel(gltf2.scene, 0.1))
                g.setAttribute("positionEnd", pointification(model2, amount));
                form.geometry.attributes.positionEnd.needsUpdate = true;
            }
        }
        let duration = 4;

        let tlMorph = gsap.timeline({ repeat: 0, repeatDelay: 4 });

        let color1 = {
            // r: 0.254, g: 0.254, b: 0.223
            r: 0.266, g: 1, b: 1,
        }
        let color2 = {
            // r: 0.160, g: 0.135, b: 0.255
            // r: 0.58, g: 0.45, b: 0.85
            // r: 0.115, g: 0.145, b: 0.185
            r: 0.15, g: 0.45, b: 0.95
        }

        let color3 = {
            r: 0.266, g: 1, b: 1,
        }
        tlMorph.fromTo(pu.morphRatio, { value: 0.3 }, { value: 1, duration })
        tlMorph.fromTo(form.rotation, { x: 2, y: 1, z: 2 }, { x: 2, y: -3, z: 2, duration }, "-=100%")
        tlMorph.fromTo(pu.morphRatio, { value: 1 }, { value: 0, duration }, "+=0.5")
        tlMorph.to(materialMorph.color, { r: color1.r, g: color1.g, b: color1.b, duration }, "<")
        tlMorph.to(form.rotation, { x: 0, y: 0.25, z: 0, duration }, "-=100%")
        tlMorph.add(changeShape);
        tlMorph.fromTo(pu.morphRatio, { value: 0 }, { value: 1, duration }, "+=0.5")
        tlMorph.to(materialMorph.color, { r: color2.r, g: color2.g, b: color2.b, duration }, "<")
        tlMorph.fromTo(pu.morphRatio, { value: 1 }, { value: 0, duration }, "+=0.5")
        tlMorph.to(materialMorph.color, { r: color3.r, g: color3.g, b: color3.b, duration }, "<")


        function mergeModel(model, scale = 1) {
            let gs = [];
            model.traverse(child => {
                if (child.isMesh) {
                    let g = child.geometry.clone().toNonIndexed();
                    for (let a in g.attributes) {
                        if (a != "position") g.deleteAttribute(a);
                    }
                    g.applyMatrix4(child.matrixWorld);
                    gs.push(g);
                }
            })
            return BufferGeometryUtils.mergeGeometries(gs).center().scale(scale, scale, scale);
        }

        function pointification(mesh, amount) {
            let mss = new MeshSurfaceSampler(mesh).build();
            let pointsData = [];
            let v = new THREE.Vector3();
            for (let i = 0; i < amount; i++) {
                mss.sample(v);
                v.toArray(pointsData, i * 3);
            }
            return new THREE.Float32BufferAttribute(pointsData, 3);
        }

    }, []);
    return (
        <>
            <div ref={refContainer}></div>

        </>

    );
}

export default MyThree
