import { createSlice } from "@reduxjs/toolkit";
import {
  getWish,
  getWishes,
  searchWish,
  createWish,
  updateWish,
  deleteWish,
  getArchive,
  markComplete,
  unMarkComplete,
} from "../actions/wishes";
const initialState = {
  wish: null,
  wishlist: [],
  allWishes: [],
  archive: [],
  currentPage: 0,
  numberOfPages: 0,
  isLoading: true,
  error: null,
};

export const wishesSlice = createSlice({
  name: "wish",
  initialState,
  reducers: {
    setWishes: (state, action) => {
      state.isLoading = false;
      state.wishlist = state.wishlist.map((wish) =>
        wish._id === action.payload._id ? action.payload : wish
      );
    },
    bookSingleWishSlice: (state, action) => {
      let obj = { ...action.payload.wish };
      if (obj.booked === action.payload.id) {
        obj.booked = null;
      } else if (obj.booked === null) {
        obj.booked = action.payload.id;
      }
      state.wish = obj;
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    endLoading: (state, action) => {
      state.isLoading = false;
    },
  },

  extraReducers: (builder) => {
    //Get wishes
    builder.addCase(getWishes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWishes.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        state.error = action.payload.error;
        localStorage.clear();
      }

      state.isLoading = false;
      state.allWishes = action.payload.allwishes;
      state.wishlist = action.payload.data;
      state.numberOfPages = action.payload.numberOfPages;
      state.currentPage = action.payload.currentPage;
      state.error = null;
    });
    builder.addCase(getWishes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //Get Single wish
    builder.addCase(getWish.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWish.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      if (action.payload.error) {
        if (action.payload.error.response.status === 401) {
          state.error = action.payload.error.response.statusText;
        }
      }

      state.wish = action.payload;
      state.numberOfPages = action.payload.numberOfPages;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getWish.rejected, (state, action) => {
      state.error = action.error.message;
    });

    //create wish
    builder.addCase(createWish.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createWish.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      state.isLoading = false;
      state.wishlist = action.payload.data;
      state.numberOfPages = action.payload.numberOfPages;
      state.error = null;
    });
    builder.addCase(createWish.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // Update wish
    builder.addCase(updateWish.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateWish.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      state.isLoading = false;
      state.wishlist = state.wishlist.map((wish) =>
        wish._id === action.payload.wish._id ? action.payload.wish : wish
      );
      state.error = null;
    });
    builder.addCase(updateWish.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // Delete wish
    builder.addCase(deleteWish.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWish.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.error && action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      state.wishlist = state.wishlist.filter(
        (wish) => wish._id !== action.payload.id
      );
      state.archive = state.archive.filter(
        (wish) => wish._id !== action.payload.id
      );
      state.numberOfPages = action.payload.numberOfPages;
      state.error = null;
    });
    builder.addCase(deleteWish.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // Search post
    builder.addCase(searchWish.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchWish.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("action ", action.payload);
      state.wishlist = action.payload.wishlist;
    });

    builder.addCase(searchWish.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //Get completed/archive wishes
    builder.addCase(getArchive.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getArchive.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        state.error = action.payload.error;
        localStorage.clear();
      }

      state.isLoading = false;
      state.archive = action.payload.data;
      state.error = null;
    });
    builder.addCase(getArchive.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //mark complete
    builder.addCase(markComplete.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.error && action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }

      state.wishlist = state.wishlist.filter(
        (wish) => wish._id !== action.payload.id
      );
      state.numberOfPages = action.payload.numberOfPages;
      state.error = null;
    });
    // un mark complete
    builder.addCase(unMarkComplete.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.error && action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }

      state.archive = state.archive.filter(
        (wish) => wish._id !== action.payload.id
      );
      state.error = null;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setWishes, bookSingleWishSlice } = wishesSlice.actions;

export default wishesSlice.reducer;
