import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

  ul: {
    justifyContent: "center",
  },
  paperTitle: {
    maxWidth: 500,
    padding: theme.spacing(2, 0),
    marginInline: "auto",
  },
  paper: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",

  }
}));
