import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { signin, googelOauth, signup, logout } from "../actions/auth";
import constants from "../constants/constants";

import { changeLang } from "../actions/user";
const initialState = {
  authData: null,
  lang: localStorage.getItem(constants.wishLang) || "en",
  isLogin: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeLangReducer: (state, action) => {
      state.lang = action.payload.lang || "en";
      localStorage.setItem(constants.wishLang, action.payload.lang || "en");

      // return { ...state };
    },
  },
  extraReducers: (builder) => {
    //Sign in
    builder.addCase(signin.pending, (state) => {});
    builder.addCase(signin.fulfilled, (state, action) => {
      localStorage.setItem(
        constants.wishProfile,
        JSON.stringify({ ...action.payload })
      );
      localStorage.setItem(constants.wishLang, action.payload.lang);
      state.authData = action.payload;
      state.lang = action.payload.lang;
    });
    builder.addCase(signin.rejected, (state, action) => {
      console.log("Error - rejected", action);
    });
    //Sign in with Google
    builder.addCase(googelOauth.pending, (state) => {});
    builder.addCase(googelOauth.fulfilled, (state, action) => {
      localStorage.setItem(
        constants.wishProfile,
        JSON.stringify({ ...action.payload })
      );
      localStorage.setItem(constants.wishLang, action.payload.lang);
      state.authData = action.payload;
      state.lang = action.payload.lang;
    });
    builder.addCase(googelOauth.rejected, (state, action) => {
      console.log("Error - rejected", action);
    });

    //sign up
    builder.addCase(signup.pending, (state) => {});
    builder.addCase(signup.fulfilled, (state, action) => {});
    builder.addCase(signup.rejected, (state, action) => {
      state.error = action.payload;
    });
    //change language
    builder.addCase(changeLang.pending, (state) => {});
    builder.addCase(changeLang.fulfilled, (state, action) => {
      localStorage.setItem(constants.wishLang, action.payload.lang);
      state.lang = action.payload.lang;
    });
    builder.addCase(changeLang.rejected, (state, action) => {
      state.error = action.payload;
    });

    //Logout
    builder.addCase(logout.fulfilled, (state, action) => {
      localStorage.removeItem(constants.wishProfile);
      state.authData = null;
    });
  },
});

// Action creators are generated for each case reducer function
export const { auth, changeLangReducer } = authSlice.actions;

export default authSlice.reducer;
