import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

  formWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
    position: "relative",
    isolation: "isolate",
    overflow: "hidden",
  },
  heading: {
    color: "rgba(0,183,255, 1)",
  },
  new: {
    marginBottom: theme.spacing(6),
    minHeight: "60vh",
  },

  // mainContainer: {
  //   paddingTop: 25,
  // },
  image: {
    marginLeft: "15px",
  },

  btnsRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",

  },

  AppBarSearch: {
    borderRadius: 4,
    marginBottom: "1rem",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: theme.spacing(2, 0, 0),
    boxShadow: "none!important",
  },
  searchButton: {},
  [theme.breakpoints.down("sm")]: {
    mainContainer: {
      flexDirection: "column-reverse!important",
    },
  },
}));
