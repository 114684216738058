import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";

import {
  Button,
  Typography,
  Paper,
  Box,
  Grow,
  Container,
  Grid,
  BottomNavigationAction,
  BottomNavigation,
  Chip,
} from "@mui/material";
import image3 from "../../images/Homepage/image3.jpg";
import {
  Face3,
  Lock,
  RedoOutlined,
  Face2,
  Key,
  Pets,
  Restore,
  Favorite,
  LocationOn,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Instagram } from "../Footer/icons";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import BottomNav from "../Navbar/BottomNav/BottomNav";
const Footer = () => {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footer}>
      {/* <BottomNav /> */}
      <Container maxWidth="xl" className="footer-bottom">
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          columnSpacing={{ xs: 0, md: 3 }}
          className={classes.footerContainer}
        >
          <Grid item xs={4} md={4}>
            {/* <IconButton aria-label="delete" className={classes.social}> */}
            {/* <a href="#" className="social">
                            <Instagram />

                        </a> */}
            {/* </IconButton> */}
            <a
              href="https://www.instagram.com/wish_u_app/"
              target="_blank"
              className="privacy tiny"
            >
              <Instagram />
              Instagram
            </a>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography
              variant="p"
              align="center"
              className={classes.copyright}
            >
              Wish 2023
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <a href="/privacy" className="privacy">
              Privacy Policy
            </a>
          </Grid>
        </Grid>
      </Container>
      {/* </Grow> */}
    </Box>
  );
};
export default Footer;
