import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { Button, List, ListItem, Grid } from "@mui/material";

import { EmailOutlined, IosShareOutlined } from "@mui/icons-material";

import { useNavigate, useParams } from "react-router-dom";
import copyText from "../ErrorScreens/helpers/copy";
import constants from "../../constants/constants";
import Toast from "../toast/Toast";
import { TwitterNew, FacebookNew } from "./decor/icons";
import { languages } from "../translations";
const toastConf = {
  open: false,
  severity: "error",
  message: "Error",
};
const Share = ({ type, id, shareText }) => {
  const [toast, setToast] = useState(toastConf);

  const classes = useStyles();
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };

  const { lang } = useSelector((state) => state.auth);

  const twitterLink = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=I%20Have%20a%20wish!Checkout%20it%20out%20at%20https://wish-u.app/${id}`
    );
  };

  const emailLink = () => {
    window.open(
      `mailto:email@example.com?subject=I have a wish&body=Checkout what I wish this year at https://wish-u.app/${id}`
    );
  };
  const fBlink = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://wish-u.app/${id}`
    );
  };

  const copyLink = () => {
    copyText(`${id}`);

    setToast({
      open: true,
      severity: "success",
      message: `${languages[lang].dialog.share.messages.link}${id} ${languages[lang].dialog.share.messages.linkSuccess}`,
    });
  };

  return (
    <div className={classes.paper}>
      <List component="nav" aria-label="mailbox folders">
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          className={classes.mainContainer}
        >
          <Grid item xs={6} md={3}>
            <ListItem className="share-list-item">
              <Button
                variant="link"
                id="myInput"
                startIcon={<EmailOutlined />}
                className="share-list-btn"
                color="primary"
                size="small"
                onClick={() => {
                  emailLink();
                }}
              >
                Email
              </Button>
            </ListItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <ListItem className="share-list-item">
              <Button
                variant="link"
                id="myInput"
                startIcon={<FacebookNew />}
                className="share-list-btn"
                size="small"
                color="primary"
                onClick={() => {
                  fBlink();
                }}
              >
                Facebook
              </Button>
            </ListItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <ListItem className="share-list-item">
              <Button
                variant="link"
                startIcon={<TwitterNew />}
                className="share-list-btn"
                id="myInput"
                size="small"
                color="primary"
                onClick={() => {
                  twitterLink();
                }}
              >
                Twitter / X
              </Button>
            </ListItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <ListItem className="share-list-item">
              <Button
                variant="link"
                className="share-list-btn"
                id="myInput"
                size="small"
                startIcon={<IosShareOutlined />}
                color="primary"
                onClick={() => {
                  copyLink();
                }}
              >
                {languages[lang].dialog.share.widget.wish.copy}
              </Button>
            </ListItem>
          </Grid>
        </Grid>
      </List>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleClose}
      />
    </div>
  );
};
export default Share;
