import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import axios from "axios";
import GoogleIcon from "@mui/icons-material/Google";
import React, { useState, useEffect } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import useStyles from "./Styles";

import Input from "./input";
import icon from "./icon";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../reducers/auth";
import { signup, signin, googelOauth } from "../../actions/auth";
import { initiateReset } from "../../actions/user";
import Toast from "../toast/Toast";
import Logo from "../Logo/Logo";
import image3 from "../../images/Homepage/image5.jpg";
import constants from "../../constants/constants";
import { languages } from "../translations";
import { Girlande, Cake, Fireworks, FireworksTop } from "./decor/loginScene";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

// Notifications
const toastConf = {
  open: false,
  severity: "error",
  message: "Error",
};

const Auth = () => {
  const lang = useSelector((state) => state.auth.lang);
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const [isSignup, setIsSignup] = useState(false);
  const [toast, setToast] = useState(toastConf);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showReset, setshowReset] = useState(false);
  const [resetData, setresetData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);
  // Validation timer
  const [timer, setTimer] = useState(null);

  const [passwordError, setPasswordErr] = useState("");

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(constants.wishProfile))
  );
  useEffect(() => {
    if (user) {
      navigate("/wishboard");
    }
  }, [user]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };

  const setIsShown = () => {
    let hoverBtns = document.querySelectorAll(".hoverBtn");
    let decorSvg = document.querySelector(".fireworks-image.top");
    hoverBtns.forEach(function (item) {
      item.addEventListener("mouseover", function () {
        decorSvg.classList.add("active");
      });

      item.addEventListener("mouseout", function () {
        decorSvg.classList.remove("active");
      });
    });
  };

  const validate = () => {
    let emailValid = formData.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (emailValid) {
      setErrorText(emailValid ? "" : " Email is invalid");
      setError(emailValid ? false : true);
    }
    let passwordValid = formData.password.length >= 6;

    if (formData.confirmPassword === formData.password) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = formData.password.length;
      const uppercasePassword = uppercaseRegExp.test(formData.password);
      const lowercasePassword = lowercaseRegExp.test(formData.password);
      const digitsPassword = digitsRegExp.test(formData.password);
      const specialCharPassword = specialCharRegExp.test(formData.password);
      const minLengthPassword = minLengthRegExp.test(formData.password);

      if (!uppercasePassword) {
        setErrorText(languages[lang].auth.errors.uppercase);
        setError(true);
      } else if (!lowercasePassword) {
        setErrorText(languages[lang].auth.errors.lowercase);
        setError(true);
      } else if (!digitsPassword) {
        setErrorText(languages[lang].auth.errors.digit);
        setError(true);
      } else if (!specialCharPassword) {
        setErrorText(languages[lang].auth.errors.special);
        setError(true);
      } else if (!minLengthPassword) {
        setErrorText(languages[lang].auth.errors.shortpwd);
        setError(true);
      } else {
        setErrorText(null);
        setError(false);
      }
    } else {
      setErrorText(languages[lang].auth.errors.pwdnotmatch);
      setError(true);
    }
    return error;
  };

  const validateField = (e) => {
    switch (e.target.name) {
      case "firstName":
        break;
      case "lastName":
        break;

      case "email":
        console.log(e.target.value);
        let emailValid = e.target.value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        if (!emailValid) {
          setErrorText(" Email is invalid");
          setError(true);
        } else {
          setErrorText(null);
          setError(false);
        }

        break;

      case "password":
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = e.target.value.length;
        const uppercasePassword = uppercaseRegExp.test(e.target.value);
        const lowercasePassword = lowercaseRegExp.test(e.target.value);
        const digitsPassword = digitsRegExp.test(e.target.value);
        const specialCharPassword = specialCharRegExp.test(e.target.value);
        const minLengthPassword = minLengthRegExp.test(e.target.value);

        if (!uppercasePassword) {
          setErrorText(languages[lang].auth.errors.uppercase);
          setError(true);
        } else if (!lowercasePassword) {
          setErrorText(languages[lang].auth.errors.lowercase);
          setError(true);
        } else if (!digitsPassword) {
          setErrorText(languages[lang].auth.errors.digit);
          setError(true);
        } else if (!specialCharPassword) {
          setErrorText(languages[lang].auth.errors.special);
          setError(true);
        } else if (!minLengthPassword) {
          setErrorText(languages[lang].auth.errors.shortpwd);
          setError(true);
        } else {
          setErrorText(null);
          setError(false);
        }

        break;

      case "confirmPassword":
        if (e.target.value !== formData.password) {
          setErrorText(languages[lang].auth.errors.pwdnotmatch);
          setError(true);
        } else {
          setErrorText(null);
          setError(false);
        }
        break;

      default:
        break;
    }
  };
  const handleResetSubmit = (e) => {
    e.preventDefault();

    dispatch(initiateReset(resetData, navigate)).then((result) => {
      if (!result.error) {
        toggleReset();
        setToast({
          open: true,
          severity: "success",
          message: languages[lang].auth.checkEmail,
        });
      } else {
        setToast({
          ...toast,
          open: true,
          message: `Error ${result.payload}`,
        });
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSignup) {
      let res = validate();
      if (error) return;
      dispatch(signup(formData, navigate)).then((result) => {
        if (!result.error) {
          setToast({
            open: true,
            severity: "success",
            message: languages[lang].auth.checkEmail,
          });
          navigate("/wishboard");
        } else {
          setToast({
            ...toast,
            open: true,
            message: `Error ${result.payload}`,
          });
        }
      });
    } else {
      dispatch(signin(formData, navigate)).then((result) => {
        if (!result.error) {
          navigate("/wishboard");
        } else {
          setToast({
            ...toast,
            open: true,
            message: `Error ${result.payload}`,
          });
        }
      });
    }
  };

  const handleChange = (e) => {
    if (isSignup) {
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        validateField(e);
      }, 500);
      setTimer(newTimer);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleResetChange = (e) => {
    setresetData(e.target.value);
  };
  const toggleReset = () => {
    setshowReset((prevShowReset) => !prevShowReset);
  };
  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const switchMode = () => {
    setErrorText("");
    setIsSignup((prevSetIsSignup) => !prevSetIsSignup);
    handleShowPassword(false);
  };

  const googleSuccess = async (res) => {
    const client = res?.clientId;
    const token = res?.credential;

    try {
      dispatch(googelOauth(res, navigate)).then((result) => {
        if (!result.error) {
          navigate("/wishboard");
        } else {
          setToast({
            ...toast,
            open: true,
            message: `Error ${result.payload}`,
          });
        }
      });
      navigate("/wishboard");
    } catch (error) {
      console.log(error);
    }
  };
  const googleError = async (error) => {
    setToast({
      ...toast,
      open: true,
      message: `Error ${error}`,
    });
  };

  return (
    <Container component="main" className={classes.main}>
      <Paper className={classes.paper} elevation={6}>
        <div className="row auth-row">
          {/* <div className="col-image row-col"> */}
          <Girlande className="girlande" />
          <div className={classes.brandContainer}>
            <Logo className="logo" />
          </div>
          <div className="fireworks-wrapper">
            <Fireworks className="fireworks" />
            <FireworksTop className="fireworks --top" />
          </div>
          <Cake className="cake" />
          {/* </div> */}
          {/* <div className="col-form row-col"> */}
          <div className="form">
            <Grid
              container
              className="form-title"
              spacing={2}
              style={{ marginBottom: 10 }}
            >
              <Typography
                variant="h5"
                className={` ${
                  isSignup ? "register-title form-h5" : "login-title form-h5"
                }`}
              >
                {isSignup
                  ? `${languages[lang].auth.register}`
                  : `${languages[lang].auth.login}`}
              </Typography>{" "}
              &nbsp;
              <Typography variant="h5" className="loginBtn">
                {" "}
                {`${languages[lang].auth.or}`}
              </Typography>
              <Button
                variant="text"
                className="loginBtn"
                color="light"
                onClick={switchMode}
              >
                {isSignup
                  ? `${languages[lang].auth.login}`
                  : `${languages[lang].auth.register}`}
              </Button>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2} style={{ marginBottom: 10 }}>
                {isSignup && (
                  <>
                    <Input
                      name="firstName"
                      label={languages[lang].auth.firstName}
                      handleChange={handleChange}
                      autoFocus
                      half
                      required
                    />
                    <Input
                      name="lastName"
                      label={languages[lang].auth.lastName}
                      handleChange={handleChange}
                      half
                      required
                    />
                  </>
                )}
                <Input
                  name="email"
                  label={languages[lang].auth.email}
                  handleChange={handleChange}
                  autoFocus
                  type="email"
                />
                {!isSignup && (
                  <Input
                    minlength="8"
                    name="password"
                    label={languages[lang].auth.password}
                    handleChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    handleShowPassword={handleShowPassword}
                  />
                )}
                {isSignup && (
                  <>
                    <Input
                      minlength="8"
                      name="password"
                      half
                      label={languages[lang].auth.password}
                      handleChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      handleShowPassword={handleShowPassword}
                    />
                    <Input
                      minlength="8"
                      half
                      name="confirmPassword"
                      label={languages[lang].auth.repeatpassword}
                      handleChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      handleShowPassword={handleShowPassword}
                    />
                  </>
                )}
              </Grid>
              <Grid
                container
                style={{ marginBottom: 10 }}
                justifyContent="space-between"
              >
                <Grid item className="hoverBtn">
                  <Button
                    onMouseEnter={() => setIsShown(true)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {isSignup
                      ? `${languages[lang].auth.signUp}`
                      : `${languages[lang].auth.signIn}`}
                  </Button>
                </Grid>
                <Grid item>
                  {!isSignup && (
                    <Button variant="text" color="light" onClick={toggleReset}>
                      {languages[lang].auth.lostpwd}
                    </Button>
                  )}
                </Grid>
              </Grid>
              {errorText && <Alert severity="warning">{errorText}</Alert>}
              <Grid container justifyContent="flex-start">
                <Grid item className={classes.altBtn}>
                  {/* <GoogleLogin
                      onSuccess={googleSuccess}
                      onError={googleError}
                      render={(renderProps) => (
                        <Button
                          className={classes.googleButton}
                          color="primary"
                          fullWidth
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          startIcon={<icon />}
                          variant="contained"
                        ></Button>
                      )}
                      cookiePolicy="single_host_origin"
                    /> */}
                  <Button
                    className="hoverBtn no-effect"
                    onMouseEnter={() => setIsShown(true)}
                  >
                    <GoogleLogin
                      onSuccess={googleSuccess}
                      onError={googleError}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          This is Google button
                        </button>
                      )}
                      buttonText="Login"
                      // theme="filled_black"
                      shape="pill"
                      size="large"
                      cookiePolicy={"single_host_origin"}
                    />
                  </Button>
                </Grid>
                <Grid item className={classes.altBtn}>
                  {/* <Button variant="text" color="light" onClick={switchMode}>
                      {isSignup
                        ? "Alredy have an account? Sign in"
                        : "Don't have an account? Sign up"}
                    </Button> */}

                  {/* <Box className={classes.googlebtn}>
                    <Button style={{ border: "1px solid" }} onClick={testLogin}>
                      <GoogleIcon style={{ marginRight: 10 }} /> Login with
                      Google
                    </Button>
                  </Box> */}
                </Grid>
              </Grid>
            </form>
            <Dialog open={showReset} onClose={handleClose}>
              <DialogTitle> {languages[lang].auth.resetpwd}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {languages[lang].auth.resetinstr}
                </DialogContentText>
                <TextField
                  fullWidth
                  margin="dense"
                  className={classes.inputFullWidth}
                  name="reset"
                  type="email"
                  autoComplete="email"
                  onChange={handleResetChange}
                  label="Enter email"
                  value={resetData}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleReset}>
                  {languages[lang].auth.cancel}{" "}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleResetSubmit}
                >
                  {languages[lang].auth.reset}
                </Button>
              </DialogActions>
            </Dialog>

            {/* {showReset && (
                <form className={classes.form} onSubmit={handleResetSubmit}>
                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <TextField
                      fullWidth
                      margin="dense"
                      className={classes.inputFullWidth}
                      name="reset"
                      type="email"
                      autoComplete="email"
                      onChange={handleResetChange}
                      label="Enter email"
                      value={resetData}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Send
                  </Button>
                </form>
              )} */}
            <Toast
              open={toast.open}
              message={toast.message}
              severity={toast.severity}
              onClose={handleClose}
            />
          </div>
          {/* </div> */}
        </div>
      </Paper>
    </Container>
  );
};
export default Auth;
