import React, { Suspense } from "react";
import { Container } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Navbar from "./components/Navbar/Navbar";

import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import WishDetails from "./components/WishDetails/WishDetails";
import UserDetails from "./components/UserDetails/UserDetails";
import ArchivePage from "./components/ArchivePage/ArchivePage";
import Wishboard from "./components/Wishboard/Wishboard";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import { useSelector } from "react-redux";
import constants from "./constants/constants";
import Friends from "./components/Friends/Friends";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import FriendDetails from "./components/FriendDetails/FriendDetails";
import Loading from "./components/Loading/loading";
import PasswordReset from "./components/Auth/PasswordReset";

import Privacy from "./components/Privacy/Privacy";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import UserProfile from "./components/UserProfile/UserProfile";
import Faq from "./components/Faq/Faq";
import WishList from "./components/Wishlist/WishList";
import Contacts from "./pages/Contacts/Contacts";
const App = () => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const authData = useSelector((state) => state.auth.authData);

  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="615265689865-puiolg9t1epd449n77mdv3kp6ah34um1.apps.googleusercontent.com">
        {/* <GoogleOAuthProvider clientId="870871470659-nn2a01q2f29rp6iltd2lpvbr764nf2bg.apps.googleusercontent.com"> */}
        <Suspense fallback={<Loading />}>
          <Container className="mainWrapper">
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              cookieName="wishawesomecookies"
              style={{
                background: "rgb(1 14 17)",
                borderTop: "thin solid rgb(241 206 137)",
              }}
              buttonStyle={{
                color: "#4e503b",
                backgroundColor: "#fff4e2",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>

            <Routes>
              <Route path="contacts" element={<Contacts />} />

              <Route
                path="user/search"
                element={
                  <ProtectedRoute>
                    <Friends />
                  </ProtectedRoute>
                }
              />
              <Route
                path="details"
                element={
                  <ProtectedRoute>
                    <UserDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="archive"
                element={
                  <ProtectedRoute>
                    <ArchivePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="friends"
                element={
                  <ProtectedRoute>
                    <Friends />
                  </ProtectedRoute>
                }
              />

              <Route
                path="wishboard"
                element={
                  <ProtectedRoute>
                    <Wishboard />
                  </ProtectedRoute>
                }
              />

              <Route path="wishList/:id" element={<WishList />} />
              <Route path="wish/:id" element={<WishDetails />} />
              <Route
                path="friend/:id"
                element={
                  //<ProtectedRoute>
                  <FriendDetails />
                  //</ProtectedRoute>
                }
              />
              <Route path="user/:id" element={<FriendDetails />} />

              <Route
                path="auth"
                element={
                  !authData || !user ? (
                    <Auth />
                  ) : (
                    <Navigate replace to="/auth" />
                  )
                }
              />
              <Route path="restore" element={<PasswordReset />} />
              <Route path="confirm-email/:id" element={<ConfirmEmail />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="404" element={<ErrorPage />} />
              <Route index element={<Home />} />
              {/* 404 */}
              <Route path="*" element={<Navigate replace to="/404" />} />
              <Route path="faq" element={<Faq />} />
            </Routes>
          </Container>
        </Suspense>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
};
export default App;
