import React, { useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';
import {
  Card,
  cardActions,
  CardContent,
  CardMedia,
  Button,
  Box,
  Typography,
  CardActions,
  Grow,
  Paper,
  ButtonBase,
  Dialog,
  Popper,
  Rating,
  MenuItem,
  MenuList,
  ClickAwayListener,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  MoreHoriz,
  DeleteOutlined,
  LockPerson,
  ModeEditOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import constants from "../../../../../constants/constants";
import { removeFriend, updatePermit } from "../../../../../actions/user";
import { styled } from "@mui/material/styles";
import Toast from "../../../../toast/Toast";

import { languages } from "../../../../translations";

const toastConf = {
  open: false,
  severity: "error",
  message: "Error",
};
const Friend = ({ friendData, setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.auth);
  const [showReset, setshowReset] = useState(false);
  const [showPermis, setShowPermis] = useState(false);
  const [permisData, setPermisData] = useState(friendData.accessLvl);
  const [hover, setHover] = useState(-1);
  const [toast, setToast] = useState(toastConf);
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#F1CE89",
    },
    "& .MuiRating-iconHover": {
      color: "#F1CE89",
    },
  });
	moment.locale(lang === "ua" ? "uk" : lang);
  const toggleReset = () => {
    setshowReset((prevShowReset) => !prevShowReset);
  };
  const togglePermis = () => {
    setShowPermis((prevShowPermis) => !prevShowPermis);
  };
  const openWish = () => {
    navigate(`/friend/${friendData.user._id}`);
  };
  const handleClosePopper = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };
  const handleDeleteSubmit = (e) => {
    e.preventDefault();

    dispatch(removeFriend(friendData.user._id)).then((result) => {
      if (!result.error) {
        toggleReset();
        setToast({
          open: true,
          severity: "success",
          message: "Friend removed from your list",
        });
      } else {
        setToast({
          ...toast,
          open: true,
          message: `Error ${result.payload}`,
        });
      }
    });
  };
  const handlePermisSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updatePermit({ permission: permisData, friendId: friendData.user._id })
    ).then((result) => {
      if (!result.error) {
        togglePermis();
        setToast({
          open: true,
          severity: "success",
          message: "Friend permissions updated",
        });
      } else {
        setToast({
          ...toast,
          open: true,
          message: `Error ${result.payload}`,
        });
      }
    });
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (event === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <Card className={classes.card}>
      <Box className={classes.friendCard}>
        <div className={classes.overlay2}>
          {friendData.accessLvl !== null && (
            <div
              className={`wishlistBadge lvl${friendData?.accessLvl || 1}`}
              onClick={handleToggle}
              ref={anchorRef}
            >
              <span className="wishlistBadge-text">
                {languages[lang].permissionCodes[friendData?.accessLvl || 1]}
              </span>
              <span className="wishlistBadge-lvl">{friendData?.accessLvl}</span>
            </div>
          )}
        </div>
        <div className={classes.ava}>
          <ButtonBase className={classes.cardAction} onClick={openWish}>
            <div className="avatar --lg">
              <CardMedia
                component="img"
                className={classes.media}
                image={
                  friendData?.user?.avatar ||
                  "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
                }
                title={friendData?.user?.firstName}
              />
            </div>
          </ButtonBase>

          <div className={classes.overlay2}>
            {(user?.sub === friendData?.creator ||
              user?._id === friendData?.creator) && <></>}
          </div>
        </div>
        <Box className={classes.friendData}>
          <Box className={classes.friendsName}>
            <Typography className={classes.title} variant="body">
              {friendData?.user?.firstName}
            </Typography>
            <Typography variant="body">{friendData?.user?.lastName}</Typography>
          </Box>
          <Typography className={classes.title} variant="h5" component="h4">
            {friendData?.user?.event && (
              <> Event {moment(friendData?.user?.event).fromNow()}</>
            )}
          </Typography>

          <CardContent
            sx={{
              "&:last-child": {
                p: 1,
                paddingBottom: 1,
              },
            }}
            className={classes.bio}
          >
            <Box className={classes.accessLvl}>
              <Typography
                className={classes.title}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {" "}
              </Typography>
            </Box>
          </CardContent>
        </Box>
        <CardActions
          sx={{
            "&:last-child": {
              paddingLeft: 0,
            },
          }}
          className={classes.cardActions}
        >
          <ButtonBase className={classes.btn} onClick={openWish}>
            <Button
              size="small"
              variant="contained"
              endIcon={<VisibilityOutlinedIcon />}
              color="primary"
              disabled={!user}
            >
              {languages[lang].friend.buttons.view}
            </Button>
          </ButtonBase>
          <div className={classes.overlay3}>
            {(user?.sub === friendData?.creator ||
              user?._id === friendData?.creator) && (
              <>
                <div className="more-actions">
                  <Button
                    ref={anchorRef}
                    endIcon={<MoreHoriz />}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    {" "}
                    {languages[lang].friend.buttons.more}
                  </Button>
                  <Popper
                    style={{ zIndex: 3 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="top-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              className="more-actions"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={handleClose}
                                className="quick-action_item"
                              >
                                {(user?.sub === friendData?.creator ||
                                  user?._id === friendData?.creator) && (
                                  <Button
                                    endIcon={<ModeEditOutlined />}
                                    size="small"
                                    color="light"
                                    onClick={togglePermis}
                                  >
                                    {languages[lang].friend.buttons.permissions}
                                  </Button>
                                )}
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                className="quick-action_item"
                              >
                                {(user?.sub === friendData?.creator ||
                                  user?._id === friendData?.creator) && (
                                  <Button
                                    endIcon={<DeleteOutlined />}
                                    size="small"
                                    color="light"
                                    onClick={toggleReset}
                                  >
                                    {languages[lang].friend.buttons.delete}
                                  </Button>
                                )}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </>
            )}
          </div>
        </CardActions>
      </Box>

      <Dialog open={showReset} onClose={handleClose}>
        <DialogTitle> {languages[lang].dialog.delete.friend.title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.desc}>
            {languages[lang].dialog.delete.friend.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button onClick={toggleReset}>
            {" "}
            {languages[lang].dialog.change.permissions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleDeleteSubmit}
          >
            {languages[lang].dialog.delete.friend.delete}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showPermis} onClose={handleClose}>
        <DialogTitle>
          {" "}
          {languages[lang].dialog.change.permissions.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.desc}>
            {languages[lang].dialog.change.permissions.text}
            <Typography component="legend">
              {" "}
              {languages[lang].dialog.change.permissions.label}
            </Typography>
            <StyledRating
              name="customized-color"
              defaultValue={permisData}
              getLabelText={(value) => languages[lang].permissionCodes[value]}
              precision={1}
              icon={<LockPerson fontSize="inherit" />}
              emptyIcon={<LockPerson fontSize="inherit" />}
              onChange={(event, newValue) => {
                setPermisData(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
            />
            {friendData.accessLvl !== null && (
              <Box sx={{ mt: 2 }}>
                {
                  languages[lang].permissionCodes[
                    hover !== -1 ? hover : friendData.accessLvl
                  ]
                }
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button onClick={togglePermis}>
            {languages[lang].dialog.change.permissions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handlePermisSubmit}
          >
            {languages[lang].dialog.change.permissions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleClose}
      />
    </Card>
  );
};
export default Friend;
