import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  media: {
    height: 200,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    maxHeight: 250,
    [theme.breakpoints.down("sm")]: {
      height: 180,
    }
  },
  border: {
    border: "solid",
  },
  fullHeightCard: {
    height: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderRadius: "15px",
    height: "100%",
    position: "relative",
  },
  cardTitle: {
    position: "relative",
  },
  cardBody: {
    height: "100%",
    position: "relative",
  },
  desc: {
    opacity: 0.6,
    padding: "0!important",
    width: "100%",
    wordBreak: "break-word",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overlay: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: "white",
    maxWidth: "40%",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
  },
  overlay2: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      display: "flex",
    },
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 2, 0),
    color: "#fff!important",

  },
  title: {
    padding: theme.spacing(0, 2),
    color: "#fff!important",
    maxWidth: "calc(100% - 20px)",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "60px",
    }
  },
  cardActions: {
    // padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "flex",
    textAlign: "initial",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "auto",
  },
}));
