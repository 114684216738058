import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";

import {
    Button,
    Stack,
    TextField,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Container,
    Grid,
    IconButton,
} from "@mui/material";

import {
    Key,
    Twitter,
    EmailOutlined,
    FacebookOutlined,
    IosShareOutlined,
} from "@mui/icons-material";

import { useNavigate, useParams } from "react-router-dom";
import copyText from "../ErrorScreens/helpers/copy";
import constants from "../../constants/constants";
import Toast from "../toast/Toast";
import { TwitterNew, FacebookNew } from './decor/icons'

const toastConf = {
    open: false,
    severity: "error",
    message: "Error",
};
const ShareLink = ({ type, id, shareText }) => {
    const [toast, setToast] = useState(toastConf);
    const user = JSON.parse(localStorage.getItem(constants.wishProfile));
    const classes = useStyles();
    const navigate = useNavigate();
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setToast({ ...toast, open: false });
    };

    const twitterLink = () => {
        window.open(
            `https://twitter.com/intent/tweet?text=I%20Have%20a%20wish!Checkout%20it%20out%20at%20https://wish-u.app`
        );

    };

    const emailLink = () => {
        window.open(
            `mailto:email@example.com?subject=I have a wish&body=Checkout what I wish this year at https://wish-u.app`
        );
    };
    const fBlink = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=https://wish-u.app`
        );
    };

    const copyLink = () => {
        copyText(``);

        setToast({
            open: true,
            severity: "success",
            message: `The link https://wish-u.app is copied to your clipboard`,

        });
    }

    return (
        <div className={classes.paper}>
            <List component="nav" aria-label="mailbox folders">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                    className={classes.mainContainer}
                >
                    <Grid item xs={6} md={3}>
                        <ListItem className="share-list-item">

                            <Button
                                variant="link"
                                id="myInput"
                                startIcon={<EmailOutlined />}
                                className="share-list-btn"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    emailLink();
                                }}
                            >
                                Email
              </Button>
                        </ListItem>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ListItem className="share-list-item">

                            <Button
                                variant="link"
                                id="myInput"
                                startIcon={<FacebookNew />}
                                className="share-list-btn"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    fBlink();
                                }}
                            >
                                Facebook
              </Button>
                        </ListItem>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ListItem className="share-list-item">

                            <Button
                                variant="link"
                                startIcon={<TwitterNew />}
                                className="share-list-btn"
                                id="myInput"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    twitterLink();
                                }}
                            >
                                Twitter / X
              </Button>
                        </ListItem>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ListItem className="share-list-item">

                            <Button
                                variant="link"
                                className="share-list-btn"
                                id="myInput"
                                size="small"
                                startIcon={<IosShareOutlined />}
                                color="primary"
                                onClick={() => {
                                    copyLink()

                                }}
                            >
                                Copy link
              </Button>
                        </ListItem>
                    </Grid>
                </Grid>
            </List>
            <Toast

                open={toast.open}
                message={toast.message}
                severity={toast.severity}
                onClose={handleClose}
            />


        </div>
    );
};
export default ShareLink;
