import * as React from "react";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";
import constants from "../../constants/constants";
import { useSelector } from "react-redux";
import banner from "../../images/banner.jpg";
import { Checklist } from "@mui/icons-material";
import { Grid, Button, Box, Typography } from "@mui/material";
import { languages } from "../translations";
import Clock from "../animations/clock";
import { Link } from "react-router-dom";
const EventBanner = () => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const navigate = useNavigate();
  const classes = useStyles();
  const lang = useSelector((state) => state.auth.lang);
  let deadline = "December, 25, 2024";
  const redirectHome = () => {
    if (user) {
      navigate("/wishboard");
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div className="banner-bg">
        <img src={banner} alt="avocado" className="banner-image" />{" "}
      </div>
      <div className="banner-content">
        <Typography className={`${classes.preTitle} ${classes.preTitleDark} `}>
          {" "}
        </Typography>
        <Typography variant="h4" className={classes.titleMain}>
          {languages[lang].holidays.christmas}
          <span className={classes.titleMain}>
            {" "}
            {languages[lang].holidays.tech.in}
          </span>
        </Typography>
        <Clock deadline={deadline} />
        {!user && (
          <Box mt={3}>
            <Button
              component={Link}
              to="/wishboard"
              variant="contained"
              color="primary"
              className={classes.actionLink}
              endIcon={<Checklist />}
            >
              {" "}
              {languages[lang].home.createAccount}
            </Button>
          </Box>
        )}
      </div>
    </>
  );
};
export default EventBanner;
