import React, { useEffect } from "react";
import Friend from "./Friend/Friend";

import { Grid, CircularProgress, Typography, Box } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading/loading";
import { useNavigate } from "react-router-dom";
const FriendsList = ({ setCurrentId, page }) => {
  const classes = useStyles();
  const { friends, invites } = useSelector((state) => state.user);

  const isLoading = useSelector((state) => state.user.isLoading);
  const navigate = useNavigate();

  return isLoading ? (
    <Loading />
  ) : (
      <>
        <Grid className={classes.container} container>

          {invites &&
            invites.map((invite) => (
              <>

                <Typography className={classes.row} variant="h4">
                  Invites
        </Typography>
                <Grid className={classes.row} key={invite._id} item xs={12}>
                  <Friend friendData={invite} setCurrentId={setCurrentId} />
                </Grid>

              </>
            ))}
          {/* 
        <Typography variant="h4" className={classes.row}>
          Friends
        </Typography> */}
        </Grid>
      </>
    );
};
export default FriendsList;
