import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import FriendsGrid from "./FriendsGrid/FriendsGrid";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const FriendsTab = () => {
  const query = useQuery();
  const page = query.get("page") || 1;
  const [currentId, setCurrentId] = useState(null);
  const { error } = useSelector((state) => state.user);

  return (
    <>
      <Box className="friend-page">
        <FriendsGrid setCurrentId={setCurrentId} />
      </Box>
    </>
  );
};
export default FriendsTab;
