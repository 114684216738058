import React from "react";
import WishList from "./WishList/WishList";

import { Grid } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading/loading";
import { useNavigate } from "react-router-dom";
import NoResults from "../../ErrorScreens/NoResults/NoResults";
import { getWishLists } from "../../../actions/wishes";
import { languages } from "../../translations";
const WishListsGrid = ({ setCurrentListId, page, edit, fireConf }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { wishLists } = useSelector((state) => state.user);

  const { numberOfListPages } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.wish.isLoading);
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.auth);
  if (wishLists && !wishLists.length && !isLoading) {
    if (!wishLists.length && numberOfListPages > 1) {
      navigate(`/wishboard?wlpage=${numberOfListPages}`);
    } else if (!wishLists.length && numberOfListPages === 1) {
      dispatch(getWishLists(numberOfListPages));
    }
    return (
      <NoResults
        message={{
          title: `${languages[lang].errors.noresults.wishlists.title} `,
          button: "none",
          desc: `${languages[lang].errors.noresults.wishlists.desc} `,
        
        }}
      />
    );
  }

  return isLoading ? (
    <Loading />
  ) : (
      <Grid
        className={classes.container}
        container
        // alignItems="stretch"
        spacing={3}
      >
        {wishLists?.map((wishList) => (
          <Grid key={wishList._id} item xs={12} md={6} lg={4}>
            <WishList
              wishData={wishList}
              setCurrentListId={setCurrentListId}
              page={page}
              edit={edit}
              fire={fireConf}
            />
          </Grid>
        ))}
      </Grid>
    );
};
export default WishListsGrid;
