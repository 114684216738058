import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "../translations";
const Clock = ({ deadline }) => {
  const classes = useStyles();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [customInterval, setCustomInterval] = useState();
  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };
  const { lang } = useSelector((state) => state.auth);

  const getTimeUntil = (deadline) => {
    const time = Date.parse(new Date(deadline)) - Date.parse(new Date());

    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    if (deadline) {
      if (customInterval) {
        clearInterval(customInterval);
      }
      setCustomInterval(setInterval(() => getTimeUntil(deadline), 1000));
      return () => getTimeUntil(deadline);
    }
    return;
  }, [deadline]);

  return (
    <div className="dates-grid">
      <div className="dates-item">
        <Typography variant="h2" className={classes.date}>
          {leading0(days)}
        </Typography>
        <Typography variant="p" className={classes.label}>
        {languages[lang].account.countdown.days}
        </Typography>
      </div>
      <div className="dates-item">
        <Typography variant="h2" className={classes.date}>
          {leading0(hours)}
        </Typography>
        <Typography variant="p" className={classes.label}>
        {languages[lang].account.countdown.hours}
        </Typography>
      </div>
      <div className="dates-item">
        <Typography variant="h2" className={classes.date}>
          {leading0(minutes)}
        </Typography>
        <Typography variant="p" className={classes.label}>
        {languages[lang].account.countdown.min}
        </Typography>
      </div>
      <div className="dates-item">
        <Typography variant="h2" className={classes.date}>
          {leading0(seconds)}
        </Typography>
        <Typography variant="p" className={classes.label}>
        {languages[lang].account.countdown.sec}
        </Typography>
      </div>
    </div>
  );
};

export default Clock;
