import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import userReducer from "./user";
import usersReducer from "./users";
import wishReducer from "./wishes";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    allUsers: usersReducer,
    wish: wishReducer,
  },
});
