import React from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';
import {
  Card,
  cardActions,
  CardContent,
  CardMedia,
  Button,
  Box,
  Typography,
  CardActions,
  ButtonBase,
} from "@mui/material";
import {
  ThumbUpAlt,
  Delete,
  MoreHoriz,
  DeleteOutlined,
  FavoriteBorder,
} from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch,useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import constants from "../../../../constants/constants";

import { acceptReq, declineReq } from "../../../../actions/user";

const Friend = ({ friendData, setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const { lang } = useSelector((state) => state.auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openWish = () => {
    navigate(`/wish/${friendData._id}`);
  };
  moment.locale(lang === "ua" ? "uk" : lang);
  const accept = () => {
    dispatch(acceptReq(friendData._id));
  };
  const decline = () => {
    dispatch(declineReq(friendData._id));
  };
  // console.log(friendData);
  return (
    <>
      <Box className={classes.friendCard}>
        <div className="avatar --lg">
          <CardMedia
            component="img"
            className={classes.media}
            image={
              friendData?.sender?.avatar ||
              "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
            }
            // src={userData.selectedFile}
            title={friendData.firstName}
          />
        </div>
        <Box className={classes.friendData}>
          <Box className={classes.friendsName}>
            <Typography variant="body">
              {friendData?.sender?.firstName}{" "}
            </Typography>
            <Typography variant="body">
              {" "}
              {friendData?.sender?.lastName}
            </Typography>
          </Box>
          {/* <Box className={classes.friendsDesc}></Box> */}
          <Box className={classes.btnRow}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={!user}
              onClick={accept}
            >
              Accept
            </Button>

            <Button
              size="small"
              color="primary"
              disabled={!user}
              onClick={accept}
            >
              View
            </Button>

            <Button
              size="small"
              color="primary"
              disabled={!user}
              onClick={decline}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Friend;
