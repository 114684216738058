import React, { useEffect } from "react";
import User from "./User/User";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { searchUsers } from "../../../actions/user";
import Loading from "../../Loading/loading";
import NoResults from "../../ErrorScreens/NoResults/NoResults";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SearchGrid = ({ setCurrentId }) => {
  const classes = useStyles();
  const users = useSelector((state) => state.allUsers.users);
  const isLoading = useSelector((state) => state.allUsers.isLoading);
  const dispatch = useDispatch();
  const query = useQuery();
  const searchQuery = query.get("searchQuery");

  useEffect(() => {
    dispatch(
      searchUsers({
        search: searchQuery,
      })
    );
  }, [searchQuery]);
  if (users && !users.length && !isLoading) {
    return <NoResults />;
  }

  return isLoading ? (
    <Loading />
  ) : (
      <>
        <Grid
          className={classes.container}
          container
          alignItems="stretch"
          spacing={3}
        >
          {users ? (
            users?.map((user) => (
              <Grid key={user._id} item xs={12} md={6}>
                <User UserData={user} setCurrentId={setCurrentId} />
              </Grid>
            ))
          ) : (
              <>
                <NoResults />
              </>
            )}
        </Grid>
      </>
    );
};
export default SearchGrid;
