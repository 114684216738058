import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  media: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: "4px solid #f1ce89",
    borderRadius: 10,
    objectPosition: "center",
  },
  border: {
    border: "solid",
  },
  friendCard: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    flexDirection: "row",
    gap: theme.spacing(0, 1),
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      minHeight: "160px",
      alignItems: "center",
      gridTemplateColumns: "60px 1fr",
    },
  },

  accessLvl: {
    display: "grid",
    gridTemplateColumns: "180px 1fr",
    flexDirection: "row",
    gap: theme.spacing(1, 1),
    marginBlock: theme.spacing(1),
    alignItems: "flex-start",
  },
  accessLabel: {
    padding: theme.spacing(0.5, 2),
    color: theme.palette.neutral.light,
    backgroundColor: theme.palette.neutral.main,
    borderRadius: 90,
    fontFamily: `${theme.typography.fonts.fontSecondary}!important`,
  },
  fullHeightCard: {
    height: "100%",
  },
  ava: {
    gridRow: "1/3",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      paddingTop: theme.spacing(2),
    }
  },
  friendsName: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      // paddingRight: theme.spacing(2),
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "calc(100% - 60px)",
    },
  },
  friendsDesc: { paddingLeft: theme.spacing(1), },

  btnRow: {
    marginTop: theme.spacing(1),
    width: "100%",

  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    padding: theme.spacing(2, 2, 1),
    margin: theme.spacing(0, 0, 2),
    position: "relative",
    // boxShadow: "none!important",
    // backgroundColor: "transparent!important",
  },
  bio: {
    color: theme.palette.neutral.light,

  },
  title: {
    padding: theme.spacing(0, 1, 0, 0),
    color: theme.palette.neutral.light,
  },

  overlay: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      left: theme.spacing(1),
      display: "flex",
    },
  },
  overlay2: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      display: "flex",
    },
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  },

  cardActions: {
    padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "flex",
    textAlign: "initial",
    flexDirection: "column",
    alignItems: "flex-start",
  }
}));