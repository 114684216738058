import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  footer: {
    borderTop: "1px solid rgb(241 206 137 / 50%)",
    background: "linear-gradient(0deg, #071f25, transparent)",
    maxWidth: "100vw",
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0),
    },
  },
  social: {
    width: 48,
    height: 48,
    color: "rgb(241 206 137 / 90%)",
  },
  copyright: {
    width: "100%",
    display: "block",
    textAlign: "center",
    color: "rgb(241 206 137 / 90%)",
  },
}));
