import { createSlice } from "@reduxjs/toolkit";
import {
  getWishboard,
  fetchUserDetails,
  updateDetails,
  fetchFriends,
  confirmEmail,
  declineReq,
  acceptReq,
  removeFriend,
  bookWish,
  getWishListData,
  updatePermit,
} from "../actions/user";
import {
  deleteWish,
  deleteWishList,
  updateWish,
  updateWishList,
} from "../actions/wishes";
import constants from "../constants/constants";
import { createWishList, getWishLists } from "../actions/wishes";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  bio: "",
  avatar: "",
  sex: "",
  friends: [],
  bookedWishes: [],
  wishLists: null,
  currentList: [],
  currentListPage: 0,
  numberOfListPages: 0,
  invites: [],
  totalInvites: 0,
  address: "",
  birthDate: "",
  city: "",
  country: "",
  postCode: "",
  phoneNumber: "",
  isLoading: true,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    //Wishlists booking wish
    bookWishSlice: (state, action) => {
      let obj = { ...action.payload.wish };
      if (obj.booked === action.payload.id) {
        obj.booked = null;
      } else if (obj.booked === null) {
        obj.booked = action.payload.id;
      }

      state.currentList.wishes = state.currentList.wishes.map((wish) =>
        wish._id === obj._id ? obj : wish
      );
    },
    //Wishlists completting wish
    completeWishSlice: (state, action) => {
      state.currentList.wishes = state.currentList.wishes.filter(
        (wish) => wish._id !== action.payload
      );
    },
    deleteWishSlice: (state, action) => {
      let obj = { ...action.payload.deleteId };

      state.currentList.wishes = state.currentList.wishes.filter(
        (wish) => wish._id !== obj._id
      );
    },
    endLoading: (state, action) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    //Get Wishboard
    builder.addCase(getWishboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWishboard.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        state.error = action.payload.error;
        localStorage.clear();
      }

      state.isLoading = false;
      state.bookedWishes = action.payload.bookedWishes;
      state.friends = action.payload.friends;
      state.invites = action.payload.invites;
      state.wishLists = action.payload.wishLists;
      state.currentListPage = action.payload.currentListPage;
      state.numberOfListPages = action.payload.numberOfListPages;
      state.totalInvites = action.payload.totalInvites;
      state.error = null;
    });

    builder.addCase(getWishboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isLoading = false;
      state.error = action.error.message;
    });
    // Get wishlists
    builder.addCase(createWishList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createWishList.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        state.error = action.payload.error;
        localStorage.clear();
      }
      state.isLoading = false;

      state.numberOfListPages = action.payload.numberOfPages;
      state.wishLists = action.payload.wishLists;
      state.error = null;
    });
    builder.addCase(createWishList.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isLoading = false;
      state.error = action.error.message;
    });
    // Update wishlist
    builder.addCase(updateWishList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateWishList.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      state.isLoading = false;
      state.wishLists = state.wishLists.map((wishlist) =>
        wishlist._id === action.payload.updatedWishlist._id
          ? action.payload.updatedWishlist
          : wishlist
      );
      state.error = null;
    });
    builder.addCase(updateWishList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //Get User
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
      }
      localStorage.setItem(
        constants.wishProfile,
        JSON.stringify({ ...action.payload })
      );
      state.isLoading = false;

      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.avatar = action.payload.avatar;
      state.address = action.payload.address;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.bio = action.payload.bio;
      state.birthDate = action.payload.birthDate;
      state.sex = action.payload.sex;
      state.bookedWishes = action.payload.bookedWishes;

      state.city = action.payload.city;
      state.country = action.payload.country;
      state.postCode = action.payload.postCode;
      state.phoneNumber = action.payload.phoneNumber;
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isLoading = false;
      state.error = action.error.message;
    });
    //Get Single wishlist
    builder.addCase(getWishListData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWishListData.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      if (action.payload.error) {
        if (action.payload.error.response.status === 401) {
          state.error = action.payload.error.response.statusText;
        }
      }
      state.currentList = action.payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getWishListData.rejected, (state, action) => {
      state.error = action.error.message;
    });
    //Get friends
    builder.addCase(fetchFriends.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFriends.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
      }
      state.isLoading = false;
      state.avatar = action.payload.avatar;
      state.friends = action.payload.friends;
    });
    builder.addCase(fetchFriends.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isLoading = false;
      state.error = action.error.message;
    });
    // Update User information
    builder.addCase(updateDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateDetails.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
      }
      state.isLoading = false;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.bio = action.payload.bio;
      state.address = action.payload.address;
      state.avatar = action.payload.avatar;

      state.birthDate = action.payload.birthDate;
      state.sex = action.payload.sex;
      state.bookedWishes = action.payload.bookedWishes;
      state.city = action.payload.city;
      state.country = action.payload.country;
      state.postCode = action.payload.postCode;
      state.phoneNumber = action.payload.phoneNumber;
    });
    builder.addCase(updateDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // confirm email
    builder.addCase(confirmEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(confirmEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("success", action.payload);
    });
    builder.addCase(confirmEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // remove user
    builder.addCase(removeFriend.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeFriend.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
      }
      state.isLoading = false;

      state.friends = state.friends.filter(
        (friend) => friend.user._id !== action.payload.id
      );
    });
    builder.addCase(removeFriend.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // Add friend
    builder.addCase(acceptReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(acceptReq.fulfilled, (state, action) => {
      if (action.payload.error === "JWT expired") {
        localStorage.clear();
      }
      state.isLoading = false;

      state.invites = state.invites.filter(
        (invite) => invite._id !== action.payload.id
      );
      state.friends.push(action.payload);
    });
    builder.addCase(acceptReq.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // decline user
    builder.addCase(declineReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(declineReq.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("success", action.payload.id);
      state.invites = state.invites.filter(
        (invite) => invite._id !== action.payload.id
      );
    });
    builder.addCase(declineReq.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // update wishlists wish
    builder.addCase(updateWish.fulfilled, (state, action) => {
      state.isLoading = false;

      state.wishLists = action.payload.wishlists;
    });
    //Delete wishlist

    builder.addCase(deleteWishList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWishList.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.error && action.payload.error === "JWT expired") {
        localStorage.clear();
        state.error = action.payload.error;
      }
      state.wishLists = state.wishLists.filter(
        (wishlist) => wishlist._id !== action.payload.id
      );

      state.numberOfListPages = action.payload.numberOfListPages;
      state.error = null;
    });
    builder.addCase(deleteWishList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //
    builder.addCase(getWishLists.fulfilled, (state, action) => {
      state.wishLists = action.payload.wishLists;
      state.numberOfListPages = action.payload.numberOfListPages;
      state.currentListPage = action.payload.currentListPage;
      state.error = null;
    });
    // Un book wish
    builder.addCase(bookWish.fulfilled, (state, action) => {
      state.isLoading = false;

      state.bookedWishes = state.bookedWishes.filter(
        (wish) => wish._id !== action.payload.wish._id
      );
    });

    // Change permit
    builder.addCase(updatePermit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePermit.fulfilled, (state, action) => {
      state.isLoading = false;
      for (let i = 0; i < state.friends.length; i++) {
        const element = state.friends[i];
        if (element.user._id === action.payload.user) {
          element.accessLvl = action.payload.permission;
        }
      }
    });
    builder.addCase(updatePermit.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // builder.addCase(deleteWishList.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   if (action.payload.error && action.payload.error === "JWT expired") {
    //     localStorage.clear();
    //     state.error = action.payload.error;
    //   }
    //   state.currentList.wishes = state.currentList.wishes.filter(
    //     (wish) => wish._id !== action.payload.id
    //   );

    //   state.error = null;
    // });
  },
});

// Action creators are generated for each case reducer function
export const { bookWishSlice, deleteWishSlice, completeWishSlice } =
  userSlice.actions;

export default userSlice.reducer;
