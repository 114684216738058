/** @format */

import React, { useEffect, useState } from "react";
import useStyles from "./styles";

import { Grid, Grow, Container, Box, Divider } from "@mui/material";
import ArchiveWish from "./ArchiveWish/ArchiveWish";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/loading";
import Toast from "../toast/Toast";
import { getArchive } from "../../actions/wishes";
import constants from "../../constants/constants";

import Navbar from "../Navbar/Navbar";

import Footer from "../Footer/Footer";
import NoResults from "../ErrorScreens/NoResults/NoResults";
import PageTitle from "../PageTitle/PageTitle";
import { languages } from "../translations";
// Notifications
const toastConf = {
  open: false,
  severity: "error",
  message: "Error",
};
const ArchivePage = () => {
  
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toast, setToast] = useState(toastConf);
  const [birthday, setDeadline] = useState();
  const [id, setId] = useState();
  const [showReset, setshowReset] = useState(false);
  const lang = useSelector((state) => state.auth.lang);
  const toggleReset = () => {
    setshowReset((prevShowReset) => !prevShowReset);
  };

  const { isLoading, archive } = useSelector((state) => state.wish);
  let user = JSON.parse(localStorage.getItem(constants.wishProfile));

  const userDetails = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleResetSubmit = (e) => {
    e.preventDefault();
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };
  useEffect(() => {
    dispatch(getArchive());
  }, []);
  const EmptyArchive = () => {
    if (archive && !archive.length && !isLoading) {
      return (
        <NoResults
          message={{
            title: "No Received Wishes yet",
            button: "none",
            desc: "Here you will see the wishes that you have marked as received.",
          }}
        />
      );
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {" "}
      <Navbar />
      <Grow in>
        <Container maxWidth="xl" className="containerH">
          <PageTitle title={`${languages[lang].archive.title}`} subtitle={`${languages[lang].archive.subtitle}`} />
          {/* <Divider style={{ marginBottom: 15 }} /> */}
          <EmptyArchive />
          <Grid
            className={classes.container}
            container
            // alignItems="stretch"
            spacing={3}
          >
            {archive?.map((wish) => (
              <Grid key={wish._id} item xs={12} sm={3}>
                <ArchiveWish wishData={wish} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Grow>
    </>
  );
};

export default ArchivePage;
