import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Toolbar,
  Grid,
  Container,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import decode from "jwt-decode";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo/Logo";

import FriendSearch from "../Friends/FriendsSearch";

import { logout } from "../../actions/auth";

import constants from "../../constants/constants";
import {
  Logout,
  FavoriteBorder,
  CardGiftcardOutlined,
  PermIdentityOutlined,
  HelpOutlineOutlined,
} from "@mui/icons-material";

import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { languages } from "../translations";
import { changeLang } from "../../actions/user";
import { changeLangReducer } from "../../reducers/auth";
const pages = ["Wishboard", "Friends List"];

const Navbar = () => {
  const classes = useStyles();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(constants.wishProfile))
  );
  const { firstName, lastName, avatar } = useSelector((state) => state.user);
  const authData = useSelector((state) => state.auth.authData);

  const lang = useSelector((state) => state.auth.lang);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const [scrollPosition, setPosition] = useState(0);
  // useLayoutEffect(() => {
  //   function updatePosition() {
  //     setPosition(window.pageYOffset);
  //   }
  //   window.addEventListener('scroll', updatePosition);
  //   updatePosition();
  //   return () => window.removeEventListener('scroll', updatePosition);
  // }, []);

  // const header = document.querySelector(".js-header")
  // if (header) {
  //   const header_height = header.offsetHeight;
  //   const add_class_on_scroll = () => header.classList.add("fade-in")
  //   const remove_class_on_scroll = () => header.classList.remove("fade-in")

  //   if (scrollPosition >= header_height) { add_class_on_scroll() }
  //   else { remove_class_on_scroll() }
  // }
  const changeLanguage = (e) => {
    if (user && lang) {
      dispatch(changeLang({ lang: e.target.value }));
    } else {
      dispatch(changeLangReducer({ lang: e.target.value }));
    }
  };
  const logoutFunc = () => {
    try {
      dispatch(logout()).then((result) => {
        navigate("/auth");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const redirectHome = () => {
    navigate("/");
  };
  const redirectDetails = () => {
    navigate("/details");
  };

  // MUI
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <AppBar
      className={classes.appBar}
      position="sticky"
      color="inherit"
      sx={{ boxShadow: 0, p: 0 }}
    >
      <Container maxWidth="xl" className="js-header" id="watchScroll--header">
        <Toolbar disableGutters className={classes.toolbar}>
          <Box
            className={classes.menuWrapper}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            {user && (
              <>
                {/* <FriendSearch /> */}
                <IconButton
                  size="large"
                  className={classes.menuMobile}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
   {!user && (
<FormControl className="language-wrapper">
                {/* <InputLabel id="demo-simple-select-label">Lang</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  label="Age"
                  variant="standard"
                  className="language"
                  onChange={changeLanguage}
                >
                  <MenuItem value="en">EN</MenuItem>
                  <MenuItem value="fr">FR</MenuItem>
                  <MenuItem value="ua">UA</MenuItem>
                </Select>
              </FormControl>
                 )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              className={`${classes.menu} mobile-menu`}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >


              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  variant="text"
                  color="light"
                  className={classes.wishboard}
                  startIcon={<FavoriteBorder />}
                  onClick={() => {
                    navigate("/wishboard");
                  }}
                >
                  {" "}
                  {languages[lang].navbar.wishboard}
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseUserMenu}>
                <Button
                  variant="text"
                  color="light"
                  className={classes.logout}
                  onClick={() => {
                    navigate("/archive");
                  }}
                  startIcon={<CardGiftcardOutlined />}
                >
                  {" "}
                  {languages[lang].navbar.archive}
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  variant="text"
                  color="light"
                  className={classes.wishboard}
                  startIcon={<HelpOutlineOutlined />}
                  onClick={() => {
                    navigate("/faq");
                  }}
                >
                  {" "}
                  {languages[lang].navbar.faq}
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
              <FormControl className="language-wrapper">
                {/* <InputLabel id="demo-simple-select-label">Lang</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  label="Age"
                  variant="standard"
                  className="language"
                  onChange={changeLanguage}
                >
                  <MenuItem value="en">EN</MenuItem>
                  <MenuItem value="fr">FR</MenuItem>
                  <MenuItem value="ua">UA</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            </Menu>
            
          </Box>

          <div className={classes.brandContainer}>
            <Logo />
          </div>
          <Box
            sx={{
              display: { scrollMarginLeft: "none", sx: "none", md: "flex" },
              flexGrow: { md: 1 },
            }}
          />
          <Box className={classes.rightBar}>
            {user && <FriendSearch />}
            <Grid item sx={{ display: { xs: "none", md: "flex" } }}>
              <FormControl className="language-wrapper">
                {/* <InputLabel id="demo-simple-select-label">Lang</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  label="Age"
                  variant="standard"
                  className="language"
                  onChange={changeLanguage}
                >
                  <MenuItem value="en">EN</MenuItem>
                  <MenuItem value="fr">FR</MenuItem>
                  <MenuItem value="ua">UA</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!authData && !user ? (
              <>
                <Button
                  sx={{ display: { xs: "none", md: "flex" } }}
                  component={Link}
                  to="/auth"
                  variant="contained"
                  color="primary"
                >
                  {" "}
                  {languages[lang].navbar.signin}
                </Button>
                <Button
                  sx={{ display: { xs: "flex", md: "none" } }}
                  component={Link}
                  to="/auth"
                  variant="contained"
                  color="primary"
                >
                  {" "}
                  {languages[lang].navbar.login}
                </Button>
              </>
            ) : (
              <div className={classes.profile}>
                <Grid container spacing={1}>
                  <Grid item sx={{ display: { xs: "none", md: "flex" } }}>
                    <Button
                      variant="text"
                      color="light"
                      className={`${classes.wishboard} --js-wishboard-page`}
                      startIcon={<FavoriteBorder />}
                      onClick={() => {
                        navigate("/wishboard");
                      }}
                    >
                      {" "}
                      {languages[lang].navbar.wishboard}
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <div className="avatar">
                            <Avatar
                              variant="rounded"
                              className={classes.purple}
                              alt={user?.name}
                              src={avatar ? avatar : user?.avatar}
                            >
                              {user?.firstName?.charAt(0)}
                              {/* {user?.name} */}
                            </Avatar>
                          </div>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        className="more-actions mobile-menu"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Button
                            variant="text"
                            className={classes.logout}
                            onClick={redirectDetails}
                            startIcon={<PermIdentityOutlined />}
                          >
                            {languages[lang].navbar.account}
                          </Button>
                        </MenuItem>

                        <MenuItem onClick={handleCloseUserMenu}>
                          <Button
                            variant="text"
                            className={classes.logout}
                            onClick={() => {
                              navigate("/archive");
                            }}
                            startIcon={<CardGiftcardOutlined />}
                          >
                            {" "}
                            {languages[lang].navbar.archive}
                          </Button>
                        </MenuItem>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Button
                            variant="text"
                            className={classes.logout}
                            startIcon={<HelpOutlineOutlined />}
                            onClick={() => {
                              navigate("/faq");
                            }}
                          >
                            {" "}
                            {languages[lang].navbar.faq}
                          </Button>
                        </MenuItem>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Button
                            variant="text"
                            className={classes.logout}
                            onClick={logoutFunc}
                            startIcon={<Logout />}
                          >
                            {" "}
                            {languages[lang].navbar.logout}
                          </Button>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={classes.userName} variant="h6">
                      {/* {user?.name} */}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    // </Box>
  );
};

export default Navbar;
