const en = {
  home: {
    hero: {
      a1: "Your dreams can become a reality",
      a2: "Make a",
      a3: "Wish",
      a4: "Help your favorite people to find a perfect gift for you!",

      make: "Make a wish",
      create: "Create a wishlist",
      choose: "Choose gift",
    },
    howReceive: "How to receive gifts?",
    three: "Three ",
    simple: "simple ",
    steps: " steps",
    s1: {
      title: "Step 1",
      create: "Create free",
      account: "account",
      instr:
        " Create your own wishSpace, where you can add as many wishes as you want.",
      button: "Create a wishlist",
    },
    s2: {
      title: "Step 2",
      create: "Create a",
      wishlist: "wishlist",
      instr:
        "Add a photo, description or link to some product. You can even rate your wishes with the Wishemeter to emphasize, what do you want the most.",
      button: "Make a wish now!",
    },
    s3: {
      title: "Step 3",
      share: "Share your",
      account: "account",
      instr:
        "Help your friends to find the best gift for you. Invite your favourite people to follow you on Wish. Share your profile in your favourite social media or by email.",
      button: "Invite friends!",
    },
    createAccount: "Create an account",
  },
  auth: {
    login: "Login",
    register: "Register",
    or: "or",
    email: "Email",
    password: "Password",
    lost: "Lost password",
    signUp: "Sign Up",
    signIn: "Sign In",
    lastName: "Last name",
    firstName: "First name",
    repeatpassword: "Repeat Password",
    lostpwd: "lost password",
    reset: "Reset",
    cancel: "Cancel",
    checkEmail: "Check your email",
    resetpwd: "Reset password",
    resetinstr:
      "Please, enter email address you've registered with and we will send you instructions via email",
    errors: {
      uppercase:
        "Missing an Uppercase letter! Password should be at least 8 characters, one Uppercase and one lowercase letters, one special character and one digit",
      lowercase:
        "At least one Lowercase! Password should be at least 8 characters, one Uppercase and one lowercase letters, one special character and one digit",
      digit:
        "At least one digit! Password should be at least 8 characters, one Uppercase and one lowercase letters, one special character and one digit",
      special:
        "Missing Special Characters! Password should be at least 8 characters, one Uppercase and one lowercase letters, one special character and one digit",
      shortpwd:
        "Too short! Password should be at least 8 characters, one Uppercase and one lowercase letters, one special character and one digit",
      pwdnotmatch: "Password do not match",
    },
  },
  archive: {
    title: "Archive",
    subtitle: " Wishes that you received",
    buttons: {
      wishagain: "Wish again",
      delete: "Delete",
    },
  },

  navbar: {
    signin: "Sign in",
    logout: "logout",
    faq: "FAQ",
    wishboard: "Wishboard",
    login: "Login",
    archive: "Archive",
    register: "Register",
    account: "Account Details",
    settings: "Open settings",
  },
  search: {
    placeholder: "Search for a friend",
    button: "Search",
    clear: " Clear",
    title: "Search results ",
  },
  searchUsers: {
    pending: "Pending request",
    invite: "Invite",
    friends: "Is Friend",
    viewProfile: "View profile",
    returnToSearch: "Return to search",
  },
  wishboard: {
    tabs: {
      wishes: "My wishes",
      wishlists: "Wishlist",
      bookedwish: "Booked wishes",
      friends: "Friends ",
    },
    buttons: {
      add: {
        wish: "Make a wish",
        wishlist: "Add wishlist",
      },
    },
    titles: {
      friends: "Invite more Friends",
    },
  },

  wishcard: {
    buttons: {
      share: "Share",
      more: "More",
      delete: "Delete",
      received: "Mark as received",
      edit: "Edit",
    },
    tooltip: {
      book: "By booking a wish you're confirming that you're planning to gift that.",
    },
    book: "Book",
    login: "Log in to see the status",
    youBooked: "You booked",
    notYouBooked: "Someone booked",
  },
  wishDetails: {
    tooltip: {
      book: "By booking a wish you're confirming that you're planning to gift that.",
    },
    book: "Book",
    login: "Log in to see the status",
    youBooked: "You booked",
    notYouBooked: "Someone booked",
    return: "Return to all wishes",
    wishmeter: "Wishmeter",
    details: "Wish details",
    openLink: "Open",
    link: "Link",
    createdBy: "Created by",
  },
  wishlist: {
    details: {
      back: "Return to wishboard",
      wishes: "Wishes",
      special: "Limited",
      created: "Created by",
      updated: "Last Update",
      desc: "	Wishlist details",
      event: "Event",
      delete: "Delete",
      received: "Mark as received",
      link: "Link",
    },
    tooltip: {
      book: "By booking a wish you're confirming that you're planning to gift that.",
    },
    book: "Book",
    login: "Log in to see the status",
    youBooked: "You booked",
    notYouBooked: "Someone booked",
  },
  wishlistForm: {
    title: {
      name: "Title of your Wish list",
      creating: "Creating",
      editing: "Editing",
      wishlist: "a wish list",
    },
    tootlTips: {
      eventTooltip:
        "You can add a date to specify when you want to receive the gifts",
      accessTooltip:
        "You can select, who will be able to access your wishlist. To limit the access select some relationship level or to show it only to few people",
    },
    selectWishes: "Select wishes",
    coverImage: "Select cover image",
    upload: "Upload",
    description: "Please, add some description",
    shortDescription: "description",
    addWishes: "Add Wishes",
    eventDate: "Do you have en event date?",
    eventCalendar: "When do you want to receive a gift",
    access: "Select who can access wish list",
    limitAccess: "Limit to specific user's?",
    save: "Save wish list",
    create: "Create wish list",
    confirmModal: "Confirm",
    clear: "clear",
  },
  wishlistcard: {
    buttons: {
      share: "Share",
      more: "More",
      delete: "Delete",
      received: "Mark as received",
      edit: "Edit",
    },
    elements: {
      wishes: "Wishes",
      special: "Limited",
    },
  },
  customIcons: [
    "How bad you want it?",
    "Can wait",
    "I'd love to have it",
    "Next holliday ",
    "As soon as possible",
    "I won't belive until I see it",
  ],
  permissionCodes: [
    "Who can access it?",
    "Public",
    "Friends",
    "Family",
    "Partner",
    "TOP SECRET!",
  ],
  wishForm: {
    title: {
      name: "Title of your Wish",
      creating: "Creating",
      editing: "Editing",
      wish: "a wish",
    },
    tootlTips: {
      eventTooltip:
        "You can add a date to specify when you want to receive the gifts",
      accessTooltip:
        "You can select, who will be able to access your wish. To limit the access select some relationship level or to show it only to few people",
    },
    coverImage: "Do you have an idea how it looks?",
    upload: "Upload",
    description: "Please, add some description",
    shortDescription: "description",
    addToWishlists: " Would you like to add it to existing wishlists?",
    selectWishlist: "Select wishlists",
    dreamscale: "Select priority",
    access: "Select who can access it",
    link: "Link to the item",
    save: "Save wish",
    create: "Create wish",
    confirmModal: "Confirm",
    clear: "clear",
    select: "Select",
  },

  account: {
    title: "About You",
    account: "Your account",
    resetPass: "Reset password",
    resetMessage:
      "	Please, enter the email address you've registered with and we will send you instructions via email",
    logout: "Logout",
    enterEmail: "Enter email",
    countdown: {
      birthday: "Your Birthday ",
      in: "in",
      days: "days",
      hours: "hours",
      min: "min",
      sec: "sec",
    },
    form: {
      first: "First Name",
      last: "Last Name",
      phone: "Phone number",
      email: "Email",
      country: "Country",
      city: "City",
      postal: "Postal Code",
      bio: "About Me",
      adress: "Address",
      birthday: "Your Birthday",
      reset: "Reset",
      upload: "Upload",
      save: "Save",
    },
  },
  user: {
    birthday: "Birthday ",
    in: "in",
    share: "Share profile",
    invite: "Invite",
    pending: "Pending",
    login: "Please log in to add a friend",
    wishlistsTitle: "Wish lists",
    wishTitle: "Wishes",
    event: "Event",
    yourFriend: "Your friend",
    ownPage: "This is how your page is presented to other.",
  },
  errors: {
    noresults: {
      bookedWishes: {
        title: "No Booked Wishes yet",
        desc: "Book your first wish now!",
      },
      wishes: {
        title: "No Wishes yet",
        desc: "Create your first wish now!",
      },
      wishlists: {
        title: "No Wishlists yet",
        desc: "Create your wish list now!",
      },
    },
  },

  friend: {
    buttons: {
      view: "View",
      more: "More",
      permissions: " Change permissions",
      delete: "Delete",
    },
  },
  holidays: {
    christmas: "Christmas",
    easter: "Easter",
    tech: {
      in: "in",
    },
  },
  share: {
    shareWish: {
      title: "your Wish",
    },
  },
  dialog: {
    delete: {
      wish: {
        title: "Delete wish",
        text: "You are going to delete your wish. We must warn you that if you proceed you can't revert this process and all people who might have subscribed to your wish will lose access to it permanently",
        cancel: "Cancel",
        delete: "Delete",
      },
      wishlist: {
        title: "Delete wishlist",
        text: "You are going to delete your wishlist. We must warn you that you if you proceed you can't revert this proccess.",
        cancel: "Cancel",
        delete: "Delete",
      },
      friend: {
        title: "Removing a friend",
        text: "You about to remove friend from your list. Are you sure you want to proceed?",
        cancel: "Cancel",
        delete: "Delete friend",
      },
    },
    change: {
      permissions: {
        title: "Change friends permission level",
        text: "You about to change permissions",
        label: "Select priority",
        cancel: "Cancel",
        confirm: "Confirm",
      },
    },
    share: {
      wish: {
        title: "Share Your Wish",
        close: "Close",
      },
      widget: {
        wish: {
          copy: "Copy link",
        },
      },
      messages: {
        link: "The link https://wish-u.app/",
        linkSuccess: "is copied to your clipboard",
      },
    },
  },
  faq: {
    title: "Frequently Asked Questions",
    subtitle:
      "We are here to help you find the answers to the questions we hear most often. ",
    contact: "    If you dont find the answers to your question",
    contactBtn: "               Contact Us",
  },
  message: {
    title: "Sorry, we can't find someone you looking for!",
    desc: "Please, try to change the request. Or send this link to someone you would like to meet in Wish",
    friends: {
      title: "No Friends yet",
      desc: "If your frieds haven't joined the Wish yet, please, you can invite them via link",
      button: "copy",
    },
  },
};
export default en;
