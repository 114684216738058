import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "240px",
  },
  wishMedia: {
    width: 90,
    height: 90,
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      width: 70,
      height: 70,
    },
  },
  cardWrapper: {
    padding: theme.spacing(4, 6),
    overflow: "hidden",
    position: "relative",
    marginBlock: theme.spacing(0, 6),
    isolation: "isolate",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2),
    },
  },
  card: {
    display: "flex",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  section: {
    borderRadius: "20px",
    margin: "10px",
    flex: 1,
    order: "-1",
  },
  sectionWr: {
    paddingBlock: theme.spacing(2),
  },
  imageSection: {
    marginLeft: "20px",
    order: "-1",

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  dialogTitle: {
    textAlign: "center",
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },
  commentsOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  commentsInnerContainer: {
    height: "200px",
    overflowY: "auto",
    marginRight: "30px",
  },
  containerMain: {
    minHeight: "calc(100vh - 200px)",
    marginTop: theme.spacing(6),
  },
  eventTitle: {
    display: "flex",
    gap: theme.spacing(0, 1),
    alignItems: "center",
  },
  eventGrid: {
    display: "flex",
    gap: theme.spacing(0, 2),
    alignItems: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },

    justifyContent: "flex-start",
  },

  wishBlock: {
    marginTop: theme.spacing(6),
  },
  loginInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0),
    },
  },
}));
