import { makeStyles } from "@mui/styles";
import { purple } from "@mui/material/colors";
export default makeStyles((theme) => ({
  appBar: {
    // margin: "0",
    padding: "10px 0",
    boxShadow: 'none',
    backgroundColor: "transparent!important",
  },
  dotNav: {
    color: theme.palette.secondary.color02,
  },
  logoNav: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontWeight: "bold!important",
  },
  heading: {
    color: theme.palette.neutral.light,
    textDecoration: "none",
  },
  brandName: {
    color: "#333",
    textDecoration: "none",
  },
  wishboard: {
    marginRight: "0",
  },
  image: {
    marginRight: "15px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    // width: "400px",
  },
  profile: {
    display: "flex",
    justifyContent: "space-between",
  },
  userName: {
    display: "flex",
    alignItems: "center",
  },
  rightBar: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: 80,
      paddingRight: 0,
    }
  },
  menuWrapper: {
    display: "flex",
    width: 80,
    [theme.breakpoints.down("md")]: {
      width: 80,
    }
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
    height: theme.layout.sizes.logoHeight,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 40,
    }
  },
  purple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    transform: 'rotate(-6deg)',
  },
  logout: {
    color: `${theme.palette.neutral.light}`,
  },
  [theme.breakpoints.down("md")]: {
    mainContainer: {
      flexDirection: "column-reverse!important",
    },
    brandContainer: {
      // width: "100%",
      // justifyContent: "space-between",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      // width: "400px",
    },
  },

}));
