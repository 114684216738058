import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

  heading: {
    color: theme.palette.secondary.main,
  },
  mt: {
    paddingBlock: theme.spacing(2, 0),
  },

  rowGrid1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    }

  },
  grid1Content: {
    [theme.breakpoints.down("md")]: {
      width: "100%!important",
      position: "relative",
      zIndex: 1,
      textAlign: "center",
    }
  },
  introRowContent: {
    maxWidth: "100%!important",
    width: "100%!important",
  },
  introRow: {
    display: "flex",
    textAlign: "center",
    paddingBlock: theme.spacing(0, 6),

    justifyContent: "center",
    alignItems: "center",
    minWidth: "100%",
    alignSelf: 'center',
    flexDirection: "row!important",
    [theme.breakpoints.down("md")]: {
      paddingBlock: theme.spacing(8),

    }
  },
  scrollerRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "left",
    gap: 50,
    marginInline: 'auto!important',
  },

  mainContainer: {
    display: "flex",
    height: theme.layout.sizes.bannerLarge,
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "left",
    minHeight: "90vh",
    marginInline: 'auto!important',
    flexDirection: "column!important",
    paddingBottom: theme.layout.gap.block.xl,
    marginBottom: theme.layout.gap.block.xl,
    [theme.breakpoints.down("sm")]: {
      minHeight: "100%",
      height: "auto",
      marginInline: 0,
      textAlign: "center",
      alignItems: "center",
      marginBottom: 0,
      flexDirection: "column-reverse!important",

    }
  },
  dot: {
    fontSize: theme.typography.sizes.h1,
    fontFamily: theme.typography.fonts.fontSecondary,
    color: theme.palette.secondary.color01,
    // backgroundClip: "text",
    // background: "linear-gradient(45deg, #F1CE89, #F2D2A4)",
    // background: "linear-gradient(45deg, #b9976A, #e4d19d, #d4b67a)",
  },
  accent2: {
    color: theme.palette.neutral.color03,
  },
  logo: {
    fontSize: theme.typography.sizes.h1,
    cursor: "pointer",
    fontFamily: `${theme.typography.fonts.fontSecondary}!important`,
    color: theme.palette.neutral.light,
    fontWeight: theme.typography.weight.reg,
  },
  date: {
    fontSize: theme.typography.sizes.xl,
    fontFamily: `${theme.typography.fonts.fontSecondary}!important`,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.weight.bold,
  },
  label: {
    fontFamily: `${theme.typography.fonts.fontPrimary}!important`,
    color: theme.palette.neutral.light,
    fontWeight: theme.typography.weight.bold,
    letterSpacing: '2px',
    textTransform: theme.typography.transform.uppercase,

  },
  titleMain: {
    fontSize: theme.typography.sizes.h2,
    fontFamily: `${theme.typography.fonts.fontSecondary}!important`,
    color: theme.palette.neutral.light,
    fontWeight: theme.typography.weight.semi,
  },
  slogan: {
    fontFamily: theme.typography.fonts.fontPrimary,
    fontSize: theme.typography.sizes.body,
    fontWeight: theme.typography.weight.reg,
    maxWidth: "34ch",
    display: "inline-block",
    lineHeight: 1.6,
    marginBlock: `${theme.spacing(2, 4)}!important`,
  },

  actionLinkIcon: {
    color: theme.palette.neutral.dark,
  },
  preTitle: {
    // color: theme.palette.primary.main,
    color: "#ffedc8",
    textTransform: theme.typography.transform.uppercase,
    letterSpacing: "2px",
  },
  preTitleDark: {
    // color: theme.palette.neutral.text,
  },
  preTitleLight: {
    color: theme.palette.neutral.text,
  },
  darkTheme: {
    color: theme.palette.neutral.text,
    paddingBlock: theme.spacing(2, 1),
  },
  text: {
    color: theme.palette.neutral.text,
    paddingBlock: theme.spacing(2, 1),
  },
  banner: {
    position: "relative",
    display: "flex",
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    isolation: "isolate",
    color: theme.palette.neutral.light,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      height: "60vh",
    }
  },

}));
