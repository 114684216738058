export const TwitterNew = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2961_74)">
          <path
            d="M23.7232 16.9373L38.2939 0H34.8412L22.1894 14.7064L12.0845 0H0.429688L15.7103 22.2387L0.429688 40H3.88267L17.2432 24.4696L27.9148 40H39.5696L23.7224 16.9373H23.7232ZM18.9939 22.4346L17.4457 20.2201L5.12683 2.59935H10.4304L20.3719 16.8199L21.9201 19.0344L34.8428 37.5189H29.5392L18.9939 22.4354V22.4346Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2961_74">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const FacebookNew = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2961_93)">
          <path
            d="M22.6563 39.9219H16.2506C15.1807 39.9219 14.3103 39.0515 14.3103 37.9816V23.5333H10.5731C9.50317 23.5333 8.63281 22.6627 8.63281 21.593V15.4019C8.63281 14.332 9.50317 13.4616 10.5731 13.4616H14.3103V10.3613C14.3103 7.28729 15.2756 4.67194 17.1014 2.79846C18.9355 0.916443 21.4987 -0.078125 24.5139 -0.078125L29.3991 -0.0701905C30.4672 -0.0683594 31.3361 0.802002 31.3361 1.87012V7.61841C31.3361 8.68836 30.466 9.55872 29.3964 9.55872L26.1072 9.55994C25.1041 9.55994 24.8486 9.76105 24.794 9.82269C24.704 9.92493 24.5969 10.2139 24.5969 11.012V13.4613H29.1492C29.4919 13.4613 29.8239 13.5458 30.1093 13.7051C30.7248 14.0491 31.1075 14.6994 31.1075 15.4022L31.105 21.5933C31.105 22.6627 30.2347 23.533 29.1647 23.533H24.5969V37.9816C24.5969 39.0515 23.7262 39.9219 22.6563 39.9219ZM16.6553 37.5769H22.2516V22.4835C22.2516 21.7691 22.8329 21.1881 23.5471 21.1881H28.7601L28.7622 15.8066H23.5468C22.8326 15.8066 22.2516 15.2255 22.2516 14.5111V11.012C22.2516 10.0958 22.3447 9.05396 23.0362 8.27087C23.8718 7.32422 25.1886 7.21497 26.1066 7.21497L28.9911 7.21375V2.27417L24.512 2.26685C19.6664 2.26685 16.6553 5.36865 16.6553 10.3613V14.5111C16.6553 15.2252 16.0742 15.8066 15.3601 15.8066H10.9778V21.1881H15.3601C16.0742 21.1881 16.6553 21.7691 16.6553 22.4835V37.5769Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2961_93">
            <rect width="39.9219" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
