import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  // Stack,
  Box,
  Paper,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import useStyles from "./styles";

import { languages } from "../../translations";
import { useSelector } from "react-redux";

const BasicAccordion = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.auth.lang);

  return (
    <div className={classes.accordion}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.paragraph}>
            {" "}
            Why should I use Wish?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Imagine the week before your Birthday. You are starting to receive
            tons of calls and messages about what you want to receive as a
            present. So instead of planning your celebration or having some
            relaxing moments, you need to repeat the same wishes over and over
            again. Or stay very focused trying not to name one wish to different
            friends, so that you won’t receive two similar Lego sets and no
            perfume, that you dreamed of.
          </Typography>
          <Typography className={classes.paragraph}>
            That is why we created Wish. You create your account, share it with
            your friends and family and they will know what are the most
            preferable presents for you.
          </Typography>
          <Typography className={classes.paragraph}>
            Also, your friends on Wish will be able to Book some of your wishes,
            so other users will know that you will be already receiving that
            gift and will select from wishes that are not booked yet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.paragraph}>
            Does Booking a Wish ruin the surprise?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            No, your secret is safe with us! When you book someone's wish, we
            will add a mark on the wish card, that someone is planning to gift
            that. But this information will only be available to other users,
            not to the creator of the Wish.
          </Typography>
          <Typography className={classes.paragraph}>
            Also as soon as you Book a wish, you’ll see it available handy in
            your “Booked wishes” tab on your WIshboard. So it will be very easy
            for you to track the wishes, you’re interested in.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Can I somehow see, what my wishes were booked?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            No, we are guarding the surprise someone is planning for you!
          </Typography>
          <Typography className={classes.paragraph}>
            So the best way to make sure, that your wishes are booked is to
            share your account or your most precious wishes with as many people
            as you can.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            I’m adding my address, but is it safe?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            It is safe to share your address in your account. The address, where
            you can receive your gifts is only visible to people, who added in
            your wish space as friends. It means that you always have control
            over who can see this information.
          </Typography>
          <Typography className={classes.paragraph}>
            Also, it is not mandatory to add an address if you don’t want to.
            You can always just leave the address of the closest pickup point of
            the Post Office and some delivery instructions.
          </Typography>
          <Typography className={classes.paragraph}>
            Or you can play the mystery card and leave the address field empty.
            But beware, that in this case only the chosen ones will be able to
            send you some gifts.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            What if I’m too shy to share my wishes?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Well, gifting is a very pleasant thing, so people who care about you
            will anyway be giving you something. So it might be much easier to
            create plenty of wishes for your friends to choose from than
            pretending that you always wanted that enormous pair of socks that
            you’ve just received.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            What if my friends are not yet on Wish?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            You can always invite your friends to join Wish, share your account,
            or some particular wish. And when your friends will try to book
            something for you, they will be also invited to create an account.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Why only registered users can book wishes?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            First of all, trusting someone’s wish to you is a big deal for us.
            We need to make sure, that when you book someone’s wish, you’ll have
            all the information you need to make that dream come true. That is
            why we need to whom we should send all the updates about the Wish
            and where the receiver is waiting for the present.
          </Typography>
          <Typography className={classes.paragraph}>
            Also in your account, you can see all the wishes you booked and
            cancel your booking in case you changed your mind
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            What if I can’t give the wish I booked?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            You can always update the Wish you booked if the circumstances
            change or you simply change your mind.
          </Typography>
          <Typography className={classes.paragraph}>
            You remove the booking on your “booked wishes” tab or simply on your
            friends' page.
          </Typography>
          <Typography className={classes.paragraph}>
            Noone will be notified about this and the Wish will be available for
            booking by other users.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Can multiple people Book the same wish?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            No, so if you’re booking a wish, other users will see that someone
            has already booked a wish. So it is no longer available for booking.
          </Typography>
          <Typography className={classes.paragraph}></Typography>
          <Typography className={classes.paragraph}></Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            {" "}
            What if I received the gift I wanted?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            You can mark your wish as received:{" "}
          </Typography>
          <ol>
            <li> Login to your account</li>
            <li> Go to your Wishboard </li>
            <li>
              {" "}
              Click on the “MORE” button in the bottom right corner of the Wish
              card.
            </li>
            <li> In the popup menu click on “Mark as received”</li>
          </ol>
          <Typography className={classes.paragraph}>
            After this your received gift will be removed from your wishes grid
            on your page. But it will be available on your Archive page just in
            case you will ever want to restore this wish.
          </Typography>
          <Typography className={classes.paragraph}></Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicAccordion;
