import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/uk';
import {
  Card,
  CardMedia,
  Button,
  Typography,
  CardActions,
  Box,
  ButtonBase,
  Rating,
  Dialog,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Tooltip,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  MoreHoriz,
  DeleteOutlined,
  FavoriteBorder,
  PermIdentityOutlined,
  Close,
} from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../../../constants/constants";
import { deleteWishList } from "../../../../actions/wishes";
import { bookWish, getWishboard } from "../../../../actions/user";
import wishlist4 from "../../../../images/utils/wishlist4.jpg";
import { ModeEditOutlined } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import Share from "../../../Share/Share";
import { LikeIcon, ShareIcon, GiftWish } from "../../WishGrid/Wish/decor/icons";

import { languages } from "../../../translations";

const WishList = ({ wishData, setCurrentListId, page, edit, fire }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));

  const { bookedWishes } = useSelector((state) => state.user);
  
  const [showDelete, setShowDelete] = useState(false);
  const [displayShare, setDisplayShare] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openWish = () => {
    navigate(`/wishList/${wishData._id}`);
  };
  const { lang } = useSelector((state) => state.auth);
  moment.locale(lang === "ua" ? "uk" : lang);
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#F1CE89",
    },
    "& .MuiRating-iconHover": {
      color: "#F1CE89",
    },
  });
  const toggleDeleteModal = () => {
    setShowDelete((prevShowDelete) => !prevShowDelete);
  };
  const toggleShareModal = () => {
    setDisplayShare((prevDisplayShare) => !prevDisplayShare);
  };
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleClosePopper = (event, reason) => {
    setOpen(false);
  };
  //bookWish
  const Books = () => {
    if (wishData?.booked) {
      return wishData.booked === user?._id ? (
        <>
          <Box className="animate-like">
            <LikeIcon />
            <FavoriteIcon fontSize="small" />{" "}
          </Box>{" "}
          &nbsp; You booked
        </>
      ) : (
        <>
          <Box className="animate-like">
            <LikeIcon />
            <FavoriteBorder fontSize="small" />{" "}
          </Box>{" "}
          &nbsp; Someone booked
        </>
      );
    }
    return (
      <>
        <Box className="book-btn">
          <Tooltip
            className="book-tooltip"
            title="By booking a wish you're confirming that you're planning to gift that."
          >
            <Box className="animate-like">
              <LikeIcon />
            </Box>
            <FavoriteBorder fontSize="small" /> &nbsp; Book{" "}
          </Tooltip>
        </Box>
      </>
    );
  };

  return (
    <Card className={classes.card}>
      <ButtonBase className={classes.cardAction} onClick={openWish}>
        <div className="wishlist">
          <CardMedia
            component="img"
            className={classes.media}
            image={wishData?.selectedFile || wishlist4}
            title={wishData.title}
          />
          <div className="overlay-cardMedia --full"></div>
          <div className="wishlistContent">
            {wishData.event && (
              <Typography className="event" variant="body2" component="p">
                <>
                  {" "}
                  {languages[lang].wishlist.details.event}{" "}
                  {moment(wishData?.event).fromNow()}
                </>
              </Typography>
            )}
            <div className={classes.cardTitle}>
              <Typography className={classes.title} variant="h6" component="h6">
                {wishData.title}
              </Typography>
              <Typography className={classes.desc} sx={{ p: 0 }}>
                {wishData.description}
              </Typography>
            </div>
          </div>
        </div>
      </ButtonBase>

      {wishData?.wishes?.length > 0 && (
        <div className="wishlistBadge lvlWish">
          <span className="wishlistBadge-icon">
            <GiftWish />
          </span>
          <span className="wishlistBadge-text">
            {" "}
            {languages[lang].wishlistcard.elements.wishes}
          </span>
          <span className="wishlistBadge-lvl">{wishData?.wishes?.length}</span>
        </div>
      )}
      <div className={classes.overlay2}>
        {wishData?.selectedUsers?.length > 0 ? (
          <Box className="helper-grid__badge">
            <div className={`wishlistBadge lvl6`}>
              <span className="wishlistBadge-text">
                {" "}
                {languages[lang].wishlistcard.elements.special}
              </span>
              <span className="wishlistBadge-lvl">
                <PermIdentityOutlined />
              </span>
            </div>
          </Box>
        ) : (
          <div className={`wishlistBadge lvl${wishData?.permission || 1}`}>
            <span className="wishlistBadge-text">
              {" "}
              {languages[lang].permissionCodes[wishData?.permission || 1]}
            </span>
            <span className="wishlistBadge-lvl">{wishData?.permission}</span>
          </div>
        )}
      </div>

      <div className={classes.details}>
        <Typography variant="body2" color="light">
          {wishData.tags?.map((tag) => (tag.length ? `#${tag}` : ""))}
        </Typography>
      </div>

      <CardActions className={classes.cardActions}>
        {user?._id === wishData?.creator && (
          <>
            <Button
              className="animated-share"
              endIcon={<ShareIcon />}
              size="small"
              color="primary"
              onClick={toggleShareModal}
            >
              {languages[lang].wishlistcard.buttons.share}
            </Button>
            <>
              <div className="more-actions">
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  {languages[lang].wishlistcard.buttons.more}{" "}
                  <MoreHoriz style={{ marginLeft: 10 }} fontSize="default" />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClosePopper}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              className="quick-action_item"
                              onClick={handleClose}
                            >
                              <Button
                                endIcon={<DeleteOutlined />}
                                size="small"
                                color="primary"
                                onClick={toggleDeleteModal}
                              >
                                {languages[lang].wishlistcard.buttons.delete}
                              </Button>
                            </MenuItem>

                            <MenuItem
                              className="quick-action_item"
                              onClick={handleClose}
                            >
                              <Button
                                endIcon={<ModeEditOutlined />}
                                size="small"
                                color="primary"
                                onClick={() => {
                                  edit(wishData._id);
                                }}
                              >
                                {languages[lang].wishlistcard.buttons.edit}
                              </Button>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </>
          </>
        )}
      </CardActions>
      <Dialog open={showDelete}>
        <DialogTitle>
          {languages[lang].dialog.delete.wishlist.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.desc}>
            {languages[lang].dialog.delete.wishlist.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button endIcon={<Close />} onClick={toggleDeleteModal}>
            {languages[lang].dialog.delete.wishlist.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<DeleteOutlined />}
            onClick={() => {
              dispatch(deleteWishList({ wishid: wishData._id, page: page }));
            }}
          >
            {languages[lang].dialog.delete.wishlist.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        className={classes.share}
        open={displayShare}
        PaperProps={{
          style: {
            maxWidth: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogActions className={classes.btns}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Close />}
            onClick={toggleShareModal}
          >
            Close
          </Button>
        </DialogActions>
        <DialogTitle className={classes.dialogTitle}>
          Share Your Wishlish
        </DialogTitle>
        <DialogContent className={classes.desc}>
          <DialogContentText></DialogContentText>
          <Share
            type="wish"
            shareText="your Wish"
            id={`wishList/${wishData._id}`}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};
export default WishList;
