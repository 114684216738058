/** @format */

import React, { useEffect, useState, useCallback, useRef } from "react";
import useStyles from "./styles";
import ReactCanvasConfetti from "react-canvas-confetti";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import {
	Paper,
	Typography,
	CircularProgress,
	Divider,
	Container,
	Grow,
	Box,
	Button,
	Rating,
	Tooltip,
	Grid,
	Popper,
	MenuItem,
	MenuList,
	ClickAwayListener,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import Loading from "../Loading/loading";
import wishlist4 from "../../images/utils/wishlist4.jpg";
import gift from "../../images/utils/gift.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getWishListData, bookWish } from "../../actions/user";
import Navbar from "../Navbar/Navbar";
import {
	ArrowBack,
	ScheduleOutlined,
	LinkOutlined,
	FavoriteBorder,
	MoreHoriz,
	CelebrationOutlined,
	PermIdentityOutlined,
	DeleteOutlined,
	Close,
} from "@mui/icons-material";
import CakeIcon from "@mui/icons-material/Cake";
import Toast from "../toast/Toast";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import Footer from "../Footer/Footer";
import { styled } from "@mui/material/styles";
import { LikeIcon, ShareIcon } from "../Wishboard/WishGrid/Wish/decor/icons";
import constants from "../../constants/constants";
import { markComplete, deleteWish } from "../../actions/wishes";
import { Stamp, GiftWish } from "../Wishlist/decor";
import {
	bookWishSlice,
	completeWishSlice,
	deleteWishSlice,
} from "../../reducers/user";
import Share from "../Share/Share";
import { languages } from "../translations";

// Notifications
const toastConf = {
	open: false,
	severity: "error",
	message: "Error",
};
const canvasStyles = {
	position: "fixed",
	pointerEvents: "none",
	width: "100%",
	height: "100%",
	top: 0,
	left: 0,
};
const WishList = () => {
	const user = JSON.parse(localStorage.getItem(constants.wishProfile));
	const { isLoading } = useSelector((state) => state.user);
	const [toast, setToast] = useState(toastConf);
	const { currentList } = useSelector((state) => state.user);

	const [showDelete, setShowDelete] = useState(false);
	const [displayShare, setDisplayShare] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [open, setOpen] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const anchorRef = useRef(null);
	const { lang } = useSelector((state) => state.auth);
	moment.locale(lang === "ua" ? "uk" : lang);

	const StyledRating = styled(Rating)({
		"& .MuiRating-iconFilled": {
			color: "#F1CE89",
		},
		"& .MuiRating-iconHover": {
			color: "#F1CE89",
		},
	});

	const openWish = (wish) => {
		navigate(`/wish/${wish._id}`);
	};
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	useEffect(() => {
		dispatch(getWishListData(id)).then((result) => {
			if (result.error) {
				setToast({
					...toast,
					open: true,
					message: `Error ${result.payload}`,
				});
				navigate("/");
			}
		});
	}, [id]);

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}

	const prevOpen = React.useRef(open);
	const closeDeleteModal = (id) => {
		setDeleteId(null);
		setShowDelete(false);
	};
	const showDeleteModal = (w) => {
		setDeleteId(w);
		setShowDelete(true);
	};
	const toggleShareModal = () => {
		setDisplayShare((prevDisplayShare) => !prevDisplayShare);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};
	const updateUI = (wish) => {
		fire();
		dispatch(completeWishSlice(wish));
	};
	const refAnimationInstance = useRef(null);

	const getInstance = useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);
	const makeShot = useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				colors: ["#FEFEDF", "#F1CE89", "#058686"],
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);
	useEffect(() => {
		// currentList.wishes ? console.log(currentList.wishes.length) : "";
	});

	const Books = ({ item }) => {
		if (!user) {
			return (
				<Typography className={classes.loginInfo}>
					{languages[lang].wishlist.login}
				</Typography>
			);
		}
		if (item.booked) {
			return item.booked === user?._id ? (
				<>
					<Box className='animate-like'>
						<LikeIcon />
						<FavoriteIcon fontSize='small' />{" "}
					</Box>{" "}
					&nbsp; {languages[lang].wishlist.youBooked}
				</>
			) : (
				<>
					<Box className='animate-like'>
						<LikeIcon />
						<FavoriteBorder fontSize='small' />{" "}
					</Box>{" "}
					&nbsp; {languages[lang].wishlist.notYouBooked}
				</>
			);
		}
		return (
			<>
				<Box className='book-btn'>
					<Tooltip
						className='book-tooltip'
						title="By booking a wish you're confirming that you're planning to gift that.">
						<Box className='animate-like'>
							<LikeIcon />
						</Box>
						<FavoriteBorder fontSize='small' /> &nbsp;{" "}
						{languages[lang].wishlist.book}
					</Tooltip>
				</Box>
			</>
		);
	};

	return isLoading ? (
		<Loading />
	) : (
		<>
			<Navbar />
			<Container maxWidth='xl' className={`${classes.containerMain}`}>
				<Button
					startIcon={<ArrowBack />}
					variant='text'
					className={classes.wishboard}
					onClick={() => {
						navigate("/wishboard");
					}}>
					{languages[lang].wishlist.details.back}
				</Button>
				<Grid
					container
					justifyContent='space-between'
					alignItems='stretch'
					className={classes.card}>
					<Grid item className={classes.section} xs={12}>
						<Grid
							container
							justifyContent='space-between'
							alignItems='stretch'
							spacing={3}
							className={classes.sectionWr}>
							<Grid item className={classes.imageSection} xs={12} md={4}>
								<div className='overlay-wrapper'>
									<img
										className={classes.media}
										src={currentList?.singleList?.selectedFile || wishlist4}
										alt={currentList?.singleList?.title}
									/>
									{currentList?.wishes?.length > 0 && (
										<div className='wishlistBadge lvlWish'>
											<span className='wishlistBadge-icon'>
												<GiftWish />
											</span>
											<span className='wishlistBadge-text'>
												{" "}
												{languages[lang].wishlist.details.wishes}
											</span>
											<span className='wishlistBadge-lvl'>
												{currentList?.wishes?.length}
											</span>
										</div>
									)}

									<div className='overlay-cardMedia transparent '>
										{user?._id === currentList?.singleList?.creator?._id &&
											(currentList?.singleList?.selectedUsers?.length > 0 ? (
												<Box className='helper-grid__badge'>
													<div className={`wishlistBadge lvl6`}>
														<span className='wishlistBadge-text'>
															{" "}
															{languages[lang].wishlist.details.special}
														</span>
														<span className='wishlistBadge-lvl'>
															<PermIdentityOutlined />
														</span>
													</div>
												</Box>
											) : (
												<div
													className={`wishlistBadge lvl${
														currentList?.singleList?.permission || 1
													}`}>
													<span className='wishlistBadge-text'>
														{" "}
														{
															languages[lang].permissionCodes[
																currentList?.singleList?.permission || 1
															]
														}
													</span>
													<span className='wishlistBadge-lvl'>
														{currentList?.singleList?.permission}
													</span>
												</div>
											))}
									</div>
								</div>
							</Grid>

							<Grid item className={classes.section} xs={12} md={8}>
								<Typography variant='h3' component='h2'>
									{currentList?.singleList?.title}
								</Typography>
								{currentList?.singleList?.description && (
									<Typography className={classes.desc}>
										{currentList.singleList.description}
									</Typography>
								)}
								{currentList?.singleList?.event && (
									<Typography
										className='event'
										// gutterBottom
										variant='body2'
										component='p'>
										<>
											{" "}
											1{languages[lang].wishlist.details.event}{" "}
											{moment(currentList?.singleList?.event).fromNow()}
										</>
									</Typography>
								)}
								<Divider style={{ margin: "20px 0" }} />
								{currentList?.description && (
									<>
										<Typography gutterBottom variant='body1' component='span'>
											{languages[lang].wishlist.details.desc}: &nbsp;
										</Typography>
										<Typography gutterBottom variant='body1' component='span'>
											{currentList?.description}
										</Typography>
									</>
								)}

								<Box className={`${classes.eventGrid} ${classes.wishBlock}`}>
									<Typography variant='body1' className={classes.eventTitle}>
										{languages[lang].wishlist.details.created}: &nbsp;
										<span
											onClick={() =>
												navigate(
													`/user/${currentList?.singleList?.creator._id}`
												)
											}
											className='icon-grid  cursor-pointer underline '>
											{currentList.singleList?.creator?.firstName}
										</span>
									</Typography>

									<div className='row-wrapper'>
										<Typography variant='body1' className='wishlist-update'>
											{languages[lang].wishlist.details.updated}: &nbsp;
											<span className='icon-grid --center'>
												<ScheduleOutlined />
												{moment(currentList?.updatedAt).fromNow()}
											</span>
										</Typography>
									</div>
								</Box>
								<>
									<div className='tiny-links --start'>
										<Share
											type='wish'
											shareText='your Wish'
											id={`wishList/${currentList?.singleList?._id}`}
										/>
									</div>
								</>
							</Grid>
						</Grid>
					</Grid>
					{currentList?.wishes?.map((wish) => (
						<Grid
							key={wish._id}
							item
							xs={12}
							sm={12}
							className={`wishlines ${
								wish?.status === 1 ? "active" : "not-active"
							}`}>
							{wish?.status !== 1 && (
								<div className='received-wrapp'>
									{" "}
									<Stamp />{" "}
								</div>
							)}
							<Box className={`${classes.card} wishline `}>
								<div className='avatar wishline-avatar --xl'>
									<img
										className={`${classes.wishMedia}  wishline-media`}
										src={wish?.selectedFile || gift}
										alt={currentList?.title}
										onClick={() => {
											navigate(`/wish/${wish._id}`);
										}}
									/>
								</div>
								<div className='wishline-title'>
									<Typography
										variant='h6'
										className='wishline-text'
										onClick={() => {
											navigate(`/wish/${wish._id}`);
										}}>
										{wish?.title}
									</Typography>
								</div>
								<div className='wishline-permission'>
									{user?._id === wish?.creator && (
										<div
											className={`wishlistBadge lvl${
												wish?.permission || 1
											} wishline-badge`}>
											<span className='wishlistBadge-text'>
												{languages[lang].permissionCodes[wish?.permission || 1]}
											</span>
											<span className='wishlistBadge-lvl'>
												{wish?.permission || 1}
											</span>
										</div>
									)}
								</div>

								<div className='rating-wrap wishline-rating'>
									<StyledRating
										size='small'
										name='customized-color'
										defaultValue={wish?.dreamScale || 1}
										readOnly
										precision={1}
										icon={<CakeIcon fontSize='inherit' />}
										emptyIcon={<CakeIcon fontSize='inherit' />}
									/>
								</div>
								<div className=' wishline-time '>
									{wish?.updatadeAt && (
										<Typography variant='body2'>
											<span className='icon-grid  accent2'>
												<ScheduleOutlined />
												{moment(wish?.updatadeAt).fromNow()}
											</span>
										</Typography>
									)}
								</div>
								<div className='link wishline-link'>
									{wish?.link && (
										<div className='row-wrapper'>
											{/* <Tooltip title="Openl wish external link"> */}
											<a
												className='link-emerald link'
												target='_blank'
												href={wish?.link}
												rel='noreferrer nofollow'>
												<span className='link-text'>
													{languages[lang].wishlist.details.link}
												</span>
												<span className='link-icon'>
													{" "}
													<LinkOutlined />
												</span>
											</a>
											{/* </Tooltip> */}
										</div>
									)}
								</div>
								{user?._id !== wish?.creator ? (
									<>
										<div className=' wishline-book'>
											<Button
												size='small'
												color='primary'
												className='no-effect'
												disabled={!user}
												onClick={() => {
													dispatch(bookWish(wish._id)).then(
														dispatch(bookWishSlice({ wish, id: user?._id }))
													);
												}}>
												<Books item={wish} />
											</Button>
										</div>
									</>
								) : (
									<>
										<div className=' wishline-delete'>
											<Button
												endIcon={<DeleteOutlined />}
												size='small'
												className='no-effect'
												color='secondary'
												onClick={() => {
													showDeleteModal(wish);
												}}>
												{languages[lang].wishlist.details.delete}
											</Button>
										</div>
										<div className='more-actions  wishline-actions'>
											<MenuList
												autoFocusItem={open}
												id='composition-menu'
												aria-labelledby='composition-button'
												onKeyDown={handleListKeyDown}>
												<MenuItem
													className='quick-action_item'
													onClick={handleClose}>
													<Button
														color='secondary'
														size='small'
														endIcon={<CelebrationOutlined />}
														onClick={() => {
															dispatch(
																markComplete({
																	wishid: wish._id,
																})
															).then(updateUI(wish._id));
														}}>
														{languages[lang].wishlist.details.received}
													</Button>
												</MenuItem>
											</MenuList>
										</div>
									</>
								)}
							</Box>
						</Grid>
					))}
				</Grid>

				<Dialog
					fullWidth
					className={classes.share}
					open={displayShare}
					PaperProps={{
						style: {
							maxWidth: "100%",
							backgroundColor: "transparent",
							boxShadow: "none",
						},
					}}>
					<DialogActions className={classes.btns}>
						<Button
							variant='contained'
							color='primary'
							endIcon={<Close />}
							onClick={toggleShareModal}>
							Close
						</Button>
					</DialogActions>
					<DialogTitle className={classes.dialogTitle}>
						Share Your Wish
					</DialogTitle>
					<DialogContent className={classes.desc}>
						<DialogContentText></DialogContentText>
						<Share
							type='wish'
							shareText='your Wish'
							id={`wish/${currentList?.singleList?._id}`}
						/>
					</DialogContent>
				</Dialog>

				<Dialog open={showDelete}>
					<DialogTitle>Delete wish</DialogTitle>
					<DialogContent>
						<DialogContentText className={classes.desc}>
							You are going to delete your wish. We must warn you that you if
							you proceed you can't revert this proccess and all people who
							might subscribed to your wish will lose access to it permanently.
						</DialogContentText>
					</DialogContent>
					<DialogActions className={classes.btns}>
						<Button endIcon={<Close />} onClick={closeDeleteModal}>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='primary'
							endIcon={<DeleteOutlined />}
							onClick={() => {
								dispatch(deleteWish({ wishid: deleteId._id })).then(
									dispatch(deleteWishSlice({ deleteId, id: user?._id }))
								);
								closeDeleteModal();
							}}>
							Delete
						</Button>
					</DialogActions>
				</Dialog>

				<ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
			</Container>

			<Footer />
		</>
	);
};

export default WishList;
