import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "440px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "280px",
    },
  },
  overlay: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    maxWidth: "40%",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(3),
      left: theme.spacing(4),
    },
  },

  cardWrapper: {
    padding: theme.spacing(4, 6),
    overflow: "hidden",
    position: "relative",
    marginBlock: theme.spacing(6),
    minHeight: "60vh",
    isolation: "isolate",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4, 2),
    },
  },
  card: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  wishboard: {
    marginBottom: theme.spacing(4),
  },
  section: {
    borderRadius: "20px",
    margin: "10px",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0!important",
    },
  },
  imageSection: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: "0!important",
    },
  },
  loginInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0),
    },
  },
  eventTitle: {
    display: "flex",
    gap: theme.spacing(0, 1),
    alignItems: "center",
  },
  // eventGrid: {
  //   display: "flex",
  //   gap: theme.spacing(0, 2),
  //   alignItems: "center",
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "column",
  //     alignItems: "flex-start",
  //   },

  //   justifyContent: "flex-start",
  // },

  wishBlock: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },
}));
