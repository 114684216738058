import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";

import {
  Button,
  Stack,
  TextField,
  Typography,
  Paper,
  Box,
  Autocomplete,
  Chip,
} from "@mui/material";
// import image3 from "../../../images/Homepage/image5.jpg";
import image3 from "../../images/Homepage/image5.jpg";
import {
  Face3,
  Lock,
  RedoOutlined,
  Face2,
  Key,
  Pets,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

import constants from "../../constants/constants";

const PageTitle = ({ title, subtitle }) => {
  const user = JSON.parse(localStorage.getItem(constants.wishProfile));
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.paper}>
      {/* <div className="decor-card2"></div> */}
      <Typography variant="h1" align="center">
        {title}
      </Typography>
      <Typography variant="body1" align="center" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
};
export default PageTitle;
