/** @format */

import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/uk";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
	Paper,
	Typography,
	CircularProgress,
	Divider,
	Container,
	Grow,
	Grid,
	Radio,
	RadioGroup,
	FormControl,
	FormControlLabel,
	FormLabel,
	Button,
	TextField,
	Box,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Avatar,
} from "@mui/material";
import Clock from "../animations/clock";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	fetchUserDetails,
	updateDetails,
	initiateReset,
} from "../../actions/user";
import { logout } from "../../actions/auth";
import Toast from "../toast/Toast";
import Logo from "../Logo/Logo";
import constants from "../../constants/constants";
import banner from "../../images/Homepage/cake.jpg";
import Navbar from "../Navbar/Navbar";
import Loading from "../Loading/loading";

import {
	Logout,
	DeleteForever,
	Update,
	KeyOutlined,
} from "@mui/icons-material";
import Footer from "../Footer/Footer";
import { languages } from "../translations";

// Notifications
const toastConf = {
	open: false,
	severity: "error",
	message: "Error",
};
const UserDetails = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [toast, setToast] = useState(toastConf);
	const [birthday, setDeadline] = useState();
	const { lang } = useSelector((state) => state.auth);
	const [showReset, setshowReset] = useState(false);
	const toggleReset = () => {
		setshowReset((prevShowReset) => !prevShowReset);
	};
	moment.locale(lang === "ua" ? "uk" : lang);
	const { isLoading, avatar } = useSelector((state) => state.user);
	let user = JSON.parse(localStorage.getItem(constants.wishProfile));
	const [formData, setFormData] = useState({
		firstName: "Name",
		lastName: "lastName",
		email: "Email",
		avatar: "",
		address: "Address",
		country: "Country",
		city: "City",
		postCode: "Postal code",
		phoneNumber: "Phone number",
		bio: "Bio",
		birthDate: "",
		sex: "male",
	});

	const [avatarPreview, setAvatarPreview] = useState(formData.avatar);
	const userDetails = useSelector((state) => state.user);
	const clear = () => {
		setAvatarPreview(null);
		setFormData({ ...formData, avatar: userDetails?.avatar });
	};
	const uploadFile = (event) => {
		let sizeInKb = (event.target.files[0].size / (1024 * 1024)).toFixed(2);
		if (sizeInKb > constants.MAX_LIFE_SIZE) {
			console.log(sizeInKb);
			// clear();
		}
		let file = event.target.files[0];

		setAvatarPreview(URL.createObjectURL(file));
		setFormData({ ...formData, avatar: file });
		// let stringLength = base64.length - "data:image/png;base64,".length;

		// let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
		// let sizeInKb = sizeInBytes / 1000;

		// if (!sizeInKb) return;
		// if (sizeInKb > constants.MAX_LIFE_SIZE * 1000) {
		//   console.log(sizeInKb);
		// } else {
		//   setFormData({ ...formData, avatar: base64 });
		// }
	};
	const logoutFunc = () => {
		try {
			dispatch(logout()).then((result) => {
				navigate("/auth");
			});
		} catch (error) {
			console.log(error);
		}
	};
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setToast({ ...toast, open: false });
	};
	const handleResetSubmit = (e) => {
		e.preventDefault();

		dispatch(initiateReset(formData.email, navigate)).then((result) => {
			if (!result.error) {
				toggleReset();
				setToast({
					open: true,
					severity: "success",
					message: "Check your email",
				});
			} else {
				setToast({
					...toast,
					open: true,
					message: `Error ${result.payload}`,
				});
			}
		});
	};
	useEffect(() => {
		dispatch(fetchUserDetails());
	}, []);

	useEffect(() => {
		if (userDetails) {
			setFormData(userDetails);
		}
		if (userDetails && userDetails.birthDate) {
			let nextYearBmonth = moment(userDetails.birthDate).format("MM");
			let nextYearBday = moment(userDetails.birthDate).format("DD");
			let currentYear = moment().year();

			let deadline = moment().diff(
				`${currentYear}-${nextYearBmonth}-${nextYearBday}`,
				"days"
			);
			if (deadline && deadline > 0) {
				setDeadline(`${currentYear + 1}-${nextYearBmonth}-${nextYearBday}`);
			} else {
				setDeadline(`${currentYear}-${nextYearBmonth}-${nextYearBday}`);
			}
		}
	}, [userDetails]);

	useEffect(() => {
		if (!user) {
			navigate("/");
		}
	}, [user]);

	const handleSubmit = (e) => {
		e.preventDefault();

		dispatch(updateDetails({ ...formData })).then((result) => {
			if (!result.error) {
				dispatch(fetchUserDetails());
			} else {
				setToast({
					...toast,
					open: true,
					message: `Error ${result.payload}`,
				});
			}
		});
	};

	const handleChange = (e) => {
		console.log([e.target.value, e.target.name]);
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	return isLoading ? (
		<Loading />
	) : (
		<>
			<Navbar />
			<Container maxWidth='xl' className={classes.innerWrapper}>
				<Grid
					container
					justifyContent='space-between'
					alignItems='stretch'
					spacing={3}
					className={classes.mainContainer}>
					<Grid item xs={12} sm={12} md={8}>
						<Paper elevation={6}>
							<Box className={classes.wrapper}>
								<Box
									component='form'
									className={classes.form}
									noValidate
									onSubmit={handleSubmit}>
									<Typography
										textAlign='center'
										variant='h4'
										component='h2'
										style={{ marginBottom: 20 }}>
										{languages[lang].account.title}
									</Typography>
									<Grid
										container
										justifyContent='space-between'
										alignItems='stretch'
										spacing={2}
										className={classes.formRow}>
										<Grid item xs={12} sm={6} md={6}>
											<TextField
												fullWidth
												margin='dense'
												className={classes.inputFullWidth}
												name='firstName'
												type='text'
												variant='standard'
												autoComplete='firstname'
												onChange={handleChange}
												label={`${languages[lang].account.form.first}`}
												value={formData.firstName}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<TextField
												fullWidth
												margin='dense'
												// className={classes.inputFullWidth}
												name='lastName'
												variant='standard'
												type='text'
												autoComplete='lastname'
												onChange={handleChange}
												label={`${languages[lang].account.form.last}`}
												value={formData.lastName}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												margin='dense'
												variant='standard'
												className={classes.inputFullWidth}
												name='email'
												type='email'
												// variant='outlined'
												autoComplete='email'
												onChange={handleChange}
												label={`${languages[lang].account.form.email}`}
												value={formData.email}
												disabled
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												variant='standard'
												margin='dense'
												className={classes.inputFullWidth}
												name='phoneNumber'
												type='tel'
												// variant='outlined'
												placeholder='Phone number'
												autoComplete='tel'
												onChange={handleChange}
												label={`${languages[lang].account.form.phone}`}
												value={formData.phoneNumber}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												margin='dense'
												className={classes.inputFullWidth}
												name='country'
												type='text'
												// variant='outlined'
												variant='standard'
												placeholder='Country'
												autoComplete='country-name'
												onChange={handleChange}
												label={`${languages[lang].account.form.country}`}
												value={formData.country}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												margin='dense'
												className={classes.inputFullWidth}
												name='city'
												type='text'
												// variant='outlined'
												variant='standard'
												placeholder='City'
												autoComplete='address-level2'
												onChange={handleChange}
												label={`${languages[lang].account.form.city}`}
												value={formData.city}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												margin='dense'
												className={classes.inputFullWidth}
												name='postCode'
												type='number'
												// variant='outlined'
												variant='standard'
												placeholder='ex: 022322'
												autoComplete='postal-code'
												onChange={handleChange}
												label={`${languages[lang].account.form.postal}`}
												value={formData.postCode}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												margin='dense'
												className={classes.inputFullWidth}
												name='address'
												type='text'
												// variant='outlined'
												variant='standard'
												placeholder='Where I receive gifts'
												autoComplete='address'
												onChange={handleChange}
												label={`${languages[lang].account.form.adress}`}
												value={formData.address}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={12}>
											<TextField
												fullWidth
												margin='dense'
												className={classes.inputFullWidth}
												name='bio'
												placeholder='My bio'
												multiline
												type='textarea'
												variant='standard'
												onChange={handleChange}
												label={`${languages[lang].account.form.bio}`}
												value={formData.bio}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<LocalizationProvider dateAdapter={AdapterMoment}>
												<DatePicker
													// className={classes.input}
													fullWidth
													margin='dense'
													variant='standard'
													name='birthdate'
													value={moment(formData.birthDate)}
													format='DD-MM-YYYY'
													// label={"Your Birthday"}
													label={`${languages[lang].account.form.birthday}`}
													views={["day", "month", "year"]}
													onChange={(e) => {
														setFormData({
															...formData,
															birthDate: moment(e, "DD MM YYYY").toISOString(),
														});
													}}
												/>
											</LocalizationProvider>
										</Grid>
										{/* <Grid item xs={12} sm={6} md={6}> */}
										{/* <FormControl fullWidth>
												<FormLabel id='gender-select'>Sex</FormLabel>
												<RadioGroup
													row
													margin='dense'
													aria-labelledby='gender-select'
													defaultValue='1'
													value={formData.sex}
													onChange={handleChange}
													name='sex'>
													<FormControlLabel
														value='2'
														control={<Radio />}
														label='Male'
													/>
													<FormControlLabel
														value='1'
														control={<Radio />}
														label='Female'
													/>
												</RadioGroup>
											</FormControl> */}
										{/* </Grid> */}
										<Grid item xs={12} sm={6} md={6}>
											<div className='input-file photo'>
												<div className='avatar'>
													<Avatar
														variant='rounded'
														className={classes.purple}
														alt='user avatar'
														src={
															avatarPreview ||
															formData.avatar ||
															"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
														}
													/>
												</div>
												<label htmlFor='upload-image'>
													<Button variant='contained' component='span'>
														{languages[lang].account.form.upload}
													</Button>
													<Button
														variant='text'
														className={classes.logout}
														onClick={clear}>
														{" "}
														{languages[lang].account.form.reset}
													</Button>
													<input
														id='upload-image'
														hidden
														accept='image/png, image/jpeg, image/webp, image/jpg'
														type='file'
														onChange={uploadFile}
													/>
												</label>

												{/* <FileBase64
                          type="file"
                          fullWidth
                          multiple={false}
                          onDone={({ base64 }) => uploadFile(base64)}
                        /> */}
											</div>
										</Grid>

										<Grid item xs={12} sm={6} md={12}>
											{/* <Box className={classes.submitWrapper}> */}
											<Button
												type='submit'
												variant='contained'
												color='primary'
												endIcon={<Update />}
												className={classes.submit}>
												{languages[lang].account.form.save}
											</Button>
											{/* </Box> */}
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box className={classes.banner}>
							<div className='banner-bg  --small'>
								<img src={banner} alt='avocado' className='banner-image' />{" "}
							</div>
							<div className='banner-content --small'>
								<Typography
									className={`${classes.preTitle} ${classes.preTitleDark} `}>
									{" "}
								</Typography>
								<Typography variant='h4' className={classes.titleMain}>
									{languages[lang].account.countdown.birthday}{" "}
									<span className={classes.titleMain}>
										{" "}
										{languages[lang].account.countdown.in}
									</span>
								</Typography>

								<Clock deadline={birthday} />
								{/* <div>{birthday}</div> */}
							</div>
						</Box>
						<Paper elevation={6} className={classes.accountMenu}>
							<Box className={classes.userMenu}>
								<Typography variant='h4' className={classes.titleMain}>
									{languages[lang].account.account}
								</Typography>
								<Grid
									container
									justifyContent='space-between'
									alignItems='stretch'
									className={classes.formRow}>
									<Grid item xs={12}>
										<Button
											variant='text'
											className={classes.logout}
											onClick={toggleReset}
											startIcon={<KeyOutlined />}>
											{" "}
											{languages[lang].account.resetPass}
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Button
											variant='text'
											className={classes.logout}
											onClick={logoutFunc}
											startIcon={<Logout />}>
											{" "}
											{languages[lang].account.logout}
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Container>
			<Dialog open={showReset} onClose={handleClose}>
				<DialogTitle> {languages[lang].account.resetPass}</DialogTitle>
				<DialogContent>
					<DialogContentText className={classes.desc}>
						{languages[lang].account.resetMessage}
					</DialogContentText>
					<TextField
						fullWidth
						disabled
						margin='normal'
						className={classes.inputFullWidth}
						name='reset'
						type='email'
						autoComplete='email'
						label={`${languages[lang].account.enterEmail}`}
						value={formData.email}
					/>
				</DialogContent>
				<DialogActions className={classes.btns}>
					<Button onClick={toggleReset}>
						{languages[lang].dialog.delete.wish.cancel}
					</Button>
					<Button
						variant='contained'
						color='primary'
						className={classes.submit}
						onClick={handleResetSubmit}>
						{languages[lang].account.form.reset}
					</Button>
				</DialogActions>
			</Dialog>
			<Toast
				open={toast.open}
				message={toast.message}
				severity={toast.severity}
				onClose={handleClose}
			/>
			<Footer />
		</>
	);
};

export default UserDetails;
