import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    mainContainer: {
        height: "100%",
    },

    success: {
        height: "100%",
    }
}));
