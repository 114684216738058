import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  // Stack,
  Box,
  Paper,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import useStyles from "./styles";

import { languages } from "../../translations";
import { useSelector } from "react-redux";

const BasicAccordionUa = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.auth.lang);

  return (
    <div className={classes.accordion}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.paragraph}>
            {" "}
            Навіщо мені Wish?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Уявіть собі тиждень до вашого дня народження. Ви починаєте
            отримувати безліч дзвінків і повідомлень про те, що ви хочете
            отримувати присутній. Тож замість того, щоб планувати своє
            святкування чи влаштовувати його розслаблюючі моменти, вам потрібно
            повторювати ті самі бажання знову і знову знову. Або залишайтеся
            дуже зосередженими, намагаючись не називати одне бажання іншим
            друзі, щоб ви не отримали два схожі набори Лего і ні духи, про які
            ви мріяли.
          </Typography>
          <Typography className={classes.paragraph}>
            Саме тому ми створили Wish. Ви створюєте свій обліковий запис,
            ділитеся ним з Ваші друзі та родина, і вони знатимуть, що які
            найбільш бажані подарунки для вас.
          </Typography>
          <Typography className={classes.paragraph}>
            Крім того, ваші друзі на Wish зможуть забронювати деякі ваші
            бажання, тому інші користувачі знатимуть, що ви вже отримаєте цей
            подарунок і вибере з бажань, які ще не заброньовані.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.paragraph}>
            Бронювання бажання може зіпсувати сюрприз?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Ні, ваш секрет у безпеці! Коли ви бронюєте чиєсь бажання, ми додасть
            позначку на картці побажань, що хтось планує подарувати що. Але ця
            інформація буде доступна лише іншим користувачам, не творцю Wish.
          </Typography>
          <Typography className={classes.paragraph}>
            Крім того, щойно ви забронюєте бажання, ви побачите його доступним у
            вкладці «Заброньовані бажання» на дошці бажань. Тож вам буде дуже
            легко відстежити бажання, які вас цікавлять.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Чи можу я якось подивитися, які мої бажання заброньовані?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Ні, ми охороняємо сюрприз, який хтось планує для вас!
          </Typography>
          <Typography className={classes.paragraph}>
            Тож найкращий спосіб переконатися, що ваші бажання зарезервовані –
            це поділіться своїм обліковим записом або найціннішими бажаннями з
            якомога більшою кількістю людей.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Я додаю свою адресу, але чи безпечно це?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Поділитися своєю адресою в обліковому записі безпечно. Вашу адреса
            за якою ви можете отримати свої подарунки, бачать лише люди,яких ви
            додали як друзів. Це означає, що у вас завжди контроль над тим, хто
            може бачити цю інформацію.
          </Typography>
          <Typography className={classes.paragraph}>
            Крім того, необов’язково додавати адресу, якщо ви цього не хочете.
            Ви завжди можете просто залишити адресу найближчого пункту
            самовивозу поштове відділення та деякі інструкції з доставки.
          </Typography>
          <Typography className={classes.paragraph}>
            Або ви можете залишити поле адреси порожнім. Але майте на увазі, що
            в цьому випадку зможуть лише обрані відправити вам подарунки.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Що робити, якщо я соромлюся поділитися своїми бажаннями?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Що ж, подарунки - це дуже приємна справа, тому людям, яким ви
            небайдужі все одно захочуть вам щось подарувати. Тому набагато
            простіше створити багато побажань для своїх друзів на вибір вдаючи,
            що ви завжди хотіли цю величезну пару шкарпеток яку щойно отримали.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Що якщо мої друзі ще не на Wish?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Ви завжди можете запросити своїх друзів приєднатися до Wish,
            поділитися своїм обліковим записом, або особливим бажанням. І коли
            ваші друзі спробують забронювати щось для вас, їм також буде
            запропоновано створити обліковий запис.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Чому тільки зареєстровані користувачі можуть бронювати побажання?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Перш за все, коли хтось довіряє своє бажання вам – це дуже важливо.
            Ми повинні бути впевнені, що коли ви бронюєте чиєсь бажання, ви його
            отримаєте усю інформацію, необхідну для здійснення цієї мрії. Тобто
            нам потрібно більше інформації, кому ми повинні надсилати всі
            оновлення про бажання і де одержувач чекає на подарунок.
          </Typography>
          <Typography className={classes.paragraph}>
            Також у своєму обліковому записі ви можете побачити всі заброньовані
            вами побажання та скасувати бронювання, якщо ви передумали
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Що робити, якщо я не можу виконати бажання, яке я забронював?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Ви завжди можете оновити заброньований Wish, змінити на інший або
            просто скасувати.
          </Typography>
          <Typography className={classes.paragraph}>
            Для того щоб скасувати бронювання, просто ще раз натисніть кнопку
            бронювання на своїй сторінці у вкладці заброньовані або на сторінці
            друга.
          </Typography>
          <Typography className={classes.paragraph}>
            Ніхто не дізнається що бронювання скасованно і бажання одразу буде
            доступно до бронювання іншими.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            Чи можуть декілька людей бронювати одне бажання?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Ні, тому якщо ви бронюєте бажання, інші користувачі побачать що
            хтось вже забронював бажання. Тому він більше не доступний для
            бронювання.
          </Typography>
          <Typography className={classes.paragraph}></Typography>
          <Typography className={classes.paragraph}></Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.paragraph}>
            {" "}
            Що робити, коли я отримав подарунок, який хотів?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.paragraph}>
            Ви можете відмітити як виконаний:{" "}
          </Typography>
          <ol>
            <li> Увійдіть до свого облікового запису</li>
            <li> Відкрийте сторінку Дошка бажань </li>
            <li> Натисніть бальше на картці бажання</li>
            <li> У спливаючому меню натисніть «Позначити як отримане»</li>
          </ol>
          <Typography className={classes.paragraph}>
            Після цього отриманий вами подарунок буде видалено з дошки побажань
            на вашій сторінці. Але він буде доступний на вашій сторінці архіву
            якщо ви коли-небудь захочете відновити це бажання.
          </Typography>
          <Typography className={classes.paragraph}></Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicAccordionUa;
