import React, { useEffect } from "react";

import { Container, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { confirmEmail } from "../../actions/user";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import EmailSuccess from "../../components/ErrorScreens/EmailSuccess/EmailSuccess";

const ConfirmEmail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(confirmEmail(id)).then(navigate("/"));
  }, [id]);
  const classes = useStyles();

  return (
    <>
      <Navbar />
      <Container className="body-wrapper --align-center">
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          spacing={3}
          className={classes.mainContainer}
        >
          <Grid item xs={12} className={classes.success}>
            <EmailSuccess />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
export default ConfirmEmail;
