import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Toolbar,
  Typography,
  Backdrop,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import decode from "jwt-decode";
import useStyles from "./styles";
import gif from "../../images/6U4w.gif";
import { LoaderSvg } from './decor'
const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingWrapper}>
      {/* <Backdrop open={true} sx={{ color: "#fff", zIndex: 20 }}></Backdrop> */}
      {/* <img src={gif} alt="gifts" />{" "} */}
      <div className="loading-wrapper">

        <LoaderSvg />
      </div>
    </div>
  );
};

export default Loading;
